import { API_URL } from "api/instance";
import { PublicAppRoutes } from "config/AppRoutes";
import { useRealmAdapter, useSwitchRealm } from "containers/RealmAdapter";
import { REALMS } from "containers/RealmAdapter/constants";
import { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

const HTMLInjector = ({ html, containerID = "HTML_CONTAINER", ...props }) => {
  const { code: modelCode, mediaCode } = useParams();
  const history = useHistory();
  const { realm, makeURL } = useRealmAdapter();
  const switchRealm = useSwitchRealm();

  /* TODO: will be removed */
  const getDemoImageUrl = useCallback(
    (url, { modelCode, mediaCode }) => {
      const x = url.replace("../", "");
      const code = switchRealm({
        [REALMS.model]: modelCode,
        [REALMS.media]: mediaCode,
      });

      return `${API_URL}/public/static/${code}/sections/${x}`;
    },
    [switchRealm]
  );

  const handleLinksClick = useCallback(
    (e, path) => {
      e.preventDefault();
      history.push(path);
    },
    [history]
  );

  const injectHTMLContent = useCallback(() => {
    const virtualCntainer = document.createElement("div");

    virtualCntainer.innerHTML = html;

    const images = virtualCntainer.getElementsByTagName("img");
    const links = virtualCntainer.getElementsByTagName("a");

    [...images].forEach((img) => {
      const newUrl = getDemoImageUrl(img.getAttribute("src"), {
        realm,
        mediaCode,
        modelCode: modelCode?.toLowerCase(),
      });

      img.src = newUrl;
    });

    [...links].forEach((link) => {
      const articleCode = link.attributes.href.value;
      const route = switchRealm({
        [REALMS.model]: PublicAppRoutes.CarModel.SubRoutes.ArticleRedirect,
        [REALMS.model]: PublicAppRoutes.MediaEntry.SubRoutes.ArticleRedirect,
      });

      const path = makeURL(route.make({ articleCode }));

      link.href = path;

      link.addEventListener("click", (e) => handleLinksClick(e, path));

      // console.log();
      // const newUrl = getDemoImageUrl(img.getAttribute("src"), modelCode);
      // img.src = newUrl;
    });

    const injectionContainer = document.getElementById(containerID);

    injectionContainer.appendChild(virtualCntainer);
  }, [
    html,
    containerID,
    realm,
    mediaCode,
    modelCode,
    getDemoImageUrl,
    handleLinksClick,
    makeURL,
    switchRealm,
  ]);

  const cleanHtmlContent = useCallback(() => {
    const injectionContainer = document.getElementById(containerID);

    if (injectionContainer) {
      injectionContainer.innerHTML = "";
    }
  }, [containerID]);

  useEffect(() => {
    if (html) injectHTMLContent(html, { realm, modelCode, mediaCode });

    return () => {
      cleanHtmlContent();
    };
  }, [html, realm, mediaCode, modelCode, injectHTMLContent, cleanHtmlContent]);

  return <div {...props} id={containerID}></div>;
};

export default HTMLInjector;
