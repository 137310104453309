const FETCH_STATUS = {
  idle: "IDLE",
  loading: "LOADING",
  sucess: "SUCESS",
  error: "ERROR",
};

export const isLoading = (status) => status === FETCH_STATUS.loading;
export const isError = (status) => status === FETCH_STATUS.error;
export const isIdle = (status) => status === FETCH_STATUS.idle;
export const isSuccess = (status) => status === FETCH_STATUS.isSuccess;

export default FETCH_STATUS;
