import Icon from "components/Icon";

const AppsIcon = ({ ...props }) => {
  return (
    <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.95 20.524c-3.36 0-4.96-1.6-4.96-4.96v-10.4c0-3.36 1.6-4.96 4.96-4.96h10.4c3.345 0 4.96 1.6 4.96 4.96v10.4c0 3.36-1.615 4.96-4.96 4.96H5.95zm0 26.181c-3.36 0-4.96-1.6-4.96-4.96V31.418c0-3.36 1.6-4.96 4.96-4.96h10.4c3.345 0 4.96 1.6 4.96 4.96v10.4c0 3.36-1.615 4.96-4.96 4.96l-10.4-.073zM18.443 15.07V5.585c0-1.818-.699-2.589-2.517-2.589h-9.57c-1.804 0-2.517.771-2.517 2.59v9.556c0 1.818.713 2.589 2.516 2.589H16c1.745 0 2.444-.771 2.444-2.59v-.072zm0 26.255V31.84c0-1.818-.699-2.59-2.517-2.59h-9.57c-1.804 0-2.517.772-2.517 2.59v9.556c0 1.819.713 2.59 2.516 2.59H16c1.745 0 2.444-.771 2.444-2.59v-.072zm13.687 5.381c-3.36 0-4.96-1.6-4.96-4.96V31.418c0-3.36 1.6-4.96 4.96-4.96h10.4c3.345 0 4.96 1.6 4.96 4.96v10.4c0 3.36-1.615 4.96-4.96 4.96l-10.4-.073zm12.494-5.381V31.84c0-1.818-.698-2.59-2.516-2.59h-9.57c-1.804 0-2.503.772-2.503 2.59v9.556c0 1.819.699 2.59 2.502 2.59h9.644c1.745 0 2.443-.771 2.443-2.59v-.072zM37.92 14.793c-3.345 0-4.96-1.6-4.96-4.946V5.164c0-3.36 1.615-4.96 4.96-4.96h4.61c3.346 0 4.96 1.6 4.96 4.96v4.683c0 3.346-1.614 4.946-4.96 4.946h-4.61zm6.705-5.368v-3.84c0-1.818-.698-2.589-2.516-2.589h-3.767c-1.818 0-2.517.771-2.517 2.59v3.84c0 1.818.699 2.574 2.517 2.574h3.84c1.745 0 2.443-.756 2.443-2.575z"
        fill="#FFF"
        fill-rule="nonzero"
      />
    </svg>
  );
};

const Apps = ({ ...props }) => {
  return (
    <Icon {...props}>
      <AppsIcon />
    </Icon>
  );
};

export default Apps;
