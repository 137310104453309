import { SET_ALPHABETICAL_INDEX_DATA, SET_ACTIVE_CHARACTER } from "./actions";

export const setAlphabeticalIndexData = (payload) => ({
  type: SET_ALPHABETICAL_INDEX_DATA,
  payload,
});

export const setActiveCharacter = (character) => ({
  type: SET_ACTIVE_CHARACTER,
  payload: character,
});
