import Icon from "components/Icon";

const MultimediaIcon = ({ ...props }) => {
  return (
    <svg width="44" height="48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M34.433 42.283c-4.948 0-8.358-2.99-8.358-7.458 0-4.237 3.482-7.096 8.488-7.096 2.151-.042 4.265.565 6.066 1.741V11.913L17.412 15.54v24.667c0 4.73-3.54 7.792-9.054 7.792C3.424 48 0 45.01 0 40.556s3.482-7.11 8.503-7.11c2.15-.04 4.264.567 6.065 1.741V4.353L43.588 0v34.476c-.1 4.702-3.656 7.807-9.155 7.807zM14.51 40.629c0-2.728-2.307-4.353-6.065-4.353-3.482 0-5.645 1.668-5.645 4.353 0 2.684 2.09 4.585 5.5 4.585 3.83.058 6.21-1.683 6.21-4.585zm2.902-28.005L40.63 8.996V3.192L17.412 6.805v5.819zM40.63 34.839c0-2.713-2.307-4.353-6.066-4.353-3.482 0-5.644 1.669-5.644 4.353 0 2.685 2.09 4.6 5.514 4.6 3.83.058 6.196-1.712 6.196-4.542v-.058z"
        fill="#FFF"
        fill-rule="nonzero"
      />
    </svg>
  );
};

const Multimedia = ({ ...props }) => {
  return (
    <Icon {...props}>
      <MultimediaIcon />
    </Icon>
  );
};

export default Multimedia;
