import Icon from "components/Icon";

const YoutubeIcon = ({ ...props }) => {
  return (
    <svg
      width="43px"
      height="32px"
      viewBox="0 0 43 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        filerule="evenodd">
        <g transform="translate(-293.000000, -1495.000000)" filerule="nonzero">
          <g id="Group-9" transform="translate(78.000000, 1495.000000)">
            <g id="youtube" transform="translate(215.993895, 0.000000)">
              <path
                d="M17.196199,8.82951028 C16.3363026,8.31564861 15.2341817,8.93678107 15.2341817,9.94374231 L15.2341817,22.0549601 C15.2341817,23.0671118 16.3380327,23.6795934 17.196199,23.1709223 L27.35059,17.1239643 C28.1949149,16.6204836 28.1931847,15.3989808 27.3523202,14.89377 L17.196199,8.82951028 Z M17.8294427,19.7728606 L17.8294427,12.2293021 L24.1532285,16.0062719 L17.8294427,19.7728606 Z"
                id="Shape"></path>
              <path
                d="M34.4373826,0.896662657 C26.3868831,-0.298887552 15.1407524,-0.298887552 7.08679255,0.896662657 C3.86520863,1.37419067 1.32704343,3.90370501 0.773387762,7.18757519 C-0.257795921,13.2829781 -0.257795921,18.7174545 0.773387762,24.8111272 C1.32704343,28.0967275 3.86520863,30.6245117 7.08679255,31.1020397 C11.1215582,31.7006799 15.9522039,32 20.7811195,32 C25.6013841,32 30.4199186,31.7024101 34.4391127,31.1037699 C37.6572363,30.6262419 40.1936713,28.0984577 40.7507874,24.8128574 C41.781971,18.7174545 41.781971,13.2812479 40.7507874,7.18584501 C40.1971317,3.90370501 37.6589665,1.37419067 34.4373826,0.896662657 Z M38.1918601,24.3803139 C37.823333,26.5534124 36.162366,28.2247604 34.0567443,28.5361917 C26.2398183,29.6971385 15.3051189,29.6971385 7.46743083,28.5344616 C5.36007894,28.2213001 3.69738176,26.5516822 3.33058488,24.3785837 C2.33746502,18.5063733 2.33746502,13.4940593 3.33058488,7.61838851 C3.69911193,5.44529001 5.36007894,3.77740231 7.469161,3.46251064 C11.3828145,2.88290237 16.0733161,2.59223314 20.7603574,2.59223314 C25.450859,2.59223314 30.1413606,2.88290237 34.0550141,3.46251064 C36.162366,3.77567213 37.8250632,5.44529001 38.1918601,7.61838851 C39.1849799,13.4940593 39.1849799,18.5063733 38.1918601,24.3803139 Z"
                id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const Youtube = ({ ...props }) => {
  return (
    <Icon {...props}>
      <YoutubeIcon />
    </Icon>
  );
};

export default Youtube;
