import classNames from "classnames";
import React from "react";

const Icon = ({ children, className, ...props }) => {
  const child = React.cloneElement(children, {
    className: classNames("svg-icon", className),
    ...props,
  });

  return child;
};

export default Icon;
