import {
  BOOKMARK_ARTICLE,
  SET_CURRENT_ARTICLE_HTML_CONTENT,
  SET_CURRENT_ARTICLE_INFO,
  SET_USER_VOTED,
} from "./actions";

export const setCurrentArticle = (data) => ({
  type: SET_CURRENT_ARTICLE_INFO,
  payload: data,
});

export const setCurrentArticleHtmlContent = (content) => ({
  type: SET_CURRENT_ARTICLE_HTML_CONTENT,
  payload: content,
});

export const setUserVoted = (voted) => ({
  type: SET_USER_VOTED,
  payload: voted,
});

export const setBookmarkArticle = (bookmarked) => ({
  type: BOOKMARK_ARTICLE,
  payload: bookmarked,
});
