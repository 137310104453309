import React from "react";
import classNames from "classnames";
import { FILTER_OPTIONS_COMPLEMENTARY_DATA } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCarModelFilter,
  selectModelTypes,
} from "containers/HomePage/store/selectors";
import { setCarModelFilter } from "containers/HomePage/store/actionCreators";

// const filterClassNames = {
//   default: { wrapper: "fixed bottom-0 left-0 bg-primary text-white" },
//   md: { wrapper: "" },
// };

/* Filter appears differntly depends on screen size  */
const CarModelsFilter = () => {
  const dispatch = useDispatch();
  const activeFilter = useSelector(selectCarModelFilter);
  const modelTypes = useSelector(selectModelTypes) || [];

  const handleFilter = (newFilter) => {
    dispatch(setCarModelFilter(newFilter));
  };

  /* Change mechanism */
  return (
    <>
      <div className="hidden md:block">
        <DefaultFilterItems
          onSelectFilter={handleFilter}
          activeFilter={activeFilter}
          options={modelTypes}
        />
      </div>

      <div className="block md:hidden">
        <MobileFilterItems
          onSelectFilter={handleFilter}
          activeFilter={activeFilter}
          options={modelTypes}
        />
      </div>
    </>
  );
};

const DefaultFilterItems = ({ options = [], onSelectFilter, activeFilter }) => {
  return (
    <div className="text-primary">
      <div className="flex justify-center w-full md:w-auto">
        {options.map((filter) => (
          <button
            onClick={(e) => onSelectFilter(filter.code, e)}
            href={`/#${filter.code}`}
            className={classNames(
              "inline-block px-2 uppercase text-center py-4 border-b-4 border-transparent mx-5 text-sm hover:border-secondary",
              activeFilter === filter.code && "font-bold border-secondary"
            )}>
            <span>véhicules {filter.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

const MobileFilterItems = ({ options = [], activeFilter, onSelectFilter }) => {
  return (
    <div className="fixed z-10 w-full bottom-0 left-0 bg-primary text-white">
      <div className="flex w-full md:w-auto">
        {options.map((filter) => (
          <button
            key={filter.code}
            onClick={(e) => onSelectFilter(filter.code, e)}
            className={classNames(
              "flex flex-col justify-between items-center font-semibold px-2 text-center py-5 w-1/3",
              { "bg-secondary text-primary": activeFilter === filter.code }
            )}>
            <span className="block">
              {FILTER_OPTIONS_COMPLEMENTARY_DATA[filter.code].icon}
            </span>
            <span>{filter.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default CarModelsFilter;
