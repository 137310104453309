import React, { useEffect } from "react";
import classNames from "classnames";
import { Link, Redirect, Route, Switch, useParams } from "react-router-dom";
import ModelIndicatorLights from "./components/ModelIndicatorLights";
import ModelSections from "./components/ModelSections";
import MultimediaSection from "containers/HomePage/components/MultimediaSection";
import { fetchCarModelInfo } from "./store/thunks";
import { useDispatch, useSelector } from "react-redux";
import { selectModelInfo } from "./store/selectors";
import Container from "components/Container";
import "./index.scss";
import { PublicAppRoutes } from "config/AppRoutes";
import { useRealmAdapter, withAdapter } from "containers/RealmAdapter";
import { REALMS } from "containers/RealmAdapter/constants";
import Section from "containers/Section";
import Articles from "containers/Articles";
import Article from "containers/Article";
import Medias from "containers/Medias";
import VideoGallery from "containers/VideoGallery";
import Lights from "containers/Lights";
import Bookmarks from "containers/Bookmarks";
import Index from "containers/Index";
import ArticleRedirect from "containers/Article/ArticleRedirect";

const CarModel = () => {
  const { makePath, makeURL } = useRealmAdapter();

  return (
    <Switch>
      <Route exact path={makePath("/")} component={Entry} />
      <Route
        exact
        path={makePath(PublicAppRoutes.CarModel.SubRoutes.Section.path)}
        component={Section}
      />
      <Route
        exact
        path={makePath(PublicAppRoutes.CarModel.SubRoutes.SectionRedirect.path)}
        component={() => <Redirect to={makeURL("/")} />}
      />
      <Route
        exact
        path={makePath(PublicAppRoutes.CarModel.SubRoutes.Articles.path)}
        component={Articles}
      />
      <Route
        exact
        path={makePath(PublicAppRoutes.CarModel.SubRoutes.Article.path)}
        component={Article}
      />
      <Route
        exact
        path={makePath(PublicAppRoutes.CarModel.SubRoutes.VideoGallery.path)}
        component={VideoGallery}
      />
      <Route
        exact
        path={makePath(PublicAppRoutes.CarModel.SubRoutes.ArticleRedirect.path)}
        component={ArticleRedirect}
      />
      <Route exact path={PublicAppRoutes.Lights.path} component={Lights} />
      <Route
        exact
        path={PublicAppRoutes.Bookmarks.path}
        component={Bookmarks}
      />
      <Route exact path={PublicAppRoutes.Index.path} component={Index} />
      <Route exact path={PublicAppRoutes.Medias.path} component={Medias} />
    </Switch>
  );
};

const Entry = () => {
  const dispatch = useDispatch();
  const { code: modelCode } = useParams();

  useEffect(() => {
    dispatch(fetchCarModelInfo(modelCode));
  }, [modelCode, dispatch]);

  return (
    <>
      <CarModelBanner modelCode={modelCode} />
      <Container>
        <SectionContainer>
          <ModelSections />
        </SectionContainer>
      </Container>

      <ModelIndicatorLights />

      <Container>
        <SectionContainer className="mt-16">
          <MultimediaSection
            to={PublicAppRoutes.ModelVideoGallery.make({ code: modelCode })}
          />
        </SectionContainer>
      </Container>
    </>
  );
};

const SectionContainer = ({ children, className }) => {
  return (
    <div className={classNames("w-10/12 lg:w-full mx-auto", className)}>
      {children}
    </div>
  );
};

const CarModelBanner = ({ modelCode }) => {
  const modelInfo = useSelector(selectModelInfo);

  /* TODO: handle loading state */
  if (!modelInfo) return "PLACEHOLDER";

  return (
    <div
      style={{ backgroundImage: `url(${modelInfo.image})` }}
      className="relative h-auto md:model-banner-xl bg-center bg-cover px-6 pt-6 pb-18">
      <div
        style={{ background: "rgba(255, 255, 255, 0.8)" }}
        className="md:absolute md:left-12 md:bottom-28 px-8 py-6">
        <h1 className="text-3xl font-bold uppercase mb-1">
          {modelInfo.modelName}
        </h1>

        <p className="mb-4 text-lg">découverez votre véhicule en vidéos</p>

        <Link
          to={PublicAppRoutes.ModelVideoGallery.make({ code: modelCode })}
          className="flex w-full justify-center bg-primary text-white font-bold px-4 py-3 hover:bg-secondary hover:text-primary transition-all uppercase">
          voir les videos
        </Link>
      </div>
    </div>
  );
};

export default withAdapter(CarModel, { realm: REALMS.model });
