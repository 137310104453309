import React, { useState } from "react";
import Stripe from "components/Stripe";
import SectionTitle from "components/Title/SectionTitle";
import Container from "components/Container";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveCharacter,
  selectIndexAlphabets,
  selectIndexData,
} from "./store/selectors";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchAlphabeticalIndex } from "./store/thunks";
import { setActiveCharacter } from "./store/actionCreators";
import classNames from "classnames";
import ChevronDown from "icons/ChevronDown";
import { PublicAppRoutes } from "config/AppRoutes";
import { useRealmAdapter } from "containers/RealmAdapter";

const TITLE = "Index alphabétique";

const Index = () => {
  const dispatch = useDispatch();
  const { code: modelCode } = useParams();
  const indexes = useSelector(selectIndexData);

  useEffect(() => {
    dispatch(fetchAlphabeticalIndex(modelCode));
  }, [modelCode, dispatch]);

  if (!indexes) return "PLACEHOLDER";

  return (
    <>
      <Stripe className="md:hidden">{TITLE}</Stripe>

      <SectionTitle className="hidden md:block my-16">{TITLE}</SectionTitle>

      <Container className="min-h-screen">
        <IndexStripe />
        <IndexTBD />
      </Container>
    </>
  );
};

const IndexStripe = () => {
  const dispatch = useDispatch();
  const alphabets = useSelector(selectIndexAlphabets);
  const activeChar = useSelector(selectActiveCharacter);

  const handleCharacterClick = (char) => {
    dispatch(setActiveCharacter(char));
  };

  return (
    <div className="flex flex-nowrap border-b border-grayscale-50 overflow-x-scroll">
      {alphabets?.map((item) => (
        <button
          onClick={() => handleCharacterClick(item)}
          className={classNames("px-4 py-2 text-base md:text-2xl", {
            "border-b-3 border-secondary font-bold": activeChar === item,
          })}>
          {item}
        </button>
      ))}
    </div>
  );
};

const IndexTBD = () => {
  const [activeSection, setActiveSection] = useState(null);
  const activeChar = useSelector(selectActiveCharacter);
  const indexes = useSelector(selectIndexData);
  const { code: modelCode } = useParams();
  const { makeURL } = useRealmAdapter();

  const indexByCharacter = indexes[activeChar];

  const handleSectionToggle = (sectionKey) => {
    const key = sectionKey === activeSection ? null : sectionKey;

    setActiveSection(key);
  };

  if (!indexByCharacter) return null;

  return (
    <div className="mb-16">
      <div className="bg-lightgray py-6 px-6">
        <h3 className="text-grayscale-700 font-bold text-4xl md:text-6xl">
          {activeChar}
        </h3>
      </div>

      <div className="">
        {Object.entries(indexByCharacter?.data).map(([key, articles]) => (
          <div className="border-b border-lightgray">
            <button
              onClick={() => handleSectionToggle(key)}
              className="flex w-full items-center py-3 px-4 text-left">
              <span className="uppercase font-bold">{key}</span>
              <ChevronDown
                className={classNames("min-w-6 ml-auto", {
                  "transform rotate-180": activeSection === key,
                })}
              />
            </button>

            {activeSection === key && (
              <div className="bg-primary text-white">
                {articles.map((article) => (
                  <Link
                    className="py-3 px-4 w-full block"
                    to={makeURL(
                      PublicAppRoutes.CarModel.SubRoutes.ArticleRedirect.make({
                        code: modelCode,
                        articleCode: article.articleCode,
                      })
                    )}>
                    {article.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Index;
