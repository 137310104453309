import React from "react";
import Header from "../../containers/App/components/Header";
import Footer from "../../containers/App/components/Footer";
import MobileNavigation from "containers/App/components/MobileNavigation";

export const PrimaryAppLayout = ({ children }) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
      <MobileNavigation />
    </div>
  );
};
