import React, { useEffect } from "react";
import { PublicAppRoutes } from "config/AppRoutes";
import { withAdapter } from "containers/RealmAdapter";
import { REALMS } from "containers/RealmAdapter/constants";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import Container from "components/Container";
import { Title } from "components/Title";
import External from "icons/External";
import PDF from "icons/PDF";
import { useDispatch, useSelector } from "react-redux";
import { selectMedias } from "./store/selectors";
import { fetchMedia } from "./store/thunks";
import { API_URL } from "api/instance";

const Medias = () => {
  const dispatch = useDispatch();
  const { code: modelCode } = useParams();
  const mediasData = useSelector(selectMedias);

  useEffect(() => {
    dispatch(fetchMedia(modelCode));
  }, [dispatch, modelCode]);

  return (
    <div>
      <div
        style={{ backgroundImage: `url(${mediasData?.mediaBanner})` }}
        className="relative h-auto md:model-banner-xl bg-center bg-cover px-6 pt-6 pb-18">
        <div
          style={{ background: "rgba(255, 255, 255, 0.8)" }}
          className="md:absolute md:left-12 md:bottom-28 px-8 py-6">
          <h1 className="text-3xl font-bold uppercase mb-1">
            Équipements multimédia
          </h1>
        </div>
      </div>

      <div className="my-16">
        <Container className="container-max">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {mediasData?.medias?.map((media) => (
              <div className="">
                <Title className="text-center mb-10">{media.title}</Title>
                <div className="max-w-lg mx-auto">
                  <div
                    className="bg-cover bg-center"
                    style={{
                      minHeight: "300px",
                      backgroundImage: `url(${
                        media.image || "https://i.imgur.com/xQCal3n.png"
                      })`,
                    }}></div>

                  <MediaLinks {...media} />
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  );
};

const MediaLinks = ({ canRedirect, pdfUrl, code }) => {
  const hasTwoColumns = !!canRedirect && !!pdfUrl;

  console.log(pdfUrl);

  return (
    <div
      className={classNames("mt-2", hasTwoColumns && "grid grid-cols-2 gap-2")}>
      {pdfUrl && (
        <a
          download
          href={pdfUrl ? `${pdfUrl}` : "unkown"}
          className="flex w-full justify-center items-center bg-primary text-white font-bold px-4 py-3 hover:bg-secondary hover:text-primary transition-all">
          <PDF className="mr-5" />
          télécharger
        </a>
      )}

      {canRedirect && (
        <a
          target="_blank"
          href={PublicAppRoutes.MediaEntry.make({ mediaCode: code })}
          className="flex w-full justify-center items-center font-bold px-4 py-3 bg-secondary text-primary transition-all "
          rel="noreferrer">
          <External className="mr-5" />
          e-guide
        </a>
      )}
    </div>
  );
};

export default withAdapter(Medias, { realm: REALMS.media });
