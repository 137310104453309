import Icon from "components/Icon";

const PDFIcon = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="22"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <path
        d="M11.976 20.052a30.712 30.712 0 003.34-3.297h-3.34v3.297zM12.38 8.68v2.793h-.877V8.68h-.608v-.773h.608V7.67c0-.807.37-1.211 1.08-1.211.235 0 .471.033.674.1v.74a1.327 1.327 0 00-.472-.1c-.304 0-.405.1-.405.505v.202h.877v.773h-.877zM8.839 10.7c.202 0 .404-.068.64-.27V8.748c-.168-.034-.303-.068-.472-.068-.54 0-.742.27-.742 1.077 0 .707.135.942.574.942zm.708.504c-.27.202-.574.336-.91.336-.844 0-1.249-.605-1.249-1.783 0-1.312.472-1.884 1.62-1.884.168 0 .303.034.472.067V6.527h.877v4.946h-.81v-.27zM4.79 7.503v1.413H5.5c.37 0 .573-.27.573-.707 0-.504-.168-.706-.573-.706H4.79zm-.944-.841h1.653c.944 0 1.518.605 1.518 1.547 0 1.01-.54 1.548-1.518 1.548H4.79v1.716h-.944V6.662zm6.983 13.794v-4.811h4.79V1.144H1.181v19.312h9.648zM0 21.6V0h16.8v16.789c-1.282 1.615-2.935 3.263-4.79 4.811H0z"
        id="a"
      />
    </svg>
  );
};

const PDF = ({ ...props }) => {
  return (
    <Icon {...props}>
      <PDFIcon />
    </Icon>
  );
};

export default PDF;
