import { useEffect } from "react";

const useBodyOverflow = (condition) => {
  useEffect(() => {
    if (condition) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => document.body.classList.remove("overflow-hidden");
  }, [condition]);
};

export default useBodyOverflow;
