import { getAlphabeticalIndexes } from "api";
import { setAlphabeticalIndexData } from "./actionCreators";

export const fetchAlphabeticalIndex = (modelCode) => {
  return (dispatch) => {
    /* TODO: handle errors */
    getAlphabeticalIndexes(modelCode).then((res) => {
      dispatch(setAlphabeticalIndexData(res.data));
    });
  };
};

// const resolver = () =>
//   Promise.resolve({
//     data: {
//       A: [
//         {
//           title: "Abs",
//           articles: [{ title: "A nice article " }],
//         },
//       ],
//       B: [
//         {
//           title: "Abs",
//           articles: [{ title: "A nice article " }],
//         },
//       ],
//       C: [
//         {
//           title: "Abs",
//           articles: [{ title: "A nice article " }],
//         },
//       ],
//       D: [
//         {
//           title: "Abs",
//           articles: [{ title: "A nice article " }],
//         },
//       ],
//       E: [
//         {
//           title: "Abs",
//           articles: [{ title: "A nice article " }],
//         },
//       ],
//     },
//   });
