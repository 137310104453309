import { createSelector } from "reselect";

const baseSelector = (state) => state.section;

export const selectCurrentSection = createSelector(
  baseSelector,
  (state) => state.currentSection
);

export const selectCurrentSectionStatus = createSelector(
  baseSelector,
  (state) => state.status
);
