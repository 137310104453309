import Icon from "components/Icon";

const ArrowLeftIcon = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="18"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <path
        d="M11.065 17.077C6.64 14.176 3.59 11.963 0 9.062V8.015C3.589 5.114 6.64 2.9 11.065 0l.629.927C8.134 3.2 5.413 5.114 1.645 7.985H19.2v1.107H1.674c3.769 2.87 6.49 4.785 10.05 7.058l-.659.927z"
        id="a"
      />

      <use fill="#000" transform="translate(.4 .6)" fill-rule="evenodd" />
    </svg>
  );
};

const ArrowLeft = ({ ...props }) => {
  return (
    <Icon {...props}>
      <ArrowLeftIcon />
    </Icon>
  );
};

export default ArrowLeft;
