import FETCH_STATUS from "shared/fetchStatus";
import { SET_ACTIVE_CHARACTER, SET_ALPHABETICAL_INDEX_DATA } from "./actions";

const initialState = {
  status: FETCH_STATUS.idle,
  data: null,
  activeChar: null,
};

export default function indexReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ALPHABETICAL_INDEX_DATA: {
      const firstCharacter = Object.keys(payload)[0];
      return { ...state, data: payload, activeChar: firstCharacter };
    }
    case SET_ACTIVE_CHARACTER: {
      return { ...state, activeChar: payload };
    }

    default:
      return state;
  }
}
