import FETCH_STATUS from "shared/fetchStatus";
import {
  SET_CURRENT_ARTICLE_INFO,
  SET_CURRENT_ARTICLE_HTML_CONTENT,
  SET_USER_VOTED,
  BOOKMARK_ARTICLE,
} from "./actions";

const initialState = {
  status: FETCH_STATUS.idle,
  articleInfo: null,
  htmlContent: null,
  userHaveVoted: false,
  bookmarked: false,
};

export default function articleReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_CURRENT_ARTICLE_INFO: {
      return { ...state, articleInfo: payload };
    }

    case SET_CURRENT_ARTICLE_HTML_CONTENT: {
      return { ...state, htmlContent: payload };
    }

    case SET_USER_VOTED: {
      return { ...state, userHaveVoted: payload };
    }

    case BOOKMARK_ARTICLE: {
      return { ...state, bookmarked: payload };
    }
    default:
      return state;
  }
}
