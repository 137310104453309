import Play from "icons/Play";
import React, { useMemo } from "react";
import { noop } from "statics";
import { getYTBVideoID } from "utils/misc";
import TruncateText from "react-text-truncate";
import classNames from "classnames";

const getThumbnailUrl = (videoID) =>
  `https://i.ytimg.com/vi/${videoID}/hqdefault.jpg`;

const YoutubeVideoCard = ({
  url,
  title,
  onClick = noop,
  className,
  wrapperClassName,
  ...props
}) => {
  const videoID = useMemo(() => getYTBVideoID(url), [url]);

  const thumbnail = useMemo(() => getThumbnailUrl(videoID), [videoID]);

  const handleVideoClick = (e) => {
    onClick({ videoID, url, title }, e);
  };

  return (
    <button className={wrapperClassName} onClick={handleVideoClick} {...props}>
      <div
        className={classNames(
          "px-4 bg-cover bg-center relative flex justify-center items-center",
          className
        )}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${thumbnail})`,
        }}>
        <Play className="absolute text-white w-8" />
      </div>
      <h3 className="text-sm font-semibold text-left min-h-12 lowercase mt-2">
        <TruncateText line={2} truncateText="..." text={title} />
      </h3>
    </button>
  );
};

export default YoutubeVideoCard;
