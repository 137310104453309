import FETCH_STATUS from "shared/fetchStatus";
import { SET_MEDIA_SECTIONS, SET_MEDIA_INFO } from "./actions";

const initialState = {
  mediaSections: { status: FETCH_STATUS.idle, data: null },
  mediaInfo: { status: FETCH_STATUS.idle, data: null },
};

export default function mediaEntryReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_MEDIA_INFO: {
      const draft = { ...state };
      draft.mediaInfo.data = payload;

      return { ...draft };
    }

    case SET_MEDIA_SECTIONS: {
      const draft = { ...state };
      draft.mediaSections.data = payload;

      return { ...draft };
    }

    default:
      return state;
  }
}
