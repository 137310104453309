import Icon from "components/Icon";

const SystemIcon = ({ ...props }) => {
  return (
    <svg width="50" height="48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M31.5 1.5l.855-1.08a60 60 0 019.66 5.76l-.36 1.365a15.735 15.735 0 00-.795 4.5c0 3.165 1.875 4.965 6.48 6.195l1.5.435c.15 3.96.15 7.2 0 11.085l-1.5.435c-4.605 1.215-6.48 3-6.48 6.195a16.5 16.5 0 00.795 4.5l.36 1.29a57.9 57.9 0 01-9.6 5.82l-.915-1.035c-2.085-2.445-4.11-3.675-6.63-3.675-2.52 0-4.5 1.23-6.615 3.675l-.87 1.005a57.735 57.735 0 01-9.645-5.76l.36-1.29c.462-1.458.724-2.972.78-4.5 0-3.18-1.86-4.98-6.48-6.195L.9 29.79a151.747 151.747 0 010-11.085l1.5-.435c4.62-1.23 6.48-3 6.48-6.195a16.2 16.2 0 00-.78-4.5L7.74 6.21A59.835 59.835 0 0117.385.45l.87 1.08c2.085 2.385 4.095 3.6 6.615 3.6 2.52 0 4.605-1.215 6.63-3.63zM33 44.37a39.675 39.675 0 005.685-3.45 19.095 19.095 0 01-.645-4.5c0-4.11 2.085-7.35 7.995-8.715v-7.02c-5.91-1.5-7.995-4.5-7.995-8.64.03-1.52.246-3.032.645-4.5a31.26 31.26 0 00-5.76-3.39c-2.88 3-5.115 3.885-7.92 3.885-2.805 0-5.04-.93-7.92-3.96A32.25 32.25 0 0011.25 7.5a17.82 17.82 0 01.645 4.5c0 4.11-2.085 7.2-7.98 8.64v6.99c5.895 1.365 7.98 4.605 7.98 8.715a18.345 18.345 0 01-.645 4.5 40.5 40.5 0 005.685 3.45c2.88-3 5.115-3.96 7.995-3.96S30 41.355 33 44.37zm-8.07-29.16c5.685 0 9 3.315 9 9 0 5.685-3.315 9-9 9-5.685 0-9.075-3.39-9.075-9 0-5.61 3.39-9 9.075-9zm0 15.195c3.825 0 6-2.235 6-6.195 0-3.96-2.22-6.12-6-6.12a5.61 5.61 0 00-6.12 6.12 5.625 5.625 0 006.12 6.195z"
        fill-rule="nonzero"
      />
    </svg>
  );
};

const System = ({ ...props }) => {
  return (
    <Icon {...props}>
      <SystemIcon />
    </Icon>
  );
};

export default System;
