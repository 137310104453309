const STORAGE_KEY = "[bookmarks]";

export const getBookmarksJSON = () => {
  return localStorage.getItem(STORAGE_KEY);
};

export const getBookmarks = () => {
  try {
    return JSON.parse(getBookmarksJSON()) || {};
  } catch {
    return null;
  }
};

export const getBookmarkById = (id) => {
  const bookmarks = getBookmarks();

  return bookmarks[id] || null;
};

const setBookmarks = (bookmarks) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(bookmarks));
};

export const setBookmark = (id, data) => {
  const bookmarks = getBookmarks() || {};
  bookmarks[id] = data;

  setBookmarks(bookmarks);
};

export const removeFromBookmarks = (id) => {
  const bookmarks = getBookmarks();
  delete bookmarks[id];

  setBookmarks(bookmarks);
};
