import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchManufacturerInfo } from "./store/thunks";

function App({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchManufacturerInfo());
  }, [dispatch]);

  return children;
}

export default App;
