import React from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useState } from "react";
import FETCH_STATUS, { isLoading } from "shared/fetchStatus";
import { updateModelStatus } from "../../store/thunks";
import { useDashboardContext } from "../../context";

const ChangeModelStatus = ({ modelID, modelName, isActive }) => {
  const dispatch = useDispatch();
  const [popupVisible, setPopupVisibility] = useState(false);
  const [status, setStatus] = useState(FETCH_STATUS.idle);
  const { refreshTable } = useDashboardContext();

  const onConfirm = () => {
    setStatus(FETCH_STATUS.loading);

    dispatch(
      updateModelStatus({
        id: modelID,
        onSuccess: () => {
          refreshTable();
          closePopup();
          setStatus(FETCH_STATUS.sucess);
        },
        onFail: () => setStatus(FETCH_STATUS.error),
      })
    );
  };

  const openPopup = () => {
    setPopupVisibility(true);
  };

  const closePopup = () => {
    setPopupVisibility(false);
  };

  return (
    <>
      <button
        onClick={openPopup}
        className={classNames("text-xs px-4 py-2 w-full uppercase font-bold", {
          "bg-primary text-white": isActive,
          "bg-secondary": !isActive,
        })}>
        {isActive ? "dépublier" : "publier"}
      </button>

      {popupVisible && (
        <Confirmation
          confirming={isLoading(status)}
          message={
            <>
              <span>
                Le modèle <span className="font-bold">{modelName}</span> sera{" "}
                {isActive ? "dépublié" : "publié"}, voulez-vous continuer ?
              </span>
            </>
          }
          onClose={closePopup}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

const Confirmation = ({ message, confirming, onClose, onConfirm }) => {
  return (
    <div
      style={{ background: "rgba(0,0,0, 0.8)" }}
      className="fixed top-0 left-0 h-screen w-screen z-50 flex items-center justify-center py-16 overflow-scroll">
      <div className="w-10/12 max-w-md m-auto">
        <div className="bg-white rounded-xl shadow-xl">
          <div className="flex flex-col w-full px-4 py-4">
            <div className="pb-12 text-base text-center pt-4">{message}</div>

            <div className="grid grid-cols-2 gap-4">
              <button
                onClick={onClose}
                type="button"
                className="flex justify-center text-primary font-bold px-4 py-3 text-sm uppercase">
                Annuler
              </button>

              <button
                disabled={confirming}
                onClick={onConfirm}
                type="button"
                className={classNames(
                  "flex justify-center  font-bold px-4 py-3 text-sm transition-all uppercase rounded",
                  {
                    "bg-primary text-white hover:bg-secondary hover:text-primary ":
                      !confirming,
                    "cursor-not-allowed bg-grayscale-50 text-grayscale-500":
                      confirming,
                  }
                )}>
                {confirming ? "Chargement..." : "Confirmer"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeModelStatus;
