import React, { useEffect } from "react";
import Table from "rc-table";
import { useDispatch, useSelector } from "react-redux";
import { selectCMSModelsList } from "./store/selectors";
import { fetchCMSModels } from "./store/thunks";
import Search from "icons/Search";
import { useForm } from "react-hook-form";
import _debounce from "lodash/debounce";
import _noop from "lodash/noop";
import ChangeModelStatus from "./components/ChangeModelStatus";
import { DashboardContext } from "./context";

const Dashboard = () => {
  const dispatch = useDispatch();
  const modelsList = useSelector(selectCMSModelsList);
  const form = useForm();

  const loadTableData = () => {
    dispatch(fetchCMSModels({ criteria: form.getValues() }));
  };

  const delayedHandleTableSearch = _debounce(() => {
    delayedHandleTableSearch.cancel();

    loadTableData();
  }, 420.69);

  useEffect(() => {
    dispatch(fetchCMSModels());
  }, [dispatch]);

  return (
    <DashboardContext.Provider value={{ refreshTable: loadTableData }}>
      <div className="flex items-center">
        <h1 className="text-xl font-bold mr-4">Liste des véhicules</h1>

        <div className="ml-auto">
          <form
            onSubmit={form.handleSubmit(_noop)}
            onChange={delayedHandleTableSearch}>
            <div className="relative">
              <span className="absolute h-full top-0 flex items-center px-3 pointer-events-none">
                <Search className="w-4" />
              </span>

              <input
                className="pl-9 border-none rounded-md w-72 outline-none focus:outline-none"
                type="text"
                placeholder="recherche rapide…"
                {...form.register("modelName")}
              />
            </div>
          </form>
        </div>
      </div>

      <div className="mt-6 pt-4 pb-4 px-8 rounded-2xl bg-white">
        <Table data={modelsList} columns={columns} />
      </div>
    </DashboardContext.Provider>
  );
};

const Avatar = ({ image }) => {
  return (
    <div
      className="w-24 h-24 bg-cover bg-center rounded-full"
      style={{ backgroundImage: `url(${image})` }}></div>
  );
};

const columns = [
  {
    title: "Couverture",
    dataIndex: "image",
    width: 280,
    render: (imageURL) => <Avatar image={imageURL} alt="" />,
    className: "bg-primary",
  },
  {
    title: "Modèle",
    dataIndex: "modelName",
    className: "confirmation",
  },
  // {
  //   title: "Marque",
  // },
  {
    title: "Statut",
    dataIndex: "active",
    render: (active) => (active ? "Publié" : "Non publié"),
  },
  {
    title: "Actions",
    width: 130,
    render: (data) => (
      <ChangeModelStatus
        isActive={data.active}
        modelID={data.id}
        modelName={data.modelName}
      />
    ),
  },
];

export default Dashboard;
