import React, { useState, useMemo, useRef } from "react";
import { Switch, Route } from "react-router-dom";
import { NavigationLink } from "components/NavigationLink";
import { PublicAppRoutes } from "config/AppRoutes";
import {
  DEFAULT_NAVIGATION_ITEMS,
  MODEL_NAVIGATION_ITEMS,
} from "containers/App/constants";
import useOnClickOutside from "hooks/clickOutside";
import useProxyBreakpoint from "hooks/proxyBreakpoint";
import { DynamicMenuContext, useDynamicMenuContext } from "./context";
import DYNAMIC_MENU_TYPES from "./types";
import MenuLink from "./MenuLink";
import ChevronDown from "icons/ChevronDown";
import classNames from "classnames";

/* types: web / mobile (a hack for now) */
const DynamicMenu = ({ type }) => {
  const screen = useProxyBreakpoint();
  const contextValue = useMemo(() => ({ screen, type }), [screen, type]);

  return (
    <DynamicMenuContext.Provider value={contextValue}>
      <Switch>
        <Route
          path={PublicAppRoutes.CarModel.path}
          render={() => <NavigationMenu items={MODEL_NAVIGATION_ITEMS} />}
        />
        {/* This will be shown by default in every page other than CarModel page*/}
        <Route
          render={() => <NavigationMenu items={DEFAULT_NAVIGATION_ITEMS} />}
        />
      </Switch>
    </DynamicMenuContext.Provider>
  );
};

const MenuWrapper = ({ children }) => (
  <div className="relative flex flex-col px-4 py-4 md:flex-row">{children}</div>
);

const NavigationMenu = ({ items = [] }) => {
  const { screen } = useDynamicMenuContext();

  const optionsObject = useMemo(() => {
    const result = { default: [], collapsibles: [] };

    items.forEach((item) => {
      if (item.collapsible) {
        /* when screen is undefined this means unsupported viewport size (not defined in BREAKPOINTS_CONFIG) */

        const inViewport =
          screen === undefined || item?.screens?.includes(screen);

        result.collapsibles.push({ ...item, hidden: !inViewport });
      } else {
        result.default.push(item);
      }
    });

    return result;
  }, [items, screen]);

  const options = [...optionsObject.default, ...optionsObject.collapsibles];

  const isDropdownVisible = optionsObject.collapsibles.some(
    (item) => item.hidden === true
  );

  return (
    <MenuWrapper>
      {options.map((navItem) => !navItem?.hidden && <MenuLink {...navItem} />)}

      {isDropdownVisible && (
        <MenuDropdown options={optionsObject.collapsibles} />
      )}
    </MenuWrapper>
  );
};

const MenuDropdown = ({ options }) => {
  const containerRef = useRef(null);
  const [visible, setVisibility] = useState(false);

  const toggleVisibility = () => {
    setVisibility((prev) => !prev);
  };

  const handleMenuClick = (e) => {
    e.preventDefault();
    toggleVisibility();
  };

  useOnClickOutside(containerRef, () => {
    if (visible) toggleVisibility();
  });

  return (
    <div ref={containerRef} className="relative flex">
      <NavigationLink
        className="flex items-center"
        onClick={handleMenuClick}
        as="a"
        href="#">
        <span>Autre Sections</span>
        <ChevronDown
          className={classNames("ml-2 transform w-3", visible && "rotate-180")}
        />
      </NavigationLink>

      {visible && (
        <div className="absolute bg-white top-10 transform left-1/2 -translate-x-1/2 flex z-10 px-2 py-4 rounded shadow w-60 flex-col">
          {options.map(
            ({ hidden: hiddenFromMainMenu, ...item }) =>
              hiddenFromMainMenu && (
                <MenuLink
                  onClick={toggleVisibility}
                  baseClassName={null}
                  className="text-primary hover:text-darkgray mb-3 last:mb-0"
                  {...item}
                />
              )
          )}
        </div>
      )}
    </div>
  );
};

DynamicMenu.TYPES = DYNAMIC_MENU_TYPES;

export default DynamicMenu;
