import React from "react";
import { Link, useParams } from "react-router-dom";
import { SECTIONS_DATA } from "containers/CarModel/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectModelSections } from "containers/CarModel/store/selectors";
import { useEffect } from "react";
import { fetchCarModelSections } from "containers/CarModel/store/thunks";
import { SECTION_TYPES_CONFIG } from "containers/Section/constants";
import SectionTitle from "components/Title/SectionTitle";
import Heritage from "components/Heritage";

const ModelSections = () => {
  const dispatch = useDispatch();
  const sections = useSelector(selectModelSections);
  const { code: modelCode } = useParams();

  useEffect(() => {
    dispatch(fetchCarModelSections(modelCode));
  }, [modelCode, dispatch]);

  /* TODO: handle loading */
  if (!sections) return "PLACEHOLDER";

  return (
    <div className="mt-16">
      <SectionTitle>Découvrez votre véhicule</SectionTitle>

      <div className="flex flex-wrap mt-16 -mx-4 md:mx-auto md:w-10/12">
        {sections.map((item) => (
          <div className="w-1/2 lg:w-1/4 px-2 md:px-4">
            <Link
              to={SECTION_TYPES_CONFIG[item.sectionType].redirectURL({
                modelCode: modelCode,
                sectionCode: item.code,
              })}
              className="bg-primary group text-white h-40 md:h-56 flex flex-col justify-center text-center mb-4 md:mb-8 lg:mb-0 hover:bg-secondary transition-colors duration-200">
              <div className="h-10 w-10 lg:h-16 lg:w-16 mx-auto">
                <Heritage className="group-hover:text-primary transition-colors duration-200 w-full h-full top-0">
                  {SECTIONS_DATA[item.code].icon}
                </Heritage>
              </div>

              <h3 className="text-base md:text-xl font-bold mt-7 group-hover:text-primary transition-colors duration-200">
                {SECTIONS_DATA[item.code].name}
              </h3>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModelSections;
