import React, { useMemo, useState } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import ChevronRight from "icons/ChevronRight";
import Times from "icons/Times";
import useProxyBreakpoint, { BREAKPOINTS_KEYS } from "hooks/proxyBreakpoint";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectModelIndicatorLights } from "containers/CarModel/store/selectors";
import { fetchCarModelIndicatorLights } from "containers/CarModel/store/thunks";
import { useParams } from "react-router-dom";
import { API_URL } from "api/instance";
import splitArrayToBlocks from "utils/splitArrayToBlocks";
import EGSlider from "components/Slider";
import { useCallback } from "react";
import Container from "components/Container";
import SectionTitle from "components/Title/SectionTitle";
import HTMLInjector from "components/HTMLInjector";

const getIndicatorLightImage = (imageUrl) =>
  `${imageUrl}`;

const DELIMITER = 12;

const ModelIndicatorLights = () => {
  const dispatch = useDispatch();
  const indicatorLights = useSelector(selectModelIndicatorLights);

  const { code: modelCode } = useParams();
  const [collapsedItemCode, setCollapsedItemCode] = useState(null);

  const handleItemOpen = (item) => {
    /* toggle item if it's already opened or open a new one */
    if (item.content) {
      setCollapsedItemCode((prev) => (prev === item.code ? null : item.code));
    }
  };

  const handleItemClose = () => {
    setCollapsedItemCode(null);
  };

  useEffect(() => {
    dispatch(fetchCarModelIndicatorLights(modelCode));
  }, [modelCode, dispatch]);

  /* TODO: handle loading */
  if (!indicatorLights) return "PLACEHOLDER";

  const blocks = splitArrayToBlocks(indicatorLights, DELIMITER);

  return (
    <div className="mt-16">
      <SectionTitle>
        Apprenez la signification des voyants lumineux de votre véhicule
      </SectionTitle>

      <EGSlider settings={{ adaptiveHeight: false }}>
        {blocks.map((block) => (
          <div>
            <div className="mt-16 mb-1 md:mb-4 md:bg-lightgray md:px-10 py-8 relative overflow-hidden">
              <Container>
                <div className="flex flex-wrap w-full">
                  {block.map((item) => (
                    <div key={item.code} className="w-full md:w-1/2 lg:w-1/3">
                      <button
                        onClick={(e) => handleItemOpen(item, e)}
                        className={classNames(
                          "w-full flex flex-col text-left px-4 md:px-0 py-5",
                          {
                            "cursor-default": !item.content,
                            "bg-lightgray md:bg-transparent":
                              item.code === collapsedItemCode,
                          }
                        )}>
                        <div className="flex items-center justify-start w-full">
                          <div className="w-16 min-w-16 h-16 min-h-16">
                            <img
                              className="border-3 border-primary h-full"
                              src={getIndicatorLightImage(item.icon)}
                              alt=""
                              // alt={item.title}
                            />
                          </div>

                          <div className="md:flex w-auto ml-5">
                            <span
                              className={classNames(
                                item.code === collapsedItemCode && "font-bold"
                              )}>
                              {item.title}
                            </span>
                          </div>

                          {!!item.content && (
                            <div className="ml-auto px-8">
                              <ChevronRight className="text-secondary md:text-primary" />
                            </div>
                          )}
                        </div>
                        {collapsedItemCode &&
                          item.code === collapsedItemCode && (
                            <MobileExtendedIndicatorLightPortal />
                          )}
                      </button>

                      {collapsedItemCode && item.code === collapsedItemCode && (
                        <ExtendedIndicatorLight
                          onClose={handleItemClose}
                          {...item}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </Container>
            </div>
          </div>
        ))}
      </EGSlider>
    </div>
  );
};

const MOBILE_PORTAL_ID = "extended-indicator-light-portal";

const MobileExtendedIndicatorLightPortal = ({ children }) => {
  return <div id={MOBILE_PORTAL_ID}>{children}</div>;
};

const ExtendedIndicatorLight = ({ title, icon, content, onClose }) => {
  const [visible, setVisibility] = useState(false);
  const breakpoint = useProxyBreakpoint();

  const isMobileViewport =
    breakpoint === undefined || breakpoint === BREAKPOINTS_KEYS.sm;

  const getContainer = () => document.getElementById(MOBILE_PORTAL_ID);

  const element = useMemo(
    () => (
      <div
        style={{
          background: !isMobileViewport
            ? "rgba(255, 255, 255, 0.9)"
            : undefined,
        }}
        className="flex flex-col md:shadow-md md:absolute right-0 top-0 bottom-0 w-full md:w-80 bg-transparent md:bg-white">
        <div className="hidden md:flex w-full p-4 flex-shrink-0 ">
          <button className="ml-auto" onClick={onClose}>
            <Times />
          </button>
        </div>

        <div className="border-b-3 border-primary pb-6 hidden md:block flex-shrink-0">
          <img
            className="border-primary border-3 h-24 mx-auto"
            src={getIndicatorLightImage(icon)}
            alt={title}
          />
        </div>

        <div className="md:px-6 pt-6 overflow-y-scroll">
          <h3 className="text-lg font-bold hidden md:flex pb-3">{title}</h3>

          <div
            className="indicator-light-html-wrapper pb-3"
            // dangerouslySetInnerHTML={{ __html: content }}
          >
            <HTMLInjector
              html={content}
              containerID="indicator-light-content"
            />
          </div>
        </div>
      </div>
    ),
    [title, icon, content, isMobileViewport, onClose]
  );

  const setElementVisibility = useCallback(() => {
    // we need to have a mounting container before we render the element visible
    // or else we get an error
    if (!getContainer()) setElementVisibility();
    else setVisibility(true);
  }, [setVisibility]);

  useEffect(() => {
    if (isMobileViewport) setElementVisibility();
  }, [isMobileViewport, setElementVisibility]);

  if (visible && isMobileViewport)
    return ReactDOM.createPortal(element, getContainer());

  return element;
};

export default ModelIndicatorLights;
