import Icon from "components/Icon";

const SearchIcon = ({ ...props }) => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g id="Web" stroke="none" stroke-width="1" fill="none" filerule="evenodd">
        <g transform="translate(-1284.000000, -54.000000)" filerule="nonzero">
          <g
            id="Components/Header/Static"
            transform="translate(80.000000, 0.000000)">
            <g id="Group-7" transform="translate(1179.000000, 54.000000)">
              <g
                id="navigation-controls-arrows-search"
                transform="translate(25.000000, 0.000000)">
                <path
                  d="M7.68571429,1.2 C4.17142857,1.2 1.2,3.8 1.2,7.57238095 C1.2,11.4009524 4.2,14.2866667 7.8,14.2866667 C11.3438095,14.2866667 14.2866667,11.6580952 14.2866667,7.9152381 C14.2866667,4.05809524 11.3152381,1.20095238 7.68666667,1.20095238 M15.4866667,7.88666667 C15.4866667,9.80095238 14.8295238,11.4580952 13.7438095,12.772381 L19.3152381,18.3447619 L18.4866667,19.2019048 L12.8866667,13.6019048 C11.5152381,14.8019048 9.7152381,15.487619 7.77238095,15.487619 C3.54285714,15.487619 0,12.0571429 0,7.6 C0,3.20095238 3.54285714,0 7.71428571,0 C12.0285714,0 15.4857143,3.34380952 15.4857143,7.88666667"
                  id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const Search = ({ ...props }) => {
  return (
    <Icon {...props}>
      <SearchIcon />
    </Icon>
  );
};

export default Search;
