import { Link } from "react-router-dom";

const CardsDisplay = ({ options = [] }) => {
  return (
    <div className="mt-16 mb-16">
      <h1 className="text-xl md:text-3xl uppercase font-bold text-center">
        Prenez soin de votre véhicule
      </h1>

      <div className="w-10/12 lg:w-full mx-auto">
        <div className="flex flex-col md:flex-row flex-wrap mt-16">
          {options.map((option) => (
            <div className="w-full md:w-1/2 lg:w-1/4 px-0 sm:px-2 lg:px-4 text-center mb-4 lg:mb-8">
              <Card to={option.to} label={option.label} image={option.image} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const Card = ({ to, label, image }) => {
  return (
    <Link
      to={to}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${image})`,
      }}
      className="flex items-center justify-center h-60 px-4 bg-cover bg-center">
      <h3 className="text-xl font-bold text-white">{label}</h3>
    </Link>
  );
};

export default CardsDisplay;
