import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTopOnLocationChange({ rootRef }) {
  const location = useLocation();

  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [location]);

  return null;
}

export default ScrollToTopOnLocationChange;
