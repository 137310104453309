import useBreakpoint from "use-breakpoint";

export const BREAKPOINTS_KEYS = {
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
  "2xl": "2xl",
};

/* this matches tailwind default breakpoints config */
export const BREAKPOINTS_CONFIG = {
  [BREAKPOINTS_KEYS.sm]: 640,
  [BREAKPOINTS_KEYS.md]: 768,
  [BREAKPOINTS_KEYS.lg]: 1024,
  [BREAKPOINTS_KEYS.xl]: 1280,
  [BREAKPOINTS_KEYS["2xl"]]: 1536,
};

export const useProxyBreakpoint = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS_CONFIG);

  return breakpoint;
};

export const useMatchBreakpoint = (inputBreakpoint) => {
  const breakpoint = useProxyBreakpoint();

  return breakpoint === inputBreakpoint;
};

export const useIsMobile = () => {
  const breakpoint = useProxyBreakpoint();

  return breakpoint === undefined || breakpoint === BREAKPOINTS_KEYS.sm;
};

export default useProxyBreakpoint;
