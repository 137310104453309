import Icon from "components/Icon";

const StarOutlinedIcon = ({ ...props }) => {
  return (
    <svg
      width="30"
      height="28"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <path
        d="M6.46 25.321c2.87-1.297 4.934-2.46 7.94-4.384 3.006 1.924 5.07 3.087 7.94 4.384-.404-3.534-.762-5.368-1.75-8.858 3.006-2.013 4.666-3.31 6.55-5.145-3.32-1.207-5.518-1.79-8.613-2.326-1.57-3.087-2.736-5.145-4.127-7.292-1.39 2.147-2.557 4.205-4.127 7.292-3.095.537-5.294 1.119-8.613 2.326 1.884 1.835 3.544 3.132 6.55 5.145-.988 3.49-1.346 5.324-1.75 8.858zM22.654 27.2c-3.095-1.342-5.742-2.684-8.254-4.295-2.512 1.61-5.159 2.953-8.254 4.295L4.8 26.216c.27-3.445.718-5.95 1.57-9.127C3.723 15.166 1.84 13.6 0 11.811L.359 10.2C2.916 9.126 5.697 8.321 9.15 7.605 10.677 4.653 11.708 2.863 13.637 0h1.526c1.929 2.863 2.96 4.653 4.486 7.605 3.454.716 6.235 1.521 8.792 2.595l.359 1.61c-1.84 1.79-3.723 3.356-6.37 5.28.852 3.176 1.3 5.681 1.57 9.126l-1.346.984z"
        id="a"
      />
    </svg>
  );
};

const StarOutlined = ({ ...props }) => {
  return (
    <Icon {...props}>
      <StarOutlinedIcon />
    </Icon>
  );
};

export default StarOutlined;
