import { SET_BOOKMARKS } from "./actions";

const initialState = {
  data: null,
};

export default function bookmarksReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_BOOKMARKS:
      return { ...state, data: payload };

    default:
      return state;
  }
}
