import Icon from "components/Icon";

const ThumbsUpIcon = ({ ...props }) => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M.348 5.6H1.45v9.931H.348V5.6zM8.69 6.703l.452-2.913c.237-1.456-.397-2.648-1.324-2.648h-.32v1.534a3.404 3.404 0 01-.265 1.512L6.07 6.478C5.517 7.508 4.772 7.8 3.476 7.8H2.57V6.698h.767c1.103 0 1.456-.265 1.909-1.137l.767-1.539c.294-.59.431-1.245.397-1.903L6.383.105h1.54c1.655 0 2.593 1.694 2.356 3.652l-.238 1.87L14.07 5.6a1.655 1.655 0 011.799 1.655c.017.411-.137.81-.425 1.104.287.318.44.735.425 1.164a1.5 1.5 0 01-1.325 1.511c.136.195.21.426.21.663.01.769-.54 1.432-1.296 1.566.138.193.214.425.215.663a1.517 1.517 0 01-1.655 1.56H6.334c-1.324 0-1.777-1.103-2.968-1.103H2.57V13.28h1.164c1.324 0 1.937 1.103 2.996 1.103h5.137c.452 0 .717-.16.717-.551 0-.392-.265-.552-.717-.552h-.453v-1.103H12.9c.552 0 .773-.16.773-.552s-.243-.552-.773-.552h-1.484V9.97h2.599c.447 0 .74-.133.74-.552 0-.42-.293-.552-.74-.552h-2.599V7.763h2.626c.447 0 .712-.133.712-.552 0-.42-.265-.585-.712-.585l-5.351.077z"
        fill-rule="nonzero"
      />
    </svg>
  );
};

const ThumbsUp = ({ ...props }) => {
  return (
    <Icon {...props}>
      <ThumbsUpIcon />
    </Icon>
  );
};

export default ThumbsUp;
