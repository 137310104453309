import { createSelector } from "reselect";
import { initialState } from "./reducer";

const baseSelector = (state) => state.carModel || initialState;

export const selectModelInfo = createSelector(
  baseSelector,
  (state) => state.model.data
);

export const selectModelSections = createSelector(
  baseSelector,
  (state) => state.sections.data
);

export const selectModelIndicatorLights = createSelector(
  baseSelector,
  (state) => state.indicatorLights.data
);
