import Icon from "components/Icon";

const BarsIcon = ({ ...props }) => {
  return (
    <svg
      width="29px"
      height="24px"
      viewBox="0 0 29 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        filerule="evenodd">
        <g
          id="1.-Accueil"
          transform="translate(-363.000000, -18.000000)"
          filerule="nonzero">
          <g id="burger" transform="translate(363.000000, 18.000000)">
            <rect
              id="Rectangle"
              x="0"
              y="0"
              width="29"
              height="2"
              rx="1"></rect>
            <rect
              id="Rectangle"
              x="0"
              y="11"
              width="19"
              height="2"
              rx="1"></rect>
            <rect
              id="Rectangle"
              x="0"
              y="22"
              width="24"
              height="2"
              rx="1"></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};

const Bars = ({ ...props }) => {
  return (
    <Icon {...props}>
      <BarsIcon />
    </Icon>
  );
};

export default Bars;
