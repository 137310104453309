import { API_URL } from "api/instance";
import classNames from "classnames";
import Container from "components/Container";
import Stripe from "components/Stripe";
import Plus from "icons/Plus";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectIndicatorLights } from "./store/selectors";
import { fetchIndicatorLights } from "./store/thunks";
import "./index.scss";
import SectionTitle from "components/Title/SectionTitle";
import TextTruncate from "react-text-truncate";
import HTMLInjector from "components/HTMLInjector";
import { useRef } from "react";
import useOnClickOutside from "hooks/clickOutside";
import useBodyOverflow from "shared/useBodyOverflow";

const TITLE =
  "Apprenez la signification des voyants lumineux de votre véhicule";

const Lights = () => {
  const dispatch = useDispatch();
  const { code: modelCode } = useParams();
  const lights = useSelector(selectIndicatorLights);
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClose = () => {
    setActiveItem(null);
  };

  const handleItemOpen = (item) => {
    /* toggle item if it's already opened or open a new one */
    if (item.content) {
      setActiveItem(item);
    }
  };

  useEffect(() => {
    dispatch(fetchIndicatorLights(modelCode));
    return () => {};
  }, [modelCode, dispatch]);

  return (
    <>
      <Stripe className="md:hidden">{TITLE}</Stripe>

      <SectionTitle className="hidden md:block my-16">{TITLE}</SectionTitle>

      <Container className="mt-6 mb-16 w-11/12">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 md:gap-8">
          {lights?.map((item) => (
            <div key={item.code} className="">
              <div
                role="button"
                onClick={(e) => handleItemOpen(item, e)}
                className={classNames({
                  "cursor-default": !item.content,
                })}>
                <div className="w-40 max-w-full mx-auto border-3 border-primary relative">
                  <img
                    className="mx-auto w-full"
                    src={getIndicatorLightImage(item.icon)}
                    alt={item.title}
                  />
                  {item.content && (
                    <div className="absolute light-content-indicator right-0 bottom-0">
                      <div className="absolute">
                        <Plus />
                      </div>
                    </div>
                  )}
                </div>

                <div
                  title={item.title}
                  className="w-full text-center mt-4 max-w-xs">
                  <TextTruncate truncateText="..." line={2} text={item.title} />
                </div>
              </div>
            </div>
          ))}
        </div>
        {activeItem && (
          <LightContentModal
            activeItem={activeItem}
            onClose={handleItemClose}
          />
        )}
      </Container>
    </>
  );
};

const LightContentModal = ({ activeItem, onClose }) => {
  const modalContentRef = useRef(null);

  useOnClickOutside(modalContentRef, onClose);
  useBodyOverflow(!!activeItem);

  return (
    <div
      style={{ background: "rgba(0,0,0, 0.8)" }}
      className="fixed top-0 left-0 h-screen w-screen z-50 flex items-center justify-center py-16 overflow-scroll">
      <div ref={modalContentRef} className="w-10/12 max-w-md m-auto">
        <div className="bg-white rounded-xl shadow-xl">
          <div className="flex flex-col w-full">
            <div className="border-b-3 border-primary py-6">
              <img
                className="border-primary border-3 h-24 mx-auto"
                src={getIndicatorLightImage(activeItem?.icon)}
                alt={activeItem?.title}
              />
            </div>

            <div className="pt-6 px-6">
              <h3 className="text-lg font-bold pb-3">{activeItem?.title}</h3>

              <div
                className="indicator-light-html-wrapper pb-3 text-justify"
                // dangerouslySetInnerHTML={{ __html: activeItem?.content }}
              >
                <HTMLInjector
                  html={activeItem?.content}
                  containerID="indicator-light-content"
                />
              </div>
            </div>

            <button
              onClick={onClose}
              type="button"
              className="flex w-full justify-center bg-primary text-white font-bold px-4 py-3 hover:bg-secondary hover:text-primary transition-all uppercase rounded-b-xl">
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const getIndicatorLightImage = (imageUrl) =>
  `${imageUrl}`;

export default Lights;
