import React, { useEffect } from "react";
import bg from "images/main-banner-bg.png";
import CarModels from "./components/CarModels";
import CarModelSearchBar from "./components/CarModelSearchBar";
import { useDispatch } from "react-redux";
import { fetchCarModels } from "./store/thunks";
import MultimediaSection from "./components/MultimediaSection";
import Container from "components/Container";
import { PublicAppRoutes } from "config/AppRoutes";
import SectionTitle from "components/Title/SectionTitle";

/* hack */
const mediaLink = `${PublicAppRoutes.MediaEntry.make({
  mediaCode: "EASY-LINK",
})}${PublicAppRoutes.MediaEntry.SubRoutes.videoGallery.make()}`;

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCarModels());
  }, [dispatch]);

  return (
    <>
      <div className="flex md:hidden">
        <CarModelSearchBar />
      </div>
      <div
        style={{ backgroundImage: `url(${bg})`, height: "65vh" }}
        className="hidden md:block relative bg-cover bg-center">
        <div
          style={{ background: "rgba(255, 255, 255, 0.8)" }}
          className="absolute bottom-24 px-8 py-6">
          <h1 className="text-3xl font-bold uppercase">Guide d'Utilisation</h1>
        </div>
      </div>

      <Container>
        <div className="mt-16">
          <SectionTitle>Choisissez un véhicule</SectionTitle>

          <CarModels />
        </div>

        <div className="mt-24">
          <MultimediaSection to={mediaLink} />
        </div>
      </Container>
    </>
  );
};

export default HomePage;
