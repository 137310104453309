import { getCurrentSection } from "api";
import FETCH_STATUS from "shared/fetchStatus";
import {
  setCurrentSectionData,
  setCurrentSectionStatus,
} from "./actionCreators";

export const fetchCurrentSection = ({ realm, params }) => {
  return (dispatch) => {
    dispatch(setCurrentSectionStatus(FETCH_STATUS.loading));

    getCurrentSection({ realm, params })
      .then((res) => {
        dispatch(setCurrentSectionStatus(FETCH_STATUS.sucess));
        dispatch(setCurrentSectionData(res.data));
      })
      .catch(() => {
        dispatch(setCurrentSectionStatus(FETCH_STATUS.error));
        // TODO: DO ME
      });
  };
};
