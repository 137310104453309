import React, { useRef } from "react";
import _debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Search from "icons/Search";
import { searchCarModelsByKeyword } from "containers/HomePage/store/thunks";
import { selctCarModelSearchResult } from "containers/HomePage/store/selectors";
import ModelReleaseYears from "components/ModelReleaseYear";
import { PublicAppRoutes } from "config/AppRoutes";
import { noop } from "statics";
import { toggleSearch } from "containers/App/store/actionCreators";
import { setCarModelSearchResult } from "containers/HomePage/store/actionCreators";

const DELAY = 400;

/* TODO: Implement proper input component */
const CarModelSearchBar = () => {
  const dispatch = useDispatch();
  const inputValue = useRef(null);

  const handleInputChange = (e) => {
    inputValue.current = e.target.value;

    delayedHandleSearch();
  };

  const handleSearch = () => {
    const keyword = inputValue.current;

    dispatch(searchCarModelsByKeyword(keyword));
  };

  const delayedHandleSearch = _debounce(() => {
    /* cancel previous request, not to send many consecutive calls */
    delayedHandleSearch.cancel();
    handleSearch();
  }, DELAY);

  const reset = () => {
    dispatch(toggleSearch(false));
    dispatch(setCarModelSearchResult(null));
  };

  return (
    <div className="flex w-full h-12 flex-wrap relative">
      <div className="h-full w-10/12">
        <input
          onChange={handleInputChange}
          className="block w-full h-full px-4 text-sm border border-lightgray border-r-0 outline-none focus-within:border-secondary"
          type="text"
          placeholder="ex: Clio 5…"
        />
      </div>

      <div className="h-full w-2/12 flex items-center justify-center bg-secondary text-primary">
        <button
          onClick={handleSearch}
          className="flex justify-center items-center h-full w-full ju">
          <Search />
        </button>
      </div>

      <SearchSuggestions onSelect={reset} />
    </div>
  );
};

const SearchSuggestions = ({ onSelect = noop }) => {
  const searchResult = useSelector(selctCarModelSearchResult);
  const searchResultWrapperRef = useRef(null);

  // useOnClickOutside(searchResultWrapperRef, () => {
  //   /* TODO: trigger onReset instead */
  //   dispatch(setCarModelSearchResult(null));
  // });

  if (!searchResult) return null;

  return (
    <div
      ref={searchResultWrapperRef}
      className={classNames(
        "absolute left-0 w-full top-12 z-10 bg-white break-words overflow-y-auto shadow",
        { "max-h-96": !!searchResult.length, "h-28": !searchResult.length }
      )}>
      {!searchResult.length && (
        <div className="flex h-full items-center w-full">
          <div className="my-auto mx-4 text-gray-400 font-bold text-center w-full">
            Pas de données
          </div>
        </div>
      )}
      {searchResult.map((model) => (
        <Link
          onClick={(e) => onSelect(model, e)}
          to={PublicAppRoutes.CarModel.make(model.code)}>
          <div className="flex items-center px-4 py-3 border-b border-lightgray">
            <div className="w-16 h-16 overflow-hidden round mr-4">
              <img
                className="object-cover w-full h-full rounded-full"
                src={model.image}
                alt=""
              />
            </div>
            <div className="font-bold mr-2">{model.modelName}</div>
            <div className="text-grayscale-200">
              <ModelReleaseYears
                start={model.startRelease}
                latest={model.lastRelease}
              />
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default CarModelSearchBar;
