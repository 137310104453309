import { setCurrentArticle } from "containers/Article/store/actionCreators";
import ThumbsDown from "icons/ThumbsDown";
import ThumbsUp from "icons/ThumbsUp";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { noop } from "statics";
import { selectArticlesList } from "./store/selectors";
import { selectCurrentSection } from "containers/Section/store/selectors";
import { fetchArticlesList } from "./store/thunks";
import { fetchCurrentSection } from "containers/Section/store/thunks";
import Container from "components/Container";
import ReturnButton from "components/ReturnButton";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import _capitalize from "lodash.capitalize";
import { BREAKPOINTS_CONFIG } from "hooks/proxyBreakpoint";
import { useRealmAdapter, useSwitchRealm } from "containers/RealmAdapter";
import { getRealmSubRoutes } from "containers/RealmAdapter/utils";
import { REALMS } from "containers/RealmAdapter/constants";
import { API_URL } from "api/instance";

const stateSelector = createStructuredSelector({
  articles: selectArticlesList,
  section: selectCurrentSection,
});

const Articles = () => {
  const dispatch = useDispatch();
  const { code: modelCode, sectionCode, mediaCode } = useParams();
  const { articles, section } = useSelector(stateSelector);
  const { makeURL, realm } = useRealmAdapter();
  const switchRealm = useSwitchRealm();

  const handleArticleClick = (articleId) => {
    /* TODO: rethink */
    const activeArticle = articles.find((item) => item.articleId === articleId);

    dispatch(setCurrentArticle(activeArticle));
  };

  useEffect(() => {
    const params = { modelCode, mediaCode, sectionCode };

    dispatch(fetchCurrentSection({ realm, params }));
    dispatch(fetchArticlesList({ realm, params }));
  }, [modelCode, sectionCode, realm, mediaCode, dispatch]);

  if (!articles) return "PLACEHOLDER";

  const desc = switchRealm({
    [REALMS.model]: section?.description,
    [REALMS.media]: section?.title,
  });

  return (
    <div>
      {section?.imageUrl && (
        <div
          style={{
            backgroundImage: `url(${switchRealm({
              [REALMS.model]: section.imageUrl,
              [REALMS.media]: `${section.imageUrl}`,
            })})`,
          }}
          className="flex justify-center items-center relative h-auto md:model-banner-xl bg-center bg-cover px-6 pt-6 pb-6 min-h-52">
          {desc && (
            <div
              style={{ background: "rgba(255, 255, 255, 0.8)" }}
              className="md:absolute md:left-12 md:bottom-28 px-8 py-6 w-full md:max-w-sm">
              <h1 className="text-xl font-bold uppercase mb-1 text-center">
                {desc}
              </h1>
            </div>
          )}
        </div>
      )}

      <div className="bg-lightgray py-6">
        <Container>
          <div className="mb-6">
            <ReturnButton
              to={switchRealm({
                [REALMS.model]: "../../",
                [REALMS.media]: "../../",
              })}>
              Retour
            </ReturnButton>
          </div>

          <ResponsiveMasonry
            columnsCountBreakPoints={{
              [BREAKPOINTS_CONFIG.sm]: 1,
              [BREAKPOINTS_CONFIG.md]: 2,
              [BREAKPOINTS_CONFIG.lg]: 3,
            }}>
            <Masonry gutter={14}>
              {articles.map(({ articleId, code, title, feedback }) => (
                <ArticleBlock
                  key={code}
                  onClick={handleArticleClick}
                  id={articleId}
                  title={title}
                  feedback={{
                    positive: feedback.positiveVotes,
                    negative: feedback.negativeVotes,
                  }}
                  to={makeURL(
                    getRealmSubRoutes(realm).Article.make({
                      code: modelCode,
                      articleCode: code,
                      sectionCode,
                    })
                  )}
                />
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </Container>
      </div>
    </div>
  );
};

const ArticleBlock = ({
  id,
  title,
  feedback = { positive: null, negative: null },
  to,
  onClick = noop,
}) => {
  return (
    <Link
      to={to}
      onClick={(e) => onClick(id, e)}
      className="flex flex-wrap bg-white px-4 py-4 filter shadow-custom-lg">
      <div className="w-8/12 sm:w-9/12 md:w-full text-left">
        <h1 className="text-sm md:text-lg lg:text-2xl font-bold truncate md:whitespace-normal md:overflow-visible">
          {_capitalize(title)}
        </h1>
      </div>

      <div className="grid grid-cols-2 gap-4 ml-auto md:ml-0 mt-0 md:mt-14">
        <Feedback icon={<ThumbsUp />} label={feedback.positive} />
        <Feedback icon={<ThumbsDown />} label={feedback.negative} />
      </div>
    </Link>
  );
};

const Feedback = ({ icon, label }) => (
  <div className="flex items-center">
    <div className="text-grayscale-300 mr-2">{icon}</div>
    <div className="text-grayscale-300">{label}</div>
  </div>
);

export default Articles;
