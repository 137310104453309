import { createSelector } from "reselect";

const baseSelector = (state) => state.homePage;

export const selectCarModelFilter = createSelector(
  baseSelector,
  (state) => state.carModels.activeType
);

export const selectModelTypes = createSelector(baseSelector, (state) => {
  return state.carModels.modelTypes?.map((modelType) => ({
    code: modelType.code,
    name: modelType.name,
    order: modelType.order,
  }));
});

export const selectCarModelsByFilter = createSelector(baseSelector, (state) => {
  const { activeType, modelTypes = [] } = state.carModels;

  if (!activeType) return [];

  const models =
    modelTypes.find((modelType) => modelType.code === activeType)?.models || [];

  return models;
});

export const selctCarModelSearchResult = createSelector(
  baseSelector,
  (state) => state.search.result
);

export const selctCarModelSearchStatus = createSelector(
  baseSelector,
  (state) => state.search.status
);
