import {
  SET_MODEL_INFO,
  SET_MODEL_SECTIONS,
  SET_MODEL_INDICATOR_LIGHTS,
} from "./actions";

// const makeDefaultStructure = (override = {}) => ({
//   status: "idle",
//   data: null,
//   ...override,
// });

// TODO: restructure
export const initialState = {
  model: {
    status: "idle",
    data: null,
  },

  sections: {
    status: "idle",
    data: null,
  },

  indicatorLights: {
    status: "idle",
    data: null,
  },
};

export default function carModelReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_MODEL_INFO: {
      const draft = { ...state };
      draft.model.data = payload;

      return { ...draft };
    }

    case SET_MODEL_SECTIONS: {
      const draft = { ...state };
      draft.sections.data = payload;

      return { ...draft };
    }

    case SET_MODEL_INDICATOR_LIGHTS: {
      const draft = { ...state };
      draft.indicatorLights.data = payload;

      return { ...draft };
    }

    default:
      return { ...state };
  }
}
