import { createSelector } from "reselect";

const baseSelector = (state) => state.mediaEntry;

export const selectMediaSections = createSelector(
  baseSelector,
  (state) => state.mediaSections.data
);

export const selectMediaSectionsStatus = createSelector(
  baseSelector,
  (state) => state.mediaSections.status
);

export const selectMediaInfo = createSelector(
  baseSelector,
  (state) => state.mediaInfo.data
);
