import Icon from "components/Icon";

const ChevronDownIcon = ({ ...props }) => {
  return (
    <svg
      width="16px"
      height="10px"
      viewBox="0 0 16 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        filerule="evenodd">
        <g
          id="1.-Accueil"
          transform="translate(-303.000000, -25.000000)"
          filerule="nonzero">
          <g id="Group" transform="translate(96.000000, 20.000000)">
            <g id="Path-3" transform="translate(207.000000, 5.000000)">
              <g
                id="Path-2"
                transform="translate(8.000000, 4.923077) rotate(-360.000000) translate(-8.000000, -4.923077) translate(0.000000, 0.000000)">
                <polygon
                  id="Path"
                  transform="translate(8.000000, 4.923077) rotate(-360.000000) translate(-8.000000, -4.923077) "
                  points="16 1.32553846 14.7556923 0 8 7.19507692 1.24430769 0 0 1.32553846 6.75569231 8.52061538 8 9.84615385 9.24430769 8.52061538"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const ChevronDown = ({ ...props }) => {
  return (
    <Icon {...props}>
      <ChevronDownIcon />
    </Icon>
  );
};

export default ChevronDown;
