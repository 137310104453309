import { PublicAppRoutes } from "config/AppRoutes";
import { REALMS } from "./constants";
import { switchRealm } from "./realmApiSelector";

export const getRealmSubRoutes = (realm) => {
  return switchRealm(realm, {
    [REALMS.model]: PublicAppRoutes.CarModel.SubRoutes,
    [REALMS.media]: PublicAppRoutes.MediaEntry.SubRoutes,
  });
};
