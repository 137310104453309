import { LinkCard } from "components/Card";
import SectionTitle from "components/Title/SectionTitle";
import { PublicAppRoutes } from "config/AppRoutes";
import { useRealmAdapter, withAdapter } from "containers/RealmAdapter";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Route, Switch, useParams } from "react-router-dom";
import multimediaBanner from "images/multimedia.png";
import { MEDIA_SECTIONS_DATA, MEDIA_SECTION_TYPES_CONFIG } from "./constants";
import { selectMediaSections } from "./store/selectors";
import { fetchMediaInfo, fetchMediaSections } from "./store/thunks";
import Container from "components/Container";
import AdapterLink from "containers/RealmAdapter/AdapterLink";
import VideoGallery from "containers/VideoGallery";
import Article from "containers/Article";
import Articles from "containers/Articles";
import Section from "containers/Section";
import { REALMS } from "containers/RealmAdapter/constants";
import MediaBanner from "./components/MediaBanner";
import Heritage from "components/Heritage";

const MediaEntry = () => {
  const { makePath, makeURL } = useRealmAdapter();

  return (
    <Switch>
      <Route exact path={makePath("/")} component={RootPage} />
      <Route
        exact
        path={makePath(PublicAppRoutes.MediaEntry.SubRoutes.videoGallery.path)}
        component={VideoGallery}
      />
      <Route
        exact
        path={makePath(PublicAppRoutes.MediaEntry.SubRoutes.Section.path)}
        component={Section}
      />
      <Route
        exact
        path={makePath(
          PublicAppRoutes.MediaEntry.SubRoutes.SectionRedirect.path
        )}
        component={() => <Redirect to={makeURL("/")} />}
      />
      <Route
        exact
        path={makePath(PublicAppRoutes.MediaEntry.SubRoutes.Articles.path)}
        component={Articles}
      />
      <Route
        exact
        path={makePath(PublicAppRoutes.MediaEntry.SubRoutes.Article.path)}
        component={Article}
      />
    </Switch>
  );
};

const RootPage = () => {
  const { mediaCode } = useParams();
  const dispatch = useDispatch();
  const { makeURL } = useRealmAdapter();
  const sections = useSelector(selectMediaSections);

  useEffect(() => {
    dispatch(fetchMediaSections(mediaCode));
    dispatch(fetchMediaInfo(mediaCode));
    return () => {};
  }, [mediaCode, dispatch]);

  return (
    <>
      <MediaBanner className="relative h-auto md:model-banner-xl bg-center bg-cover px-6 pt-6 pb-18">
        <div
          style={{ background: "rgba(255, 255, 255, 0.8)" }}
          className="md:absolute md:left-12 md:bottom-28 px-8 py-6">
          <h1 className="text-3xl font-bold uppercase mb-4">
            Prenez en main votre Multimédia
          </h1>

          <Link
            to={makeURL(
              PublicAppRoutes.MediaEntry.SubRoutes.videoGallery.make({
                mediaCode,
              })
            )}
            className="flex w-full justify-center bg-primary text-white font-bold px-4 py-3 hover:bg-secondary hover:text-primary transition-all uppercase">
            voir les videos
          </Link>
        </div>
      </MediaBanner>

      <Container className="container-max">
        <div className="mt-16">
          <SectionTitle>Technologies & fonctionnalités</SectionTitle>

          <div className="flex flex-wrap mt-16 md:mx-auto md:w-10/12 justify-center">
            {sections?.map((item) => (
              <div className="w-1/2 lg:w-1/4 px-2 md:px-4">
                <AdapterLink
                  make={(params) =>
                    makeURL(
                      MEDIA_SECTION_TYPES_CONFIG[item.sectionType].redirectURL({
                        ...params,
                        sectionCode: item.code,
                      })
                    )
                  }
                  className="bg-primary group text-white h-40 md:h-56 flex flex-col justify-center text-center mb-4 md:mb-8 hover:bg-secondary transition-colors duration-200">
                  <Heritage className="group-hover:text-primary transition-colors duration-200">
                    {MEDIA_SECTIONS_DATA[item.code].icon}
                  </Heritage>

                  <h3 className="text-base md:text-xl font-bold mt-7 group-hover:text-primary transition-colors duration-200">
                    {item.title || MEDIA_SECTIONS_DATA[item.code].name}
                  </h3>
                </AdapterLink>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-16 mb-12">
          <div className="w-full ">
            <LinkCard
              to="/TBD"
              image={multimediaBanner}
              label="Navigation Connectée"
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default withAdapter(MediaEntry, { realm: REALMS.media });
