import Icon from "components/Icon";

const TimesIcon = ({ ...props }) => {
  return (
    <svg
      width="27px"
      height="27px"
      viewBox="0 0 27 27"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        filerule="evenodd">
        <g
          id="0.-Menu"
          transform="translate(-13.000000, -14.000000)"
          filerule="nonzero">
          <g
            id="G_ICONS_24px_CANCEL_v4"
            transform="translate(13.000000, 14.000000)">
            <polygon
              id="Path"
              points="0.79 24.84 12.26 13.37 0.79 1.9 2.23 0.46 13.7 11.93 25.18 0.46 26.62 1.85 15.14 13.37 26.66 24.89 25.22 26.33 13.7 14.81 2.23 26.28"></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};

const Times = ({ ...props }) => {
  return (
    <Icon {...props}>
      <TimesIcon />
    </Icon>
  );
};

export default Times;
