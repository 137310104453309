import { getVideosList } from "api";
import { batch } from "react-redux";
import {
  setActiveCategory,
  setCategories,
  setVideosList,
} from "./actionCreators";

const extractCategories = (data) =>
  data.map(({ title, order }) => ({ title, order }));

export const fetchVideosList = ({ realm, params }) => {
  return (dispatch) => {
    getVideosList({ realm, params })
      .then((res) => {
        console.log(res.data);
        const categories = extractCategories(res.data);

        batch(() => {
          dispatch(setVideosList(res.data));
          dispatch(setCategories(categories));
          dispatch(setActiveCategory(categories[0]));
        });
      })
      .catch(() => {
        // TODO: DO ME
      });
  };
};
