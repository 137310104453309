import Axios from "axios";
import { __DEV__ } from "statics";

export const API_URL = __DEV__ ? process.env.REACT_APP_API_URL : "/api";

const instance = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

const CDN = Axios.create({
  baseURL: API_URL,
  withCredentials: false,
});

export { instance as Api, CDN as instanceCDN };
