/*
 *
 * LanguageProvider reducer
 *
 */

import { DEFAULT_LOCALE } from "containers/App/constants";

import { CHANGE_LOCALE } from "./actions";

export const initialState = {
  locale: DEFAULT_LOCALE,
};

/* eslint-disable default-case, no-param-reassign */
export default function languageProviderReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CHANGE_LOCALE:
      return { ...state, locale: payload };
    default:
      return { ...state };
  }
}
