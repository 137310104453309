export default function splitArrayToBlocks(array, delimiter) {
  if (!array.length) return [];

  let start = 0;

  const blockCount = Math.ceil(array.length / delimiter);
  const proxyBlocks = [];

  for (let index = 1; index <= blockCount; index++) {
    const block = array.slice(start, delimiter * index) || [];

    proxyBlocks.push(block);

    start += delimiter;
  }

  return proxyBlocks;
}
