import React from "react";
import classNames from "classnames";

const TITLE_VARIANTS = {
  lg: "lg",
  xl: "xl",
  "2xl": "2xl",
  "3xl": "3xl",
  "4xl": "4xl",
  "5xl": "5xl",
  "6xl": "6xl",
  "7xl": "7xl",
  "8xl": "8xl",
  "9xl": "9xl",
};

const DEFAULT_TAG = "h1";

const BASE_CLASSNAME = "font-bold";

export const Title = ({
  as: Tag = DEFAULT_TAG,
  baseClassName = BASE_CLASSNAME,
  className,
  variant: variantPrefix = TITLE_VARIANTS.xl,
  children,
}) => {
  const mappedVariantPrefix = TITLE_VARIANTS[variantPrefix];
  const variant =
    variantPrefix && mappedVariantPrefix && `text-${mappedVariantPrefix}`;

  return (
    <Tag className={classNames(baseClassName, className, variant)}>
      {children}
    </Tag>
  );
};
