import { createSelector } from "reselect";

const baseSelector = (state) => state.article;

export const selectCurrentArticleInfo = createSelector(
  baseSelector,
  (state) => state.articleInfo
);

export const selectCurrentArticleHtmlContent = createSelector(
  baseSelector,
  (state) => state.htmlContent
);

export const selectUserHaveVoted = createSelector(
  baseSelector,
  (state) => state.userHaveVoted
);

export const selectArticleBookmarked = createSelector(
  baseSelector,
  (state) => state.bookmarked
);
