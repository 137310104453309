import React, { useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { PrivateAppRoutes, PublicAppRoutes } from "config/AppRoutes";
import { PrimaryAppLayout } from "components/Layout";
import ScrollToTopOnLocationChange from "components/ScrollToTopOnLocationChange";
import CarModel from "containers/CarModel";
import CMS from "containers/CMS";
import HomePage from "containers/HomePage";
import MediaEntry from "containers/MediaEntry";
import MockError404 from "components/MockError404";
import LegalNotice from "containers/LegalNotice";
import MatomoPageTrack from "components/MatomoPageTrack";

const AppRouter = () => {
  const rootRef = useRef();

  return (
    <div ref={rootRef}>
      <Router>
        <ScrollToTopOnLocationChange />
        <MatomoPageTrack />
        <Switch>
          <Route path={PrivateAppRoutes.Root.path}>
            <CMS />
          </Route>

          <Route path={PublicAppRoutes.Root.path}>
            <PrimaryAppLayout>
              <Switch>
                <Route
                  exact
                  path={PublicAppRoutes.HomePage.path}
                  component={HomePage}
                />
                <Route
                  path={PublicAppRoutes.CarModel.path}
                  component={CarModel}
                />
                <Route
                  path={PublicAppRoutes.MediaEntry.path}
                  component={MediaEntry}
                />
                <Route
                  path={PublicAppRoutes.LegalNotice.path}
                  component={LegalNotice}
                />
                {/* TODO: Add error page */}
                <Route
                  component={() => <MockError404 text="Page non trouvée" />}
                />
              </Switch>
            </PrimaryAppLayout>
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default AppRouter;
