import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { fetchCarModelInfo } from "containers/CarModel/store/thunks";
import { selectModelInfo } from "containers/CarModel/store/selectors";
import Videos from "./components/Videos";
import { fetchVideosList } from "./store/thunks";
import { VideoGalleryContext } from "./context";
import { useMemo } from "react";
import Stripe from "components/Stripe";
import { selectActiveVideo } from "./store/selectors";
import VideoPlayer from "./components/VideoPlayer";
import "./index.scss";
import { setVideosList } from "./store/actionCreators";
import { useRealmAdapter, useSwitchRealm } from "containers/RealmAdapter";
import ModelBanner from "containers/CarModel/components/ModelBanner";
import MediaBanner from "containers/MediaEntry/components/MediaBanner";
import { REALMS } from "containers/RealmAdapter/constants";
import { fetchMediaInfo } from "containers/MediaEntry/store/thunks";

const stateSelector = createStructuredSelector({
  modelInfo: selectModelInfo,
});

const VideoGallery = ({ root }) => {
  const dispatch = useDispatch();
  const { code: modelCode, mediaCode } = useParams();
  const { modelInfo } = useSelector(stateSelector);
  const { realm } = useRealmAdapter();
  const switchRealm = useSwitchRealm();

  const wrapperRef = useRef(null);

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const contextValue = useMemo(
    () => ({ root, scrollToTop }),
    [root, scrollToTop]
  );

  useEffect(() => {
    const exec = switchRealm({
      [REALMS.model]: () => dispatch(fetchCarModelInfo(modelCode)),
      [REALMS.media]: () => dispatch(fetchMediaInfo(mediaCode)),
    });

    exec();
  }, [modelCode, mediaCode, switchRealm, dispatch]);

  useEffect(() => {
    const params = { modelCode, mediaCode };

    dispatch(fetchVideosList({ realm, params }));

    return () => {
      /* DATA RESET */
      dispatch(setVideosList(null));
    };
  }, [root, modelCode, mediaCode, realm, dispatch]);

  return (
    <div ref={wrapperRef}>
      <VideoGalleryContext.Provider value={contextValue}>
        <Stripe className="block md:hidden">Vidéothèque</Stripe>

        {modelCode && modelInfo && (
          <Banner image={modelInfo.image} label={modelInfo.modelName} />
        )}
        {!modelCode && <Banner />}

        <Videos />
      </VideoGalleryContext.Provider>
    </div>
  );
};

const Banner = ({ image, label }) => {
  const activeVideo = useSelector(selectActiveVideo);
  const switchRealm = useSwitchRealm();

  const BannerComponent = switchRealm({
    [REALMS.model]: ({ children, ...props }) => (
      <ModelBanner {...props}>{children}</ModelBanner>
    ),
    [REALMS.media]: ({ children, ...props }) => (
      <MediaBanner {...props}>{children}</MediaBanner>
    ),
  });

  const labelElement = switchRealm({
    [REALMS.model]: label && <p className="mb-4 text-lg">{label}</p>,
    [REALMS.media]: null,
  });

  return (
    <>
      {!activeVideo && (
        <BannerComponent className="hidden md:block relative h-auto md:model-banner-xl bg-center bg-cover px-6 pt-6 pb-18">
          <div
            style={{ background: "rgba(255, 255, 255, 0.8)" }}
            className="md:absolute md:left-0 md:bottom-28 md:pl-16 px-8 py-6">
            <h1 className="text-3xl font-bold uppercase mb-1">Vidéothèque</h1>
            {labelElement}
          </div>
        </BannerComponent>
      )}

      {activeVideo && (
        <VideoPlayer title={activeVideo.title} videoID={activeVideo.videoID} />
      )}
    </>
  );
};

export default VideoGallery;
