import Icon from "components/Icon";

const VehicleIcon = ({ ...props }) => {
  return (
    <svg width="44" height="48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.429 17.75H12.1c-2.848 0-4.012 2.53-5.468 7.771h29.96c-.304-1.025-.545-1.937-.798-2.734h7.417v2.126l-3.177 1.405a7.835 7.835 0 013.089 6.126V46a1.962 1.962 0 01-1.937 2h-6.075a2.063 2.063 0 01-2.063-2v-2.974H10.265V46a2.063 2.063 0 01-2.063 2H2.063a1.962 1.962 0 01-1.936-2V32.444a7.835 7.835 0 013.088-6.126L0 24.913v-2.126h4.797c1.709-4.557 3.278-7.468 6.923-7.468h8.696l.013 2.43zM37.87 27.875H5.354a6.873 6.873 0 00-2.734 4.557V45.57h5.063v-4.974H35.49v4.974h5.062V32.444a6.873 6.873 0 00-2.683-4.556v-.013zM5.05 32.938a2.531 2.531 0 115.063 0 2.531 2.531 0 01-5.063 0zM25.593.03h14.884a2.38 2.38 0 012.671 2.67v14.682a2.747 2.747 0 01-3.038 2.912H34.53c-.366.91-.86 1.762-1.469 2.531h-3.037a13.77 13.77 0 01-1.456-2.531h-2.531a2.747 2.747 0 01-3.038-2.912V2.612A2.455 2.455 0 0125.593.004V.03zm7.417 2.328h-6.57a1 1 0 00-1.088 1.038v13.556c0 .67.355 1.038 1.089 1.038h3.708a11.91 11.91 0 001.393 2.911 18.011 18.011 0 001.468-2.911h6.683a.95.95 0 001.088-1.038V3.41a.975.975 0 00-1.088-1.05H35.49v2.733h-2.532l.05-2.734zM30.478 7.65h4.86v5.228h2.735v2.303h-7.595v-2.354h2.532V9.953h-2.532V7.649zm2.532 25.314a2.531 2.531 0 115.063 0 2.531 2.531 0 01-5.063 0z"
        fill-rule="nonzero"
      />
    </svg>
  );
};

const Vehicle = ({ ...props }) => {
  return (
    <Icon {...props}>
      <VehicleIcon />
    </Icon>
  );
};

export default Vehicle;
