import Icon from "components/Icon";

const ExternalIcon = ({ ...props }) => {
  return (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M.703 23.288V3.426H12.29v1.54H2.359v16.733h16.618v-9.93h1.655v11.585L.703 23.288zm23.173-22.13a74.193 74.193 0 01-1.308 7.672l-1.655-.322a96.045 96.045 0 001.233-5.636L11.065 14.069l-1.134-1.15L21.203 1.796c-1.986.397-3.31.678-5.793 1.274l-.24-1.655A69.517 69.517 0 0122.833.066l1.043 1.093z"
        fill-rule="nonzero"
      />
    </svg>
  );
};

const External = ({ ...props }) => {
  return (
    <Icon {...props}>
      <ExternalIcon />
    </Icon>
  );
};

export default External;
