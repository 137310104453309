import FETCH_STATUS from "shared/fetchStatus";
import { SET_MEDIAS } from "./actions";

const initialState = {
  status: FETCH_STATUS.idle,
  data: null,
};

export default function mediasReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_MEDIAS: {
      return { ...state, data: payload };
    }

    default:
      return state;
  }
}
