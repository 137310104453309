import React from "react";
import { Link, useParams } from "react-router-dom";

const AdapterLink = ({ children, make, ...props }) => {
  const { code: modelCode, mediaCode, sectionCode, articleCode } = useParams();

  const path = make({ modelCode, mediaCode, sectionCode, articleCode });

  return (
    <Link to={path} {...props}>
      {children}
    </Link>
  );
};

export default AdapterLink;
