import { PublicAppRoutes } from "config/AppRoutes";

export const SECTION_TYPES_KEYS = {
  CARD: "CARD",
  LIST: "LIST",
  STANDARD: "STANDARD",
};

export const SECTION_CODES = {
  HAND: "HAND", // hand over
  TECH: "TECH", // technology
  CONF: "CONF", // confort
  MAIN: "MAIN", // maintenance
};

/* TBD???? */
export const SECTION_TYPES_CONFIG = {
  [SECTION_TYPES_KEYS.STANDARD]: {
    redirectURL: ({ modelCode, sectionCode }) =>
      PublicAppRoutes.Articles.make({ code: modelCode, sectionCode }),
  },
  [SECTION_TYPES_KEYS.LIST]: {
    redirectURL: ({ modelCode, sectionCode }) =>
      PublicAppRoutes.Articles.make({ code: modelCode, sectionCode }),
  },
  [SECTION_TYPES_KEYS.CARD]: {
    redirectURL: ({ modelCode, sectionCode }) =>
      PublicAppRoutes.Section.make({ code: modelCode, sectionCode }),
  },
};
