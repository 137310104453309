import { Provider as ReduxProvider } from "react-redux";
import AppRouter from "AppRouter";
import { AppStore } from "store";
import LanguageProvider from "containers/LanguageProvider";
import AppEntry from "containers/App";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const AppProviders = ({ messages }) => {
  const { enableLinkTracking } = useMatomo();

  enableLinkTracking();

  return (
    <ReduxProvider store={AppStore}>
      <LanguageProvider messages={messages}>
        <AppEntry>
          <AppRouter />
        </AppEntry>
      </LanguageProvider>
    </ReduxProvider>
  );
};

export default AppProviders;
