import "rc-table/assets/index.css";

import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { PrivateAppRoutes } from "config/AppRoutes";

import CMSLayout from "containers/CMS/Layout";
import Dashboard from "containers/CMS/Dashboard";
import MockError404 from "components/MockError404";
import { useDispatch } from "react-redux";
import { fetchManufacturerInfo } from "containers/App/store/thunks";

const CMS = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchManufacturerInfo());
  }, [dispatch]);

  return (
    <CMSLayout>
      <Switch>
        <Route
          exact
          path={PrivateAppRoutes.Dashboard.path}
          component={Dashboard}
        />
        {/* TODO: Add Not found for CMS based pages */}
        <Route
          component={() => <MockError404 text="CMS: Page non trouvée" />}
        />
      </Switch>
    </CMSLayout>
  );
};

export default CMS;
