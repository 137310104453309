import {
  SET_CURRENT_SECTION_DATA,
  SET_CURRENT_SECTION_STATUS,
} from "./actions";

export const setCurrentSectionData = (data) => ({
  type: SET_CURRENT_SECTION_DATA,
  payload: data,
});
export const setCurrentSectionStatus = (status) => ({
  type: SET_CURRENT_SECTION_STATUS,
  payload: status,
});
