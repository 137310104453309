import React from "react";
import Img from "images/404.png";
import { Link } from "react-router-dom";

const MockError404 = () => {
  return (
    <div
      style={{
        height: "100vh",
      }}
      className="py-3 text-center flex flex-col">
      <h1 className="text-5xl font-bold uppercase pt-5">Oups !</h1>
      <h2 className="text-2xl font-bold uppercase mt-2">Voilà qui n’était pas prévu</h2>
      <img src={Img} alt="404" className="self-center" />
      <p className="text-base m-4 font-semibold	self-center max-w-md">Désolé, mais ce lien est introuvable !
        nous vous inquiétez pas, désirez vous qu’on vous ramène ?</p>
      <Link
        to='/'
        className="inline-block bg-primary text-white font-bold px-4 py-3 hover:bg-secondary hover:text-primary transition-all max-w-xs mx-6 self-center">
       oui ! ramenez moi
      </Link>
    </div>
  );
};

export default MockError404;
