import ArticleBlock from "components/ArticleBlock";
import Container from "components/Container";
import { Title } from "components/Title";
import { BREAKPOINTS_CONFIG } from "hooks/proxyBreakpoint";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { selectBookmarks } from "./store/selectors";
import { fetchBookmarks } from "./store/thunks";

const Bookmarks = () => {
  const dispatch = useDispatch();
  const bookmarks = useSelector(selectBookmarks);

  console.log(bookmarks);

  useEffect(() => {
    dispatch(fetchBookmarks());
  }, [dispatch]);

  return (
    <div className="bg-lightgray">
      <Container className="pt-6 md:pt-10 pb-16">
        <Title variant={null} className="uppercase text-lg md:text-2xl px-2">
          Mes favoris
        </Title>

        <div className="mt-6 md:mt-10">
          {!bookmarks?.length && (
            <div className="mt-16 px-18 py-18 text-center bg-gray-200 mx-4 text-gray-400 font-bold">
              Pas de données
            </div>
          )}
          {!!bookmarks?.length && (
            <ResponsiveMasonry
              columnsCountBreakPoints={{
                [BREAKPOINTS_CONFIG.sm]: 1,
                [BREAKPOINTS_CONFIG.md]: 2,
                [BREAKPOINTS_CONFIG.lg]: 3,
              }}>
              <Masonry gutter={14}>
                {bookmarks?.map((article) => (
                  <ArticleBlock
                    key={article.code}
                    id={article.code}
                    title={article.title}
                    to={article.inAppPath}
                    feedback={null}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Bookmarks;
