import classNames from "classnames";
import { Link } from "react-router-dom";

export const Card = ({
  className,
  as: Tag = "div",
  label,
  image,
  ...props
}) => {
  return (
    <Tag
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${image})`,
      }}
      className={classNames(
        "flex items-center justify-center h-60 px-4 bg-cover bg-center",
        className
      )}
      {...props}>
      {label && <h3 className="text-xl font-bold text-white">{label}</h3>}
    </Tag>
  );
};

export const LinkCard = ({ to, ...props }) => {
  return <Card as={Link} to={to} {...props} />;
};
