import Icon from "components/Icon";

const MaintenaceIcon = ({ ...props }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 91 56"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M9.188 31.985H4.95v10.59H.715V17.158H4.95v10.486h4.237V8.812h16.945V4.575H13.361V.34h29.654v4.236H30.306v4.237h26.478l15.95 20.017v5.401h4.236v-8.473h12.71v29.655H76.97V46.94h-4.236v8.473H20.16L9.188 44.61V31.985zm59.31-1.928L54.453 12.985H13.466v30.078l8.325 8.155h46.6v-8.473h12.71v8.473h4.236V30.036h-4.236v8.473H68.39l.106-8.452z" />
    </svg>
  );
};

const Maintenace = ({ ...props }) => {
  return (
    <Icon {...props}>
      <MaintenaceIcon />
    </Icon>
  );
};

export default Maintenace;
