import React from "react";
import Electric from "icons/Electric";
import Individual from "icons/Individual";
import Utility from "icons/Utility";

export const FILTER_OPTIONS_KEYS = {
  individual: "001",
  utility: "002",
  electric: "003",
};

export const FILTER_OPTIONS_COMPLEMENTARY_DATA = {
  [FILTER_OPTIONS_KEYS.individual]: {
    icon: <Individual className="w-9" />,
  },
  [FILTER_OPTIONS_KEYS.utility]: {
    icon: <Utility className="w-9" />,
  },
  [FILTER_OPTIONS_KEYS.electric]: {
    icon: <Electric className="w-9" />,
  },
};
