import { PublicAppRoutes } from "config/AppRoutes";
import Facebook from "icons/Facebook";
import Instagram from "icons/Instagram";
import Twitter from "icons/Twitter";
import Youtube from "icons/Youtube";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    /* remove pb-24 */
    <div className="flex flex-wrap items-center bg-primary text-white pb-24 md:pb-0">
      <div className="flex flex-col md:flex-row px-4 py-8">
        <a className="text-white font-bold px-3 text-sm mb-5 md:mb-0" href="/#">
          Gestion des cookies
        </a>
        <Link
          to={PublicAppRoutes.LegalNotice.path}
          className="text-white font-bold px-3 text-sm mb-5 md:mb-0"
          href="/#">
          Mentions légales
        </Link>
        <a className="text-white font-bold px-3 text-sm mb-5 md:mb-0" href="/#">
          Politique d'utilisation des cookies
        </a>
        {/* mb-5 md:mb-0 */}
        <span className="text-white font-bold px-3 text-sm">
          Copyright Renault
        </span>
      </div>

      <div className="flex ml-auto w-full md:w-auto justify-center md:justify-start bg-white md:bg-transparent px-4 py-4 md:py-8">
        <a
          className="px-2 text-black md:text-white"
          target="_blank"
          href="https://www.facebook.com/renaultmaroc"
          rel="noreferrer">
          <Facebook />
        </a>
        <a
          className="px-2 text-black md:text-white"
          target="_blank"
          href="https://twitter.com/renaultmaroc"
          rel="noreferrer">
          <Twitter />
        </a>
        <a
          className="px-2 text-black md:text-white"
          target="_blank"
          href="https://www.instagram.com/renaultmaroc"
          rel="noreferrer">
          <Instagram />
        </a>
        <a
          className="px-2 text-black md:text-white"
          target="_blank"
          href="https://www.youtube.com/user/RenaultMaroc"
          rel="noreferrer">
          <Youtube />
        </a>
      </div>
    </div>
  );
};

export default Footer;
