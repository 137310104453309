import { createContext, useContext } from "react";
import invariant from "invariant";
import { unreachableContextError } from "utils/runtime-errors";

export const DynamicMenuContext = createContext(null);

export const useDynamicMenuContext = () => {
  const context = useContext(DynamicMenuContext);

  invariant(context, unreachableContextError("DynamicMenuContext"));

  return { type: context.type, screen: context.screen };
};
