import { createSelector } from "reselect";

const baseSelector = (state) => state.global;

export const selectMobileMenuVisible = createSelector(
  baseSelector,
  (state) => state.mobileMenuVisible
);

export const selectLanguageSwitchVisible = createSelector(
  baseSelector,
  (state) => state.languageSwitchVisible
);

export const selectManufacturerInfoLoading = createSelector(
  baseSelector,
  (state) => state.manufacturerInfoStatus
);

export const selectManufacturerInfo = createSelector(
  baseSelector,
  (state) => state.manufacturerInfo
);

export const selectSearchVisible = createSelector(
  baseSelector,
  (state) => state.searchVisible
);
