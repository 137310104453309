import { selectMediaInfo } from "containers/MediaEntry/store/selectors";
import { useSelector } from "react-redux";

const MediaBanner = ({ className, style, children }) => {
  const mediaInfo = useSelector(selectMediaInfo);

  return (
    <div
      style={{
        backgroundColor: "#F2F2F2",
        backgroundImage: `url(${mediaInfo?.image})`,
        ...style,
      }}
      className={className}>
      {children}
    </div>
  );
};

export default MediaBanner;
