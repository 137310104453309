import Slider from "react-slick";

const EGSlider = ({ children, settings: extendedSettings = {} }) => {
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    touchMove: true,
    adaptiveHeight: true,
    ...extendedSettings,
  };

  return <Slider {...settings}>{children}</Slider>;
};

export default EGSlider;
