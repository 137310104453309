import Icon from "components/Icon";

const PhoneIcon = ({ ...props }) => {
  return (
    <svg width="49" height="48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 12.49C0 6.369 3.618 2.44 9.593 0l11.803 11.869-6.384 6.122 14.93 14.93 6.188-6.45 11.95 11.95c-2.439 6.042-6.842 9.66-12.572 9.578-6.548 0-13.359-3.93-22.477-13.097S0 19.022 0 12.491zm35.753 32.3c3.536 0 6.549-2.194 8.497-5.812l-8.022-8.021-6.368 6.45L10.526 17.99l6.45-6.122-8.021-8.022C5.353 5.73 3.143 8.644 3.143 12.18c0 5.664 3.7 11.95 12.18 20.43s14.864 12.18 20.43 12.18z"
        fill-rule="nonzero"
      />
    </svg>
  );
};

const Phone = ({ ...props }) => {
  return (
    <Icon {...props}>
      <PhoneIcon />
    </Icon>
  );
};

export default Phone;
