import invariant from "invariant";
import React, { useCallback, useMemo } from "react";
import { useRouteMatch } from "react-router";
import * as path from "path";
import { unreachableContextError } from "utils/runtime-errors";
import { switchRealm } from "./realmApiSelector";

const RealmAdapterContext = React.createContext(null);

export const useRealmAdapter = () => {
  const context = React.useContext(RealmAdapterContext);

  invariant(context, unreachableContextError("RealmAdapter"));

  return {
    makePath: context.makePath,
    makeURL: context.makeURL,
    realm: context.realm,
  };
};

export const useSwitchRealm = () => {
  const { realm } = useRealmAdapter();

  const func = useCallback(
    (switcherObject) => switchRealm(realm, switcherObject),
    [realm]
  );

  return func;
};

export const RealmAdapterProvider = ({ children, realm }) => {
  const { path: relativePath, url: relativeURL } = useRouteMatch();

  const makePath = useCallback(
    (suffix) => {
      return path.join(relativePath, suffix);
    },
    [relativePath]
  );

  const makeURL = useCallback(
    (suffix) => {
      return path.join(relativeURL, suffix);
    },
    [relativeURL]
  );

  const memoizedContextValue = useMemo(
    () => ({ realm, makePath, makeURL }),
    [realm, makePath, makeURL]
  );

  return (
    <RealmAdapterContext.Provider value={memoizedContextValue}>
      {children}
    </RealmAdapterContext.Provider>
  );
};

export const withAdapter =
  (Component, config = { realm: null }) =>
  (props) =>
    (
      <RealmAdapterProvider {...config}>
        <Component {...props} />
      </RealmAdapterProvider>
    );
