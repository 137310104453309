// import technologyIcon from "images/technology.svg";
// import confortIcon from "images/confort.svg";
// import maintenanceIcon from "images/maintenance.svg";
// import handoverIcon from "images/handover.svg";

import Handover from "icons/Handover";
import Technology from "icons/Technology";
import Confort from "icons/Confort";
import Maintenance from "icons/Maintenance";

export const SECTIONS_KEYS = {
  HAND: "HAND",
  TECH: "TECH",
  CONF: "CONF",
  MAIN: "MAIN",
};

export const SECTIONS_DATA = {
  [SECTIONS_KEYS.HAND]: {
    name: "prise en main",
    icon: <Handover />,
  },
  [SECTIONS_KEYS.TECH]: {
    name: "technologie",
    icon: <Technology />,
  },
  [SECTIONS_KEYS.CONF]: {
    name: "confort",
    icon: <Confort />,
  },
  [SECTIONS_KEYS.MAIN]: {
    name: "maintenance",
    icon: <Maintenance />,
  },
};
