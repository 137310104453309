import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentArticleHtmlContent,
  selectCurrentArticleInfo,
} from "containers/Article/store/selectors";
import { fetchCurrentArticleHtmlContent } from "containers/Article/store/thunks";
import { useParams } from "react-router-dom";
import { useRealmAdapter } from "containers/RealmAdapter";
import HTMLInjector from "components/HTMLInjector";

const ArticleBody = () => {
  const dispatch = useDispatch();
  const { code: modelCode, sectionCode, mediaCode } = useParams();
  const currentArticle = useSelector(selectCurrentArticleInfo);
  const { realm } = useRealmAdapter();

  useEffect(() => {
    if (currentArticle) {
      dispatch(
        fetchCurrentArticleHtmlContent({
          url: currentArticle.url,
        })
      );
    }
  }, [modelCode, sectionCode, currentArticle, dispatch]);

  return (
    <ArticleHTMLContainer
      realm={realm}
      modelCode={modelCode}
      mediaCode={mediaCode}
    />
  );
};

const ArticleHTMLContainer = ({ realm, mediaCode, modelCode }) => {
  const htmlContent = useSelector(selectCurrentArticleHtmlContent);

  return (
    <div className="overflow-x-auto">
      <HTMLInjector html={htmlContent} containerID="article-html-body" />
    </div>
  );
};

export default ArticleBody;
