import {
  selectArticleBookmarked,
  selectCurrentArticleInfo,
} from "containers/Article/store/selectors";
import { toggleBookmarkArticle } from "containers/Article/store/thunks";
import { useRealmAdapter } from "containers/RealmAdapter";
import { getRealmSubRoutes } from "containers/RealmAdapter/utils";
import StarFilled from "icons/StarFilled";
import StarOutlined from "icons/StarOutlined";
import Notification from "rc-notification";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Bookmark = ({ className, ...props }) => {
  const dispatch = useDispatch();
  const bookmarked = useSelector(selectArticleBookmarked);
  const currentArticle = useSelector(selectCurrentArticleInfo);
  const { makeURL, realm } = useRealmAdapter();
  const { code: modelCode, sectionCode, mediaCode } = useParams();

  const toggleBookmark = () => {
    const data = {
      ...currentArticle,
      inAppPath: makeURL(
        getRealmSubRoutes(realm).Article.make({
          code: modelCode,
          articleCode: currentArticle.code,
          sectionCode,
          mediaCode,
        })
      ),
    };

    dispatch(
      toggleBookmarkArticle({
        articleId: currentArticle.articleId,
        bookmarked: !bookmarked,
        data,
      })
    );

    Notification.newInstance(
      {
        getContainer: () => document.getElementById("root"),
        style: { right: 20, bottom: 20 },
      },
      (instance) => {
        instance.notice({
          content: (
            <NotifContent
              text={bookmarked ? "Article défavorisé" : "Article favorisé"}
            />
          ),
        });
      }
    );
  };

  return (
    <button onClick={toggleBookmark} className={className} {...props}>
      {bookmarked ? <StarFilled /> : <StarOutlined />}
    </button>
  );
};

const NotifContent = ({ text }) => {
  return (
    <div className="flex min-w-40 items-center py-1">
      <div className="mr-3">
        <StarFilled className="text-secondary w-5" width="100%" height="100%" />
      </div>

      <div className="text-sm text-grayscale-300">{text}</div>
    </div>
  );
};

export default Bookmark;
