import Icon from "components/Icon";

const FacebookIcon = ({ ...props }) => {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        filerule="evenodd">
        <g transform="translate(-78.000000, -1495.000000)" filerule="nonzero">
          <g id="facebook" transform="translate(78.000000, 1495.000000)">
            <path
              d="M28.25,0 L3.75,0 C1.68225,0 0,1.68225 0,3.75 L0,28.25 C0,30.31775 1.68225,32 3.75,32 L28.25,32 C30.31775,32 32,30.31775 32,28.25 L32,3.75 C32,1.68225 30.31775,0 28.25,0 Z M29.5,28.25 C29.5,28.93925 28.93925,29.5 28.25,29.5 L21.125,29.5 L21.125,19.3125 L24.986875,19.3125 L25.625,15.4375 L21.125,15.4375 L21.125,12.75 C21.125,11.6890625 21.9390625,10.875 23,10.875 L25.5625,10.875 L25.5625,7 L23,7 C19.8173125,7 17.251375,9.578125 17.251375,12.760875 L17.251375,15.4375 L13.5,15.4375 L13.5,19.3125 L17.251375,19.3125 L17.251375,29.5 L3.75,29.5 C3.06075,29.5 2.5,28.93925 2.5,28.25 L2.5,3.75 C2.5,3.06075 3.06075,2.5 3.75,2.5 L28.25,2.5 C28.93925,2.5 29.5,3.06075 29.5,3.75 L29.5,28.25 Z"
              id="Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const Facebook = ({ ...props }) => {
  return (
    <Icon {...props}>
      <FacebookIcon />
    </Icon>
  );
};

export default Facebook;
