/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";

import appReducer from "containers/App/store/reducer";
import homePageReducer from "containers/HomePage/store/reducer";
import languageProviderReducer from "containers/LanguageProvider/store/reducer";
import carModelReducer from "containers/CarModel/store/reducer";
import articlesReducer from "containers/Articles/store/reducer";
import articleReducer from "containers/Article/store/reducer";
import sectionReducer from "containers/Section/store/reducer";
import videoGalleryReducer from "containers/VideoGallery/store/reducer";
import mediasReducer from "containers/Medias/store/reducer";
import mediaEntryReducer from "containers/MediaEntry/store/reducer";
import lightsReducer from "containers/Lights/store/reducer";
import bookmarksReducer from "containers/Bookmarks/store/reducer";
import indexReducer from "containers/Index/store/reducer";
import dashboardReducer from "containers/CMS/Dashboard/store/reducer";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: appReducer,
    homePage: homePageReducer,
    language: languageProviderReducer,
    carModel: carModelReducer,
    section: sectionReducer,
    articles: articlesReducer,
    article: articleReducer,
    videoGallery: videoGalleryReducer,
    medias: mediasReducer,
    mediaEntry: mediaEntryReducer,
    lights: lightsReducer,
    bookmarks: bookmarksReducer,
    index: indexReducer,

    /* CMS */
    dashboard: dashboardReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
