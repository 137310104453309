import React from "react";

const YoutubePlayer = ({ videoID, title, ...props }) => {
  return (
    <iframe
      title={title}
      width="100%"
      height="100%"
      src={`https://www.youtube.com/embed/${videoID}?autoplay=1&rel=0`}
      {...props}
    />
  );
};

export default YoutubePlayer;
