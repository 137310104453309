import classNames from "classnames";
import React, { useMemo } from "react";

const Heritage = ({ children, className, style, ...props }) => {
  const child = React.Children.only(children);

  const clone = useMemo(
    () =>
      React.cloneElement(child, {
        ...props,
        style: { ...child?.props?.style, ...style },
        className: classNames(className, child?.props?.className),
      }),
    [child, className, style, props]
  );

  console.log(clone.props);

  return clone;
};

export default Heritage;
