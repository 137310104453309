import React, { useEffect, useLayoutEffect, useCallback } from "react";
import { selectMobileMenuVisible } from "containers/App/store/selectors";
import { useIsMobile } from "hooks/proxyBreakpoint";
import Times from "icons/Times";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actionCreators";
import DynamicMenu from "../DynamicMenu";

const MobileNavigation = () => {
  const dispatch = useDispatch();
  const mobileNavigationVisible = useSelector(selectMobileMenuVisible);
  const isMobile = useIsMobile();

  const toggleMobileNavigationMenu = useCallback(
    () => dispatch(actions.toggleMobileNavigationMenu()),
    [dispatch]
  );

  /* Hack */
  useEffect(() => {
    if (mobileNavigationVisible) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [mobileNavigationVisible]);

  useLayoutEffect(() => {
    if (!isMobile && mobileNavigationVisible) {
      toggleMobileNavigationMenu();
    }
  }, [isMobile, mobileNavigationVisible, toggleMobileNavigationMenu]);

  return (
    <>
      {mobileNavigationVisible && (
        <div className="fixed h-screen w-screen z-50 top-0 left-0 flex">
          <div style={{ width: "56px" }} className="bg-darkgray">
            <button
              className="flex justify-center w-full pt-5"
              onClick={toggleMobileNavigationMenu}>
              <Times className="text-white" />
            </button>
          </div>
          <div
            style={{ width: "calc(100% - 56px)" }}
            className="bg-primary text-white">
            <div className="py-5 px-5 border-b border-lightgray">E-Guide</div>

            <div>
              <DynamicMenu type={DynamicMenu.TYPES.MOBILE} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const MobileMenuProvider = ({ children }) => (
  <>
    {children}
    <MobileNavigation />
  </>
);

export default MobileNavigation;
