import FETCH_STATUS from "shared/fetchStatus";
import {
  SET_CURRENT_SECTION_DATA,
  SET_CURRENT_SECTION_STATUS,
} from "./actions";

const initialState = {
  currentSection: null,
  status: FETCH_STATUS.idle,
};

export default function sectionReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_CURRENT_SECTION_DATA: {
      return { ...state, currentSection: payload };
    }

    case SET_CURRENT_SECTION_STATUS: {
      return { ...state, status: payload };
    }
    default:
      return state;
  }
}
