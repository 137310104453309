import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import ArrowLeft from "icons/ArrowLeft";

const ReturnButton = ({ className, to, children }) => {
  return (
    <div className="inline-block">
      <Link
        className={classNames(
          "flex flex-1 w-auto px-4 items-center py-4 flex-shrink",
          className
        )}
        to={to}>
        <ArrowLeft className="text-primary mr-4" />
        <span className="font-bold text-sm md:text-xl">{children}</span>
      </Link>
    </div>
  );
};

export default ReturnButton;
