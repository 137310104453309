// import React from "react";

import {
  selectCurrentArticleInfo,
  selectUserHaveVoted,
} from "containers/Article/store/selectors";
import { postArticleVote } from "containers/Article/store/thunks";
import { useDispatch, useSelector } from "react-redux";

const Feedback = () => {
  const currentArticle = useSelector(selectCurrentArticleInfo);
  const userHaveVoted = useSelector(selectUserHaveVoted);

  const dispatch = useDispatch();

  const handleVote = (helpful) => {
    dispatch(postArticleVote({ articleId: currentArticle.articleId, helpful }));
  };

  return (
    <div>
      {!userHaveVoted && (
        <>
          <h3 className="text-sm font-bold">Cet. article vous a aidé ?</h3>

          <div className="flex mt-4">
            <button
              onClick={() => handleVote(true)}
              className="inline-block bg-secondary font-bold px-4 py-2
         text-primary transition-all mr-4">
              Oui
            </button>

            <button
              onClick={() => handleVote(false)}
              className="inline-block bg-primary text-white font-bold px-4 py-2 transition-all">
              Non
            </button>
          </div>
        </>
      )}

      {/* TODO: change */}
      {userHaveVoted && <h3 className="text-sm font-bold">Merci :)</h3>}
    </div>
  );
};

export default Feedback;
