import { getMediasByCode } from "api";
import { setMedias } from "./actionCreators";

export const fetchMedia = (modelCode) => {
  return (dispatch) => {
    getMediasByCode(modelCode)
      .then((res) => {
        dispatch(setMedias(res.data));
      })
      .catch(() => {
        // TODO: DO ME
      });
  };
};
