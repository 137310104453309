import React from "react";
import { PublicAppRoutes } from "config/AppRoutes";
import { SECTION_TYPES_KEYS } from "containers/Section/constants";
import Apps from "icons/Apps";
import Multimedia from "icons/Multimedia";
import Navigation from "icons/Navigation";
import Phone from "icons/Phone";
import System from "icons/System";
import Vehicle from "icons/Vehicle";

export const SECTIONS_KEYS = {
  MDIA: "MDIA",
  PHON: "PHON",
  APPS: "APPS",
  VHCL: "VHCL",
  SYST: "SYST",
  NAVI: "NAVI",
};

export const MEDIA_SECTIONS_DATA = {
  [SECTIONS_KEYS.NAVI]: {
    name: "navigation",
    icon: <Navigation className="mx-auto" />,
  },
  [SECTIONS_KEYS.MDIA]: {
    name: "multimédia",
    icon: <Multimedia className="mx-auto" />,
  },
  [SECTIONS_KEYS.PHON]: {
    name: "téléphone",
    icon: <Phone className="mx-auto" />,
  },
  [SECTIONS_KEYS.APPS]: {
    name: "apps",
    icon: <Apps className="mx-auto" />,
  },
  [SECTIONS_KEYS.VHCL]: {
    name: "véhicule",
    icon: <Vehicle className="mx-auto" />,
  },
  [SECTIONS_KEYS.SYST]: {
    name: "système",
    icon: <System className="mx-auto" />,
  },
};

export const MEDIA_SECTION_TYPES_CONFIG = {
  [SECTION_TYPES_KEYS.STANDARD]: {
    redirectURL: ({ modelCode, sectionCode, mediaCode }) =>
      PublicAppRoutes.MediaEntry.SubRoutes.Articles.make({
        code: modelCode,
        sectionCode,
        mediaCode,
      }),
  },
  [SECTION_TYPES_KEYS.LIST]: {
    redirectURL: ({ modelCode, sectionCode, mediaCode }) =>
      PublicAppRoutes.MediaEntry.SubRoutes.Articles.make({
        code: modelCode,
        sectionCode,
        mediaCode,
      }),
  },
  [SECTION_TYPES_KEYS.CARD]: {
    redirectURL: ({ modelCode, sectionCode, mediaCode }) =>
      PublicAppRoutes.MediaEntry.SubRoutes.Section.make({
        code: modelCode,
        sectionCode,
        mediaCode,
      }),
  },
};
