import React, { useEffect } from "react";
import { useRealmAdapter, useSwitchRealm } from "containers/RealmAdapter";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentArticle } from "../store/thunks";
import { selectCurrentArticleInfo } from "../store/selectors";
import { Redirect, useParams } from "react-router-dom";
import { REALMS } from "containers/RealmAdapter/constants";
import { PublicAppRoutes } from "config/AppRoutes";
import { useState } from "react";
import FETCH_STATUS, { isError } from "shared/fetchStatus";

const ArticleRedirect = () => {
  const dispatch = useDispatch();
  const { code: modelCode, articleCode, mediaCode } = useParams();
  const [status, setStatus] = useState(FETCH_STATUS.idle);
  const currentArticle = useSelector(selectCurrentArticleInfo);
  const { realm, makeURL } = useRealmAdapter();
  const switchRealm = useSwitchRealm();

  useEffect(() => {
    const params = { modelCode, mediaCode, articleCode };

    dispatch(
      fetchCurrentArticle({
        realm,
        params,
        onFail: () => setStatus(FETCH_STATUS.error),
      })
    );
  }, [modelCode, mediaCode, articleCode, realm, dispatch]);

  if (currentArticle) {
    const ArticleRoute = switchRealm({
      [REALMS.model]: PublicAppRoutes.CarModel.SubRoutes.Article,
      [REALMS.model]: PublicAppRoutes.MediaEntry.SubRoutes.Article,
    });

    const redirectPath = makeURL(
      ArticleRoute.make({
        articleCode,
        sectionCode: currentArticle.sectionCode,
      })
    );

    return <Redirect to={redirectPath}></Redirect>;
  }

  if (isError(status))
    return "Une erreur s'est produite lors de la récupération de l'article";

  return `Chargement...`;
};

export default ArticleRedirect;
