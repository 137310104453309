import React from "react";
import { useDispatch } from "react-redux";
import { NavigationLink } from "components/NavigationLink";
import * as actions from "../../store/actionCreators";
import classNames from "classnames";
import { useDynamicMenuContext } from "./context";
import DYNAMIC_MENU_TYPES from "./types";
import { useParams } from "react-router-dom";

/**
 * @param baseClassName is overridable by setting it to null
 */

const MenuLink = ({
  component: Component = NavigationLink,
  baseClassName = "mb-5 md:mb-0",
  className,
  render,
  label,
  to,
  makePath,
  ...props
}) => {
  const { code: modelCode } = useParams();
  const { type } = useDynamicMenuContext();
  const dispatch = useDispatch();

  const toggleMenu = (e) => {
    if (type === DYNAMIC_MENU_TYPES.MOBILE) {
      dispatch(actions.toggleMobileNavigationMenu());
      // onClick(e);
    }
  };

  const genrateDynamicPath = () => {
    if (typeof makePath === "function")
      return makePath({ modelCode /*TODO: add more */ });

    return to;
  };

  if (typeof render === "function") return render({ type });

  return (
    <Component
      className={classNames(baseClassName, className)}
      to={genrateDynamicPath()}
      onClick={toggleMenu}
      {...props}>
      {label}
    </Component>
  );
};

export default MenuLink;
