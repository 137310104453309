import Icon from "components/Icon";

const NavigationIcon = ({ ...props }) => {
  return (
    <svg width="42" height="47" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M39.418 46.982a143.825 143.825 0 00-18.094-11.637A146.53 146.53 0 003.316 46.982L.931 44.756C7.084 29.047 11.97 17.12 20.204.204h2.312C30.75 17.178 35.607 29.047 41.79 44.756l-2.37 2.226zm-1.687-4.8C32.42 28.829 27.636 17.876 21.396 4.364 15.04 17.92 10.298 28.873 4.99 42.182A150.473 150.473 0 0121.324 32.13c5.676 3 11.156 6.356 16.407 10.05z"
        fill-rule="nonzero"
      />
    </svg>
  );
};

const Navigation = ({ ...props }) => {
  return (
    <Icon {...props}>
      <NavigationIcon />
    </Icon>
  );
};

export default Navigation;
