import { SET_ARTICLES_LIST } from "./actions";

const initialState = {
  articles: {
    status: "idle",
    data: null,
  },
};

export default function articlesReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_ARTICLES_LIST: {
      const draft = { ...state };
      draft.articles.data = payload;

      return { ...draft };
    }
    default:
      return state;
  }
}
