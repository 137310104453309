import { NavigationLink } from "components/NavigationLink";
import { PublicAppRoutes } from "config/AppRoutes";
import { SECTION_CODES } from "containers/Section/constants";

// will be changed once i18n logic is implemented
export const DEFAULT_LOCALE = "fr";

export const LANGUAGES = [
  { code: "fr", label: "Français" },
  { code: "en", label: "English" },
];

const MockExternalLink = ({ link, label }) => (
  <NavigationLink
    className="mb-5 md:mb-0"
    as="a"
    href={link}
    target="_blank"
    rel="noopener">
    {label}
  </NavigationLink>
);

/**
  @param component: string | React Component
  @param render: func(): React Element
  @param label: string | React Element
  @param collapsible: Boolean (is the element going to collapse under a dropdown)
  @param screens: Array (on what screens the element is visible)
*/

export const DEFAULT_NAVIGATION_ITEMS = [
  {
    label: "Accueil",
    to: "/",
  },
  {
    render: () => (
      <MockExternalLink label="My Renault" link="https://myr.renault.ma/" />
    ),
  },
  {
    render: () => (
      <MockExternalLink
        label="Contact"
        link="https://www.renault.ma/contactez-nous"
      />
    ),
  },
];

export const MODEL_NAVIGATION_ITEMS = [
  {
    label: "Accueil",
    to: "/",
  },
  {
    label: "Technologie",
    makePath: ({ modelCode }) =>
      PublicAppRoutes.Section.make({
        code: modelCode,
        sectionCode: SECTION_CODES.TECH,
      }),
    // collapsible: true,
    // screens: ["sm", "lg", "xl", "2xl"],
  },
  {
    label: "Confort",
    collapsible: true,
    screens: ["sm", "lg", "xl", "2xl"],
    makePath: ({ modelCode }) =>
      PublicAppRoutes.Section.make({
        code: modelCode,
        sectionCode: SECTION_CODES.CONF,
      }),
  },
  {
    label: "Maintenance",
    collapsible: true,
    screens: ["sm", "xl", "2xl"],
    makePath: ({ modelCode }) =>
      PublicAppRoutes.Section.make({
        code: modelCode,
        sectionCode: SECTION_CODES.MAIN,
      }),
  },
  {
    label: "Vidéos",
    makePath: ({ modelCode }) =>
      PublicAppRoutes.ModelVideoGallery.make({ code: modelCode }),
    collapsible: true,
    screens: ["sm", "xl", "2xl"],
  },
  {
    label: "Équipements Multimédia",
    collapsible: true,
    screens: ["sm", "2xl"],
    makePath: ({ modelCode }) =>
      PublicAppRoutes.Medias.make({ code: modelCode }),
  },
  {
    label: "Voyants lumineux",
    collapsible: true,
    screens: ["sm", "2xl"],
    makePath: ({ modelCode }) =>
      PublicAppRoutes.Lights.make({ code: modelCode }),
  },
  {
    label: "Index Alphabétique",
    collapsible: true,
    screens: ["sm"],
    makePath: ({ modelCode }) =>
      PublicAppRoutes.Index.make({ code: modelCode }),
  },
  {
    label: "Favoris",
    collapsible: true,
    screens: ["sm"],
    makePath: ({ modelCode }) =>
      PublicAppRoutes.Bookmarks.make({ code: modelCode }),
  },
];
