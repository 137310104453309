import { getArticlesList } from "api";
import { SECTION_TYPES_KEYS } from "containers/Section/constants";
import { setArticlesList } from "./actionCreators";

/* for now only */
const SECTION_TYPE_MOCK = "LIST";

export const fetchArticlesList = ({ realm, params }) => {
  return (dispatch) => {
    getArticlesList({ realm, params })
      .then((res) => {
        dispatch(setArticlesList(res.data));
        // later: (res.data.sectionType === <L>)
        if (SECTION_TYPE_MOCK === SECTION_TYPES_KEYS.LIST) {
        }
      })
      .catch(() => {
        // TODO: DO ME
      });
  };
};
