import Icon from "components/Icon";

const UtilityIcon = ({ ...props }) => {
  return (
    <svg
      width="43px"
      height="19px"
      viewBox="0 0 43 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        filerule="evenodd">
        <g
          transform="translate(-50.000000, -1571.000000)"
          fill="#FFFFFF"
          filerule="nonzero">
          <g id="Footer" transform="translate(0.000000, 1543.000000)">
            <g id="Group-2" transform="translate(42.500000, 28.000000)">
              <g
                id="G_ICONS_24px_CAR_MPV_v4"
                transform="translate(7.500000, 0.000000)">
                <path
                  d="M3.89,13.63 C4.10624036,11.4363221 6.00342901,9.79677459 8.20528606,9.90073637 C10.4071431,10.0046981 12.141412,11.8157067 12.15,14.02 L29.86,14.02 C29.8599999,12.5444931 30.6471735,11.1810684 31.9249999,10.4433149 C33.2028264,9.70556146 34.7771736,9.70556146 36.0550001,10.4433149 C37.3328265,11.1810684 38.1200001,12.5444931 38.12,14.02 L40.04,14.02 L40.04,13.3 C40.0119868,12.1739053 39.7675426,11.0637212 39.32,10.03 C38.94,9.26 38.7,9.03 37.26,8.45 L33.7,7.01 C32.26,6.43 29.96,4.7 26.89,3.5 C23.3770198,2.34389351 19.6855104,1.82572751 15.99,1.97 C13.25,1.97 9.08,2.21 5.99,2.5 L3.68,1.15 L3.68,0.67 C7.33,0.34 11.6,0 16.02,0 C22.93,0 25.24,0.48 28.64,1.92 C31.72,3.22 32.64,4.37 34.5,5.14 L38.1,6.62 C40.1,7.44 40.6,7.92 41.17,9.22 C41.7327895,10.6088276 42.0279717,12.0915244 42.04,13.59 C42.04,15.41 41.22,16.03 39.73,16.03 L37.73,16.03 C37.0205123,17.4175772 35.5934426,18.2906946 34.035,18.2906946 C32.4765574,18.2906946 31.0494877,17.4175772 30.34,16.03 L11.72,16.03 C10.997516,17.4077398 9.57562234,18.2762478 8.02,18.29 C6.31923669,18.2823091 4.7962075,17.2352266 4.18,15.65 L3.56,15.65 C1.3,15.46 0,14.65 0,12.19 L0,6 C0.805022438,4.71163961 1.79187287,3.5462804 2.93,2.54 L4.76,3.54 C3.72637594,4.4863981 2.80681403,5.55027083 2.02,6.71 L2.02,11.94 C2.02,13.24 2.31,13.48 3.51,13.58 L3.89,13.63 Z M10.18,14.06 C10.18,12.8670649 9.21293506,11.9 8.02,11.9 C6.82706494,11.9 5.86,12.8670649 5.86,14.06 C5.86,14.8316937 6.27169365,15.5447681 6.93999998,15.9306149 C7.60830632,16.3164618 8.43169368,16.3164618 9.10000002,15.9306149 C9.76830635,15.5447681 10.18,14.8316937 10.18,14.06 Z M36.18,14.06 C36.18,12.8670649 35.2129351,11.9 34.02,11.9 C32.8270649,11.9 31.86,12.8670649 31.86,14.06 C31.86,15.2529351 32.8270649,16.22 34.02,16.22 C35.2129351,16.22 36.18,15.2529351 36.18,14.06 Z"
                  id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const Utility = ({ ...props }) => {
  return (
    <Icon {...props}>
      <UtilityIcon />
    </Icon>
  );
};

export default Utility;
