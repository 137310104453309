import Icon from "components/Icon";

const DashboardIcon = ({ ...props }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 19 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M9.918 8.16H4.736c-1.12 0-1.57.99-2.14 3.041h11.722c-.12-.4-.213-.758-.332-1.07h2.928v.833l-1.264.55a3.072 3.072 0 011.214 2.397v5.306a.773.773 0 01-.763.783h-2.378a.812.812 0 01-.807-.783v-1.164H3.998v1.164A.812.812 0 013.19 20H.812a.773.773 0 01-.762-.783v-5.306a3.057 3.057 0 011.213-2.397L0 10.964v-.833h1.883c.663-1.783 1.283-2.923 2.71-2.923h5.325v.952zm4.9 3.963H2.096a2.67 2.67 0 00-1.07 1.783v5.143h1.981v-1.947h10.885v1.947h1.981V13.91a2.67 2.67 0 00-1.055-1.783v-.005zM1.977 14.105a1.01 1.01 0 112.021 0 1.01 1.01 0 01-2.021 0zm16.482-8.006c-.787.118-.99.356-.99.782.01.32.073.634.188.932l.143.356c-.654.407-1.58.902-2.318 1.249l-.164-.283a1.14 1.14 0 00-.95-.668 1.095 1.095 0 00-.952.668l-.169.283a19.207 19.207 0 01-2.283-1.234l.118-.356c.12-.297.185-.612.194-.932 0-.426-.213-.664-.991-.783l-.357-.05V3.33l.357-.044c.763-.12.99-.337.99-.763a2.581 2.581 0 00-.193-.927l-.118-.381A22.6 22.6 0 0113.247 0l.169.287c.18.372.54.624.951.664.408-.05.764-.298.951-.664L15.482 0c.738.332 1.664.857 2.308 1.214l-.143.381c-.117.296-.18.61-.188.927 0 .426.213.644.99.763l.332.044v2.735l-.322.035zm-6.351 1.61c.287.188.57.351.926.545a1.684 1.684 0 011.358-.714c.532.01 1.03.264 1.352.689.337-.188.62-.332.907-.496a4.122 4.122 0 01-.168-.852c-.011-.81.58-1.503 1.382-1.62V4.117a1.58 1.58 0 01-1.382-1.57c.025-.291.081-.579.168-.858-.287-.168-.57-.307-.907-.495-.327.417-.822.67-1.352.689a1.69 1.69 0 01-1.382-.739 7.57 7.57 0 00-.902.57c.07.273.118.552.144.832a1.526 1.526 0 01-1.358 1.57v1.14a1.575 1.575 0 011.358 1.62 3.76 3.76 0 01-.144.833zm.689-2.998a1.585 1.585 0 111.595 1.57 1.595 1.595 0 01-1.62-1.57h.025zm.143 9.413a.99.99 0 11.991.991.99.99 0 01-1.015-1.01l.024.02zm2.14-9.413a.689.689 0 00-.688-.688.659.659 0 00-.689.688.708.708 0 00.689.714.743.743 0 00.689-.738v.024z" />
    </svg>
  );
};

const Dashboard = ({ ...props }) => {
  return (
    <Icon {...props}>
      <DashboardIcon />
    </Icon>
  );
};

export default Dashboard;
