import Icon from "components/Icon";

const HandoverIcon = ({ ...props }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 70 63"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M6.552 59.695l-4.01 2.987-2.405-2.987 4.01-2.986a20.146 20.146 0 0113.09-4.811c6.835 0 13.19 3.187 23.192 3.367l23.874-16.357c.962-.661 1.343-1.243 1.343-2.004a2.165 2.165 0 00-2.205-2.205 3.107 3.107 0 00-1.844.661l-10.624 7.217a6.014 6.014 0 01-5.773 3.748H31.168v-3.849H45.2c1.643 0 2.225-.681 2.225-2.124 0-1.444-.582-2.105-2.225-2.105H19.46c-6.555 0-9.24.862-13.771 4.41l-3.167 2.505-2.305-3.067 3.167-2.505c5.292-4.15 9.14-5.192 16.176-5.192H45.14a6.134 6.134 0 015.693 3.548l9.141-6.014a5.212 5.212 0 013.268-1.062 6.014 6.014 0 016.254 6.254c0 2.005-1.063 3.568-3.849 5.493L41.591 59.254c-10.403 0-17.038-3.468-24.054-3.468a15.736 15.736 0 00-10.985 3.91zm4.01-37.244a6.294 6.294 0 0112.026 0h20.046a6.294 6.294 0 0112.027 0h1.544c2.104 0 2.585-.381 2.585-2.005a12.609 12.609 0 00-1.443-4.91 4.35 4.35 0 00-2.987-2.005l-6.735-1.744c-2.506-.662-5.773-3.749-9.902-5.393-3.188-1.242-6.014-1.723-12.609-1.723-3.829.058-7.651.346-11.446.862L10 3.328v-.963A117.887 117.887 0 0125.776.722a35.821 35.821 0 0113.65 2.004c4.53 1.724 7.217 4.71 9.141 5.292l6.415 2.005a9.281 9.281 0 015.673 3.568 18.722 18.722 0 012.004 7.597c0 3.468-2.987 5.292-6.935 5.292H54.58a6.214 6.214 0 01-12.027 0H22.508a6.014 6.014 0 01-6.013 4.63 6.475 6.475 0 01-6.014-4.73h-.18a7.176 7.176 0 01-7.698-7.217v-4.81c0-2.005.581-3.85 4.51-7.798l3.368 1.844c-3.448 3.749-4.13 4.51-4.13 6.435v4.009c0 2.405 1.163 3.748 4.01 3.748l.2-.14zm8.86 2.004a2.846 2.846 0 00-2.887-2.886 2.806 2.806 0 00-2.707 2.886 2.746 2.746 0 002.707 2.887 2.786 2.786 0 002.926-2.866l-.04-.02zm32.072 0a2.826 2.826 0 00-2.887-2.886 2.786 2.786 0 00-2.686 2.886 2.726 2.726 0 002.686 2.887 2.766 2.766 0 002.887-2.866v-.02z" />
    </svg>
  );
};

const Handover = ({ ...props }) => {
  return (
    <Icon {...props}>
      <HandoverIcon />
    </Icon>
  );
};

export default Handover;
