import Icon from "components/Icon";

const TechnologyIcon = ({ ...props }) => {
  return (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58 64"
      {...props}>
      <path d="M53.676 63.975a195.847 195.847 0 00-24.64-15.845 199.531 199.531 0 00-24.52 15.845l-3.248-3.03C9.646 39.554 16.3 23.312 27.51.277h3.15c11.21 23.115 17.825 39.277 26.243 60.668l-3.228 3.03zm-2.298-6.536c-7.23-18.182-13.746-33.097-22.243-51.497-8.655 18.46-15.112 33.374-22.341 51.497a204.899 204.899 0 0122.242-13.686 206.226 206.226 0 0122.342 13.686z" />
    </svg>
  );
};

const Technology = ({ ...props }) => {
  return (
    <Icon {...props}>
      <TechnologyIcon />
    </Icon>
  );
};

export default Technology;
