import { PrivateAppRoutes } from "config/AppRoutes";
import { selectManufacturerInfo } from "containers/App/store/selectors";
import Dashboard from "icons/Dashboard";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const CMSLayout = ({ children }) => {
  const manufacturerInfo = useSelector(selectManufacturerInfo);

  console.log(manufacturerInfo);

  return (
    <div className="bg-white">
      <div className="grid grid-cols-10 min-h-screen">
        <div className="col-span-2 px-8 h-full flex flex-col pt-8 pr-8">
          {manufacturerInfo && (
            <div className="flex mb-4">
              <img
                className="w-9 filter invert mr-4"
                src={manufacturerInfo?.icon}
                alt="logo"
              />
              <div>
                <h1 className="text-2xl font-bold">e-guide</h1>
                <h3 className="text-sm uppercase text-darkgray">maroc</h3>
              </div>
            </div>
          )}
          <MenuItems />
          {/* <UserInfo /> */}
        </div>

        <div className="col-span-8 pt-8 pr-8 h-screen overflow-auto">
          <div className="bg-gray-200 rounded-t-2xl p-8 ">{children}</div>
        </div>
      </div>
    </div>
  );
};

const MenuItems = () => {
  return (
    <div className="mt-20">
      <div className="flex flex-col">
        <NavLink
          className="flex items-center"
          activeClassName="font-bold"
          to={PrivateAppRoutes.Dashboard.path}>
          <Dashboard className="w-6 mr-3" />
          Gestion des modèles
        </NavLink>
      </div>
    </div>
  );
};

// const UserInfo = () => {
//   return <div className="mt-auto">user info</div>;
// };

export default CMSLayout;
