import { getMediaInfo, getMediaSections } from "api";
import { setMediaInfo, setMediaSections } from "./actionCreators";

export const fetchMediaInfo = (mediaCode) => {
  return (dispatch) => {
    getMediaInfo(mediaCode)
      .then((res) => {
        dispatch(setMediaInfo(res.data));
      })
      .catch(() => {
        // TODO: DO ME
      });
  };
};

export const fetchMediaSections = (mediaCode) => {
  return (dispatch) => {
    getMediaSections(mediaCode)
      .then((res) => {
        dispatch(setMediaSections(res.data));
      })
      .catch(() => {
        // TODO: DO ME
      });
  };
};
