import Icon from "components/Icon";

const StarFilledIcon = ({ ...props }) => {
  return (
    <svg
      width="30"
      height="28"
      viewBox="0 0 30 28"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <path
        d="M22.654 27.2c-3.095-1.342-5.742-2.684-8.254-4.295-2.512 1.61-5.159 2.953-8.254 4.295L4.8 26.216c.27-3.445.718-5.95 1.57-9.127C3.723 15.166 1.84 13.6 0 11.811L.359 10.2C2.916 9.126 5.697 8.321 9.15 7.605 10.677 4.653 11.708 2.863 13.637 0h1.526c1.929 2.863 2.96 4.653 4.486 7.605 3.454.716 6.235 1.521 8.792 2.595l.359 1.61c-1.84 1.79-3.723 3.356-6.37 5.28.852 3.176 1.3 5.681 1.57 9.126l-1.346.984z"
        id="a"
      />
    </svg>
  );
};

const StarFilled = ({ ...props }) => {
  return (
    <Icon {...props}>
      <StarFilledIcon />
    </Icon>
  );
};

export default StarFilled;
