import invariant from "invariant";
import { useContext } from "react";
import { createContext } from "react";
import { unreachableContextError } from "utils/runtime-errors";

export const VideoGalleryContext = createContext(null);

export const useVideoGalleryContext = () => {
  const context = useContext(VideoGalleryContext);

  invariant(context, unreachableContextError("VideoGalleryContext"));

  return {
    root: context.root,
    scrollToTop: context.scrollToTop,
  };
};
