import FETCH_STATUS from "shared/fetchStatus";
import { SET_CMS_MODELS } from "./actions";

export const initialState = {
  status: FETCH_STATUS.idle,
  models: null,
};

export default function dashboardReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_CMS_MODELS:
      return { ...state, models: payload };
    default:
      return state;
  }
}
