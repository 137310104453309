import { FILTER_OPTIONS_KEYS } from "../constants";
import {
  SET_MODEL_TYPES,
  SET_CAR_MODEL_FILTER,
  SET_CAR_MODEL_SEARCH_RESULT,
} from "./actions";

export const initialState = {
  carModels: {
    activeType: FILTER_OPTIONS_KEYS.utility,
    modelTypes: [],
  },
  search: {
    status: "idle",
    result: null,
  },
};

export default function appReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CAR_MODEL_FILTER: {
      const draft = { ...state };

      draft.carModels.activeType = payload;

      return draft;
    }

    case SET_MODEL_TYPES: {
      const draft = { ...state };

      draft.carModels.modelTypes = payload;

      return draft;
    }

    case SET_CAR_MODEL_SEARCH_RESULT: {
      const draft = { ...state };

      draft.search.result = payload;

      return draft;
    }

    default:
      return state;
  }
}
