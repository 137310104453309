import FETCH_STATUS from "shared/fetchStatus";
import {
  SET_ACTIVE_CATEGORY,
  SET_ACTIVE_VIDEO,
  SET_CATEGORIES,
  SET_VIDEOS_LIST,
} from "./actions";

const initialState = {
  videos: {
    status: FETCH_STATUS.idle,
    data: null,
  },
  categories: null,
  activeCategory: null,
  activeVideo: null,
  /*  activeVideo: { videoID, url, title } ?? */
};

export default function videoGalleryReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_VIDEOS_LIST: {
      const draft = { ...state };
      draft.videos.data = payload;

      return { ...draft };
    }

    case SET_CATEGORIES: {
      return { ...state, categories: payload };
    }

    case SET_ACTIVE_CATEGORY: {
      return { ...state, activeCategory: payload };
    }

    case SET_ACTIVE_VIDEO: {
      return { ...state, activeVideo: payload };
    }
    default:
      return state;
  }
}
