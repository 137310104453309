// TODO: transform to a utility function
const ModelReleaseYears = ({ start, latest }) => {
  const startYear = start ? new Date(start).getUTCFullYear() : "";
  const latestYear = latest ? new Date(latest).getUTCFullYear() : "";

  const output = [startYear, latestYear].filter((item) => !!item).join(" - ");

  return output;
};

export default ModelReleaseYears;
