import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Container from "components/Container";
import {
  selectActiveCategory,
  selectCategories,
  selectVideosList,
} from "containers/VideoGallery/store/selectors";
import {
  setActiveCategory,
  setActiveVideo,
} from "containers/VideoGallery/store/actionCreators";
import YoutubeVideoCard from "components/YoutubeVideoCard";
import { useVideoGalleryContext } from "containers/VideoGallery/context";
import useProxyBreakpoint, { BREAKPOINTS_KEYS } from "hooks/proxyBreakpoint";
import ReturnButton from "components/ReturnButton";

import Img from "images/unavailable.png";


const Videos = () => {
  return (
    <Container baseClassName="lg:container mx-auto">
      <div className="md:grid md:grid-cols-8">
        <div className="md:col-span-2 px-6">
          <Categories />
        </div>
        <div className="md:bg-lightgray col-span-6 min-h-screen">
          <VideosListBody />
        </div>
      </div>
    </Container>
  );
};

const Categories = () => {
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const activeCategory = useSelector(selectActiveCategory);
  const breakpoint = useProxyBreakpoint();
  const isMobile =
    breakpoint === undefined || breakpoint === BREAKPOINTS_KEYS.sm;

  const handleCategoryClick = (item) => {
    dispatch(setActiveCategory(item));
  };

  return (
    <div className="md:py-10">
      {!isMobile && (
        <>
          <h3 className="text-base uppercase font-bold">Catégories</h3>

          <div className="mt-4">
            {categories?.map((item) => (
              <button
                className={classNames(
                  "text-left block text-sm py-2 lowercase",
                  {
                    "font-bold": activeCategory?.order === item.order,
                  }
                )}
                onClick={() => handleCategoryClick(item)}>
                {item.title}
              </button>
            ))}
          </div>
        </>
      )}

      {isMobile && (
        <div className="scrollable-container overflow-x-scroll whitespace-nowrap h-12 px-6 py-2 border-b border-lightgray">
          {categories?.map((item) => (
            <button
              className={classNames(
                "text-left inline-block text-sm py-2 px-4",
                {
                  "border-b-2 border-secondary font-bold":
                    activeCategory?.order === item.order,
                }
              )}
              onClick={() => handleCategoryClick(item)}>
              {item.title}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const VideosListBody = () => {
  const dispatch = useDispatch();
  const videosList = useSelector(selectVideosList);
  const activeCategory = useSelector(selectActiveCategory);
  const { scrollToTop } = useVideoGalleryContext();
  const videosByCategory = videosList?.find(
    (item) => item.order === activeCategory?.order
  );
  const handleVideoClick = (video) => {
    scrollToTop();
    dispatch(setActiveVideo(video));
  };


  const isVideoEmpty = !videosByCategory

  return (
    <div className="pb-10 px-4 md:px-10">
      <ReturnButton className="pl-0" to="./">
        Retour
      </ReturnButton>
      {isVideoEmpty ?

        <div className="mt-4">
          <div class="text-2xl font-bold text-center max-w-md m-auto pb-4"> contenu en cours de préparation, réessayez plus tard </div>
          <img src={Img} alt="404" className="self-center max-w-xl m-auto" />
        </div>

        :

        <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {videosByCategory?.videos?.map((video) => (
            <YoutubeVideoCard
              className="h-40"
              onClick={handleVideoClick}
              key={video.code}
              title={video.title}
              url={video.url}
            />
          ))}
        </div>
      }

    </div>
  );
};

export default Videos;
