import { getModelLights } from "api";
import { setLightsData } from "./actionCreators";

export const fetchIndicatorLights = (modelCode) => {
  return (dispatch) => {
    getModelLights(modelCode)
      .then((res) => {
        dispatch(setLightsData(res.data));
      })
      .catch(() => {
        // TODO: DO ME
      });
  };
};
