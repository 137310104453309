import Container from "components/Container";
import { Title } from "components/Title";

const LegalNotice = () => {
  return (
    <Container className="px-4">
      <div className="mt-12 mb-16">
        <Title className="mb-8">Mentions légales</Title>

        <p className="mb-5">
          Les informations sur les modèles constituant cet e-Guide sont établies
          à partir des caractéristiques techniques connues à la date de la mise
          en ligne ou des mises à jour des différentes pages du site. L’e-Guide
          regroupe l'ensemble des équipements (de série ou optionnels) existant
          pour ces modèles ; leur présence dans le véhicule dépend de la
          version, des options choisies et du pays de commercialisation. De
          même, certains équipements devant apparaître en cours d'année peuvent
          être décrits sur ce site. Ainsi, les informations données sur ce site
          le sont à titre purement indicatif et ne sont pas exhaustives. Elles
          sont susceptibles d’être régulièrement mises à jour pour prendre en
          compte l'ensemble des évolutions des véhicules.
        </p>

        <p className="mb-5">
          RENAULT se réserve le droit de modifier, supprimer ou suspendre ces
          informations à tout moment sans préavis, sans que cela puisse engager
          la responsabilité de RENAULT, des membres de son réseau, ou de ses
          employés.
        </p>

        <p className="mb-5">
          La notice d’utilisation téléchargeable à partir du site peut être
          d’une version différente de celle qui vous a été remise avec votre
          véhicule.
        </p>

        <p className="mb-5">
          Il convient que vous vous référiez toujours au document d'entretien et
          de garantie qui vous a été remis avec votre véhicule. Le réseau
          RENAULT est à votre disposition pour tout renseignement
          complémentaire.
        </p>

        <p className="mb-5">
          L'utilisateur utilise le site à ses seuls risques. En aucun cas, ni
          RENAULT, ni ses filiales, ni les membres de son réseau ne pourront
          être tenus responsables des dommages directs ou indirects, et
          notamment préjudice matériel, perte de données ou de programme,
          préjudice financier, résultant de l'accès ou de l'utilisation de ce
          site ou de tous sites qui lui sont liés.
        </p>
      </div>
    </Container>
  );
};

export default LegalNotice;
