import Container from "components/Container";
import { useRealmAdapter } from "containers/RealmAdapter";
import { getRealmSubRoutes } from "containers/RealmAdapter/utils";
import invariant from "invariant";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { isError, isIdle, isLoading } from "shared/fetchStatus";
import { noop } from "statics";
import CardsDisplay from "./components/CardsDisplay";
import { SECTION_TYPES_KEYS } from "./constants";
import {
  selectCurrentSection,
  selectCurrentSectionStatus,
} from "./store/selectors";
import { fetchCurrentSection } from "./store/thunks";

const dataToCardsOptions = (data = [], { modelCode, realm, makeURL }) =>
  data.map(({ code, imageUrl, title }) => ({
    code,
    to: makeURL(
      getRealmSubRoutes(realm).Articles.make({
        code: modelCode,
        sectionCode: code,
      })
    ),
    image: imageUrl,
    label: title,
  }));

/* TODO: externalize */
const CONFIG_BY_SECTION_TYPE = {
  [SECTION_TYPES_KEYS.CARD]: {
    component: CardsDisplay,
    format: dataToCardsOptions,
  },

  [SECTION_TYPES_KEYS.LIST]: {
    format: noop,
    component: ({ modelCode, sectionCode, realm, makeURL }) => (
      <Redirect
        to={makeURL(
          getRealmSubRoutes(realm).Articles.make({
            code: modelCode,
            sectionCode,
          })
        )}
      />
    ),
  },

  [SECTION_TYPES_KEYS.STANDARD]: {
    format: noop,
    component: ({ modelCode, sectionCode, realm, makeURL }) => (
      <Redirect
        to={makeURL(
          getRealmSubRoutes(realm).Articles.make({
            code: modelCode,
            sectionCode,
          })
        )}
      />
    ),
  },
};

const Section = () => {
  const dispatch = useDispatch();
  const { code: modelCode, sectionCode, mediaCode } = useParams();
  const { realm, makeURL } = useRealmAdapter();

  const currentSection = useSelector(selectCurrentSection);
  const status = useSelector(selectCurrentSectionStatus);

  useEffect(() => {
    const params = { modelCode, sectionCode, mediaCode };

    dispatch(fetchCurrentSection({ realm, params }));
  }, [modelCode, sectionCode, mediaCode, realm, dispatch]);

  if (isLoading(status) || isIdle(status)) return "PLACEHOLDER";

  if (isError(status)) return "ERROR";

  /* the second argument is just a temporary hack (hopefully :) ) */
  if (!currentSection || currentSection.code !== sectionCode) return null;

  const activeSectionConfig =
    CONFIG_BY_SECTION_TYPE[currentSection.sectionType];

  invariant(
    activeSectionConfig,
    `Unsupported section type: ${currentSection.sectionType}`
  );

  const { component: Component, format } = activeSectionConfig;

  return (
    <Container>
      <Component
        makeURL={makeURL}
        realm={realm}
        modelCode={modelCode}
        sectionCode={sectionCode}
        options={format(currentSection.children, { modelCode, realm, makeURL })}
      />
    </Container>
  );
};

export default Section;
