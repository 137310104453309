import React, { useState } from "react";
import { useSelector } from "react-redux";
import ChevronLeft from "icons/ChevronLeft";
import ChevronRight from "icons/ChevronRight";
import { selectArticlesList } from "containers/Articles/store/selectors";
import { createStructuredSelector } from "reselect";
import {
  selectArticleBookmarked,
  selectCurrentArticleInfo,
} from "containers/Article/store/selectors";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useRef } from "react";
import useOnClickOutside from "hooks/clickOutside";
import { useIsMobile } from "hooks/proxyBreakpoint";
import _capitalize from "lodash.capitalize";
import { getRealmSubRoutes } from "containers/RealmAdapter/utils";
import { useRealmAdapter } from "containers/RealmAdapter";
import Bookmark from "../Bookmark";

const stateSelector = createStructuredSelector({
  articles: selectArticlesList,
  currentArticle: selectCurrentArticleInfo,
  bookmarked: selectArticleBookmarked,
});
const ArticlesNavigation = () => {
  const { code: modelCode, sectionCode, mediaCode } = useParams();
  const { articles, currentArticle } = useSelector(stateSelector);
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile && (
        <MobileNavigation
          articles={articles}
          modelCode={modelCode}
          sectionCode={sectionCode}
          mediaCode={mediaCode}
          currentArticle={currentArticle}
        />
      )}

      {!isMobile && (
        <WebNavigation
          articles={articles}
          modelCode={modelCode}
          sectionCode={sectionCode}
        />
      )}
    </>
  );
};

const WebNavigation = ({ articles, modelCode, sectionCode, mediaCode }) => {
  const { realm, makeURL } = useRealmAdapter();

  return (
    <div className="hidden md:block bg-primary p-8">
      <h3 className="text-white font-bold text-xl uppercase">
        Articles relatifs
      </h3>

      <div className="mt-6">
        {articles?.map((article) => (
          <NavLink
            key={article.code}
            activeClassName="text-secondary font-bold"
            to={makeURL(
              getRealmSubRoutes(realm).Article.make({
                code: modelCode,
                articleCode: article.code,
                sectionCode,
                mediaCode,
              })
            )}
            className="article-navigation-item text-white block py-3">
            {_capitalize(article.title)}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

const MobileNavigation = ({
  articles,
  modelCode,
  sectionCode,
  mediaCode,
  currentArticle,
}) => {
  const history = useHistory();
  const articlesListContainerRef = useRef(null);
  const { realm, makeURL } = useRealmAdapter();
  const [articlesListVisible, setArticlesListVisibility] = useState(false);

  const toggleArticlesVisibility = () => {
    setArticlesListVisibility((prev) => !prev);
  };

  const navigateToArticle = (articleCode) => {
    history.push(
      makeURL(
        getRealmSubRoutes(realm).Article.make({
          code: modelCode,
          articleCode,
          sectionCode,
          mediaCode,
        })
      )
    );
  };

  const findArticle = (index) =>
    articles.find((item) => item.order === index) || null;

  const next = () => {
    const nextArticle = findArticle(currentArticle.order + 1);

    if (!nextArticle) return;

    navigateToArticle(nextArticle.code);
  };

  const previous = () => {
    const prevArticle = findArticle(currentArticle.order - 1);

    if (!prevArticle) return;

    navigateToArticle(prevArticle.code);
  };

  useOnClickOutside(articlesListContainerRef, () =>
    setArticlesListVisibility(false)
  );

  return (
    <div className="md:hidden w-full fixed left-0 bottom-0 bg-grayscale-600">
      <div className="relative flex w-full">
        <div className="flex w-11/12 p-2 px-6 justify-between items-center">
          <button onClick={previous} className="text-secondary">
            <ChevronLeft />
          </button>

          <button
            onClick={toggleArticlesVisibility}
            className="text-white font-bold text-sm truncate px-2 w-full">
            {currentArticle?.title}
          </button>

          <button onClick={next} className="text-secondary">
            <ChevronRight />
          </button>
        </div>
        <div className="flex p-2 justify-center ml-auto bg-grayscale-700">
          <Bookmark className="text-secondary ml-auto" />
        </div>

        {articlesListVisible && (
          <div
            ref={articlesListContainerRef}
            className="absolute bg-grayscale-600 bottom-0 w-full max-h-96 overflow-y-scroll">
            {articles?.map((article) => (
              <NavLink
                key={article.code}
                activeClassName="font-bold"
                onClick={() => {
                  toggleArticlesVisibility();
                }}
                to={makeURL(
                  getRealmSubRoutes(realm).Article.make({
                    code: modelCode,
                    articleCode: article.code,
                    sectionCode,
                  })
                )}
                className="text-white block py-2 px-6 border-b border-lightgray last:border-none">
                {article.title}
              </NavLink>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticlesNavigation;
