import { selectModelInfo } from "containers/CarModel/store/selectors";
import { useSelector } from "react-redux";

const ModelBanner = ({ className, style, children }) => {
  const modelInfo = useSelector(selectModelInfo);

  return (
    <div
      style={{
        backgroundColor: "#F2F2F2",
        backgroundImage: `url(${modelInfo?.image})`,
        ...style,
      }}
      className={className}>
      {children}
    </div>
  );
};

export default ModelBanner;
