import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import AppProviders from "AppProviders";
import { translationMessages } from "./i18n";
import "rc-notification/assets/index.css";
import "./styles/index.scss";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

const instance = createInstance({
  urlBase: "https://matomo.dt.renault.com/",
  trackerUrl: "https://matomo.dt.renault.com/matomo.php",
  siteId: window._env_[`MATOMO_SITE_ID_${window._env_.environment}`],
  linkTracking: false,
});

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <AppProviders messages={translationMessages} />
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
