import Icon from "components/Icon";

const ThumbsDownIcon = ({ ...props }) => {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M.326.11h1.103v9.931H.326V.111zm13.693 8.828c.447 0 .712-.188.712-.585 0-.397-.265-.552-.712-.552h-2.626V6.698h2.599c.447 0 .739-.182.739-.552 0-.37-.292-.552-.74-.552h-2.598V4.491h1.484c.552 0 .773-.16.773-.552 0-.391-.243-.551-.773-.551h-1.484V2.284h.453c.452 0 .717-.187.717-.552 0-.364-.265-.551-.717-.551H6.709c-1.06 0-1.655 1.103-2.996 1.103H2.55V1.181h.794C4.535 1.18 5 .077 6.312.077h5.666a1.517 1.517 0 011.655 1.562c-.002.239-.077.472-.215.667.758.13 1.308.793 1.296 1.562 0 .236-.074.467-.21.662a1.5 1.5 0 011.325 1.533c.035.434-.101.864-.38 1.198.287.293.44.692.424 1.103a1.655 1.655 0 01-1.799 1.655h-4.027l.237 1.882c.237 1.958-.69 3.652-2.356 3.652H6.361V13.54a3.818 3.818 0 00-.37-1.953l-.766-1.54c-.453-.849-.811-1.114-1.915-1.114H2.55V7.83h.877c1.297 0 2.036.292 2.593 1.324l1.192 2.279c.202.477.293.994.265 1.511v1.534h.32c.927 0 1.561-1.191 1.324-2.648l-.452-2.913 5.351.022z"
        fill-rule="nonzero"
      />
    </svg>
  );
};

const ThumbsDown = ({ ...props }) => {
  return (
    <Icon {...props}>
      <ThumbsDownIcon />
    </Icon>
  );
};

export default ThumbsDown;
