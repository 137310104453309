export const PublicAppRoutes = {
  Root: {
    path: "/",
  },

  HomePage: {
    path: "/",
  },

  /* These are common between /model/:code/ and /model/:code/media */
  CarModel: {
    path: "/model/:code/",
    make: (id) => `/model/${id}`,

    SubRoutes: {
      Section: {
        path: "/sections/:sectionCode",
        make: ({ sectionCode }) => `/sections/${sectionCode}`,
      },
      SectionRedirect: {
        path: "/sections",
        make: () => `/sections`,
      },

      Articles: {
        path: "/sections/:sectionCode/articles",
        make: ({ sectionCode }) => `/sections/${sectionCode}/articles`,
      },

      Article: {
        path: "/sections/:sectionCode/articles/:articleCode",
        make: ({ sectionCode, articleCode }) =>
          `/sections/${sectionCode}/articles/${articleCode}`,
      },
      VideoGallery: {
        path: "/videos",
        make: () => `/videos`,
      },

      ArticleRedirect: {
        path: "/articles/:articleCode",
        make: ({ articleCode }) => `/articles/${articleCode}`,
      },
    },
  },

  Section: {
    path: "/model/:code/sections/:sectionCode",
    make: ({ code, sectionCode }) => `/model/${code}/sections/${sectionCode}`,
  },

  Articles: {
    path: "/model/:code/sections/:sectionCode/articles",
    make: ({ code, sectionCode }) =>
      `/model/${code}/sections/${sectionCode}/articles`,
  },

  Article: {
    path: "/model/:code/sections/:sectionCode/articles/:articleCode",
    make: ({ code, sectionCode, articleCode }) =>
      `/model/${code}/sections/${sectionCode}/articles/${articleCode}`,
  },

  Lights: {
    path: "/model/:code/lights",
    make: ({ code }) => `/model/${code}/lights`,
  },

  Bookmarks: {
    path: "/model/:code/bookmarks",
    make: ({ code }) => `/model/${code}/bookmarks`,
  },

  Index: {
    path: "/model/:code/index",
    make: ({ code }) => `/model/${code}/index`,
  },

  MediaEntry: {
    path: "/media/:mediaCode",
    make: ({ mediaCode }) => `/media/${mediaCode}`,

    SubRoutes: {
      Section: {
        path: "/sections/:sectionCode",
        make: ({ sectionCode }) => `/sections/${sectionCode}`,
      },

      SectionRedirect: {
        path: "/sections",
        make: () => `/sections`,
      },

      Articles: {
        path: "/sections/:sectionCode/articles",
        make: ({ sectionCode }) => `/sections/${sectionCode}/articles`,
      },

      Article: {
        path: "/sections/:sectionCode/articles/:articleCode",
        make: ({ sectionCode, articleCode }) =>
          `/sections/${sectionCode}/articles/${articleCode}`,
      },

      ArticleRedirect: {
        path: "/articles/:articleCode",
        make: ({ articleCode }) => `/articles/${articleCode}`,
      },

      videoGallery: {
        path: "/videos",
        make: () => `/videos`,
      },
    },
  },

  Medias: {
    path: "/model/:code/media",
    make: ({ code }) => `/model/${code}/media`,
  },

  RootVideoGallery: {
    path: "/videos",
  },

  ModelVideoGallery: {
    path: "/model/:code/videos",
    make: ({ code }) => `/model/${code}/videos`,
  },

  LegalNotice: {
    path: "/legal-notice",
  },
};

export const PrivateAppRoutes = {
  Root: {
    path: "/cms",
  },

  Dashboard: {
    path: "/cms",
  },
};
