import Icon from "components/Icon";

const ChevronRightIcon = ({ ...props }) => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M1.32569 -5.79411e-08L0.000151943 1.24431L7.19523 8L0.000152534 14.7557L1.32569 16L8.52077 9.24431L9.84631 8L8.52077 6.75569L1.32569 -5.79411e-08Z" />
    </svg>
  );
};

const ChevronRight = ({ ...props }) => {
  return (
    <Icon {...props}>
      <ChevronRightIcon />
    </Icon>
  );
};

export default ChevronRight;
