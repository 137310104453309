import React from "react";
import multimediaBanner from "images/multimedia.png";
import { Link } from "react-router-dom";
import SectionTitle from "components/Title/SectionTitle";

const MultimediaSection = ({ to }) => (
  <>
    <SectionTitle>Multi-média & technologie</SectionTitle>

    <div className="mt-16 mb-16 px-4 md:px-0">
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2">
          <img
            className="w-full"
            src={multimediaBanner}
            alt="Multi-média & technologie"
          />
        </div>

        <div className="w-full md:w-1/2 pl-0 md:pl-10">
          <h2 className="text-lg md:text-xl uppercase font-bold mt-4 md:mt-0 mb-4">
            DÉCOUVREZ NOS SYSTÈMES MULTIMÉDIAS
          </h2>

          <p className="leading-4 text-sm mb-10">
            Conçus pour vous accompagner dans vos trajets, découvrez et comparez
            nos systèmes multimédias Renault.
          </p>

          <Link
            to={to}
            className="inline-block bg-primary text-white font-bold px-4 py-3 hover:bg-secondary hover:text-primary transition-all">
            Accéder à la vidéothèque
          </Link>
        </div>
      </div>
    </div>
  </>
);

export default MultimediaSection;
