import React, { useMemo } from "react";
import Slider from "react-slick";
import CarModelsFilter from "../CarModelsFilter";
import { PublicAppRoutes } from "config/AppRoutes";
import { Link } from "react-router-dom";
import { selectCarModelsByFilter } from "containers/HomePage/store/selectors";
import { useSelector } from "react-redux";

const DELIMITER = 8;

const CarModels = () => {
  const models = useSelector(selectCarModelsByFilter);

  const blocks = useMemo(() => {
    if (!models.length) return [];

    let start = 0;

    const blockCount = Math.ceil(models.length / DELIMITER);
    const proxyBlocks = [];

    for (let index = 1; index <= blockCount; index++) {
      const block = models.slice(start, DELIMITER * index) || [];

      proxyBlocks.push(block);

      start += DELIMITER;
    }

    return proxyBlocks;
  }, [models]);

  return (
    <div className="mt-16">
      <CarModelsFilter />

      {!models.length && (
        <div className="mt-16 px-18 py-18 text-center bg-gray-100 mx-4 text-gray-400 font-bold">
          Pas de données
        </div>
      )}

      {!!models.length && (
        <CarModelSlider>
          {blocks.map((models) => (
            <div>
              <div className="flex flex-wrap mt-16 px-2 items-end">
                {models.map((model) => (
                  <CarModel
                    code={model.code}
                    image={model.image}
                    name={model.modelName}
                  />
                ))}
              </div>
            </div>
          ))}
        </CarModelSlider>
      )}
    </div>
  );
};

const CarModel = ({ code, image, name }) => (
  <div key={code} className="w-1/2 md:w-1/4 px-2 pb-4 group">
    <Link to={PublicAppRoutes.CarModel.make(code)}>
      <div className="relative overflow-hidden">
        <img
          className="w-full transition-transform group-hover:scale-110"
          src={image}
          alt={name}
        />
        <div className="py-2 px-4 car-model-wrapper bg-primary group-hover:bg-secondary transition-all duration-300">
          <h3 className="text-white group-hover:text-primary text-sm font-bold uppercase transition-all duration-300">
            {name}
          </h3>
        </div>
      </div>
    </Link>
  </div>
);

const CarModelSlider = ({ children }) => {
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    touchMove: true,
    adaptiveHeight: false,
  };

  return <Slider {...settings}>{children}</Slider>;
};

export default CarModels;
