import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentArticleInfo } from "containers/Article/store/selectors";
import YoutubeVideoCard from "components/YoutubeVideoCard";
import YoutubePlayer from "components/YoutubePlayer";

const ArticleVideos = () => {
  const currentArticle = useSelector(selectCurrentArticleInfo);
  const [currentPlayingVideo, setCurrentPlayingVideo] = useState(null);

  const handleVideoPlay = (videoObject) => {
    console.log();
    setCurrentPlayingVideo(videoObject);
  };

  if (!currentArticle?.videos?.length) return null;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-10">
      {currentArticle.videos?.map((video) => (
        <div className="h-64">
          {currentPlayingVideo && currentPlayingVideo?.code === video.code ? (
            <YoutubePlayer
              videoID={currentPlayingVideo?.videoID}
              title={currentPlayingVideo.title}
            />
          ) : (
            <YoutubeVideoCard
              onClick={({ videoID }) => handleVideoPlay({ ...video, videoID })}
              wrapperClassName="h-full w-full"
              className="h-full"
              title={video.title}
              url={video.url}
              key={video.code}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ArticleVideos;
