import {
  SET_VIDEOS_LIST,
  SET_ACTIVE_CATEGORY,
  SET_ACTIVE_VIDEO,
  SET_CATEGORIES,
} from "./actions";

export const setVideosList = (data) => ({
  type: SET_VIDEOS_LIST,
  payload: data,
});
export const setCategories = (data) => ({
  type: SET_CATEGORIES,
  payload: data,
});

export const setActiveCategory = (data) => ({
  type: SET_ACTIVE_CATEGORY,
  payload: data,
});

export const setActiveVideo = (data) => ({
  type: SET_ACTIVE_VIDEO,
  payload: data,
});
