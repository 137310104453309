import { useEffect } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useLocation } from "react-router-dom";

const MatomoPageTrack = () => {
  const location = useLocation();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    /* Track page view on page change */
    trackPageView();
  }, [trackPageView, location]);

  return null;
};

export default MatomoPageTrack;
