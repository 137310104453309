import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

export const NavigationLink = ({
  as: Component = NavLink,
  className,
  children,
  ...props
}) => (
  <Component
    activeClassName="text-gray-100"
    className={classNames(
      "text-white uppercase font-bold px-3 text-base md:text-sm hover:text-gray-100",
      className
    )}
    {...props}>
    {children}
  </Component>
);

export const NavigationIconLink = ({
  to,
  icon,
  children,
  className,
  ...props
}) => (
  <a
    className={classNames(
      "flex flex-col items-center justify-center text-center text-white px-6 text-sm",
      className
    )}
    href={to}
    {...props}>
    <span className="mb-2">{icon}</span>
    <span>{children}</span>
  </a>
);
