import { createSelector } from "reselect";

const baseSelector = (state) => state.index;

export const selectIndexData = createSelector(
  baseSelector,
  (state) => state.data
);

export const selectIndexAlphabets = createSelector(baseSelector, (state) => {
  if (!state.data) return null;

  return Object.keys(state.data);
});

export const selectActiveCharacter = createSelector(
  baseSelector,
  (state) => state.activeChar
);
