import invariant from "invariant";
import { useContext, createContext } from "react";
import { unreachableContextError } from "utils/runtime-errors";

export const DashboardContext = createContext(null);

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  invariant(context, unreachableContextError("DashboardContext"));

  return {
    refreshTable: context.refreshTable,
  };
};
