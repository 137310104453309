import {
  SET_MANUFACTURER_INFO,
  TOGGLE_MOBILE_LANGUAGE_SWITCH,
  TOGGLE_MOBILE_NAVIGATION_MENU,
  TOGGLE_SEARCH,
} from "./actions";

export const toggleMobileNavigationMenu = () => ({
  type: TOGGLE_MOBILE_NAVIGATION_MENU,
});

export const toggleLanguageSwitch = () => ({
  type: TOGGLE_MOBILE_LANGUAGE_SWITCH,
});

export const toggleSearch = (visible) => ({
  type: TOGGLE_SEARCH,
  payload: visible,
});

export const setManufacturerInfo = (data) => ({
  type: SET_MANUFACTURER_INFO,
  payload: data,
});
