import { getCarModels, searchCarModels } from "api";
import { setCarModelSearchResult, setModelTypes } from "./actionCreators";

export const searchCarModelsByKeyword = (keyword) => {
  return (dispatch) => {
    if (!keyword) {
      dispatch(setCarModelSearchResult(null));

      return false;
    }

    searchCarModels(keyword).then((res) => {
      dispatch(setCarModelSearchResult(res.data));
    });
  };
};

export const fetchCarModels = (keyword) => {
  return (dispatch) => {
    getCarModels().then((res) => {
      dispatch(setModelTypes(res.data));
    });
  };
};
