import Icon from "components/Icon";

const ChevronLeftIcon = ({ ...props }) => {
  return (
    <svg width="10" height="17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.598.923l1.325 1.244-7.195 6.756 7.195 6.756-1.325 1.244-7.196-6.756L.077 8.923 1.402 7.68z"
        fill-rule="nonzero"
      />
    </svg>
  );
};

const ChevronLeft = ({ ...props }) => {
  return (
    <Icon {...props}>
      <ChevronLeftIcon />
    </Icon>
  );
};

export default ChevronLeft;
