import React from "react";
import classNames from "classnames";

const BASE_CLASSNAME = "container mx-auto";

const Container = ({ baseClassName = BASE_CLASSNAME, className, children }) => {
  return <div className={classNames(baseClassName, className)}>{children}</div>;
};

export default Container;
