import classNames from "classnames";
import React from "react";
import { Title } from ".";

const SectionTitle = ({ className, children }) => {
  return (
    <Title
      // variant={{ default: "xl", md: "3xl" }}
      className={classNames(
        "md:text-3xl uppercase font-bold text-center max-w-2xl mx-auto px-4",
        className
      )}>
      {children}
    </Title>
  );
};

export default SectionTitle;
