import React from "react";
import useProxyBreakpoint, { BREAKPOINTS_KEYS } from "hooks/proxyBreakpoint";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setActiveVideo } from "containers/VideoGallery/store/actionCreators";
import YoutubePlayer from "components/YoutubePlayer";

const WEB_HEIGHT = "calc(100vh - 144px)";
const MOBILE_HEIGHT = "250px";

const VideoPlayer = ({ title, videoID }) => {
  const breakpoint = useProxyBreakpoint();
  const dispatch = useDispatch();

  const height =
    breakpoint === undefined || breakpoint === BREAKPOINTS_KEYS.sm
      ? MOBILE_HEIGHT
      : WEB_HEIGHT;

  useEffect(() => {
    return () => {
      /* DATA RESET */
      dispatch(setActiveVideo(null));
    };
  }, [dispatch]);

  return (
    <div style={{ height }} className="bg-primary">
      <YoutubePlayer videoID={videoID} title={title} />
    </div>
  );
};

export default VideoPlayer;
