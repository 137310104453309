import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { selectArticlesList } from "containers/Articles/store/selectors";
import { fetchCurrentArticle } from "./store/thunks";
import { fetchArticlesList } from "containers/Articles/store/thunks";
import { selectCurrentArticleInfo } from "./store/selectors";
import ArticlesNavigation from "./components/ArticlesNavigation";
import ArticleBody from "./components/ArticleBody";
import Feedback from "./components/Feedback";
import Container from "components/Container";
import { selectModelInfo } from "containers/CarModel/store/selectors";
import { fetchCarModelInfo } from "containers/CarModel/store/thunks";
import ReturnButton from "components/ReturnButton";
import "./index.scss";
import { useRealmAdapter, useSwitchRealm } from "containers/RealmAdapter";
import { REALMS } from "containers/RealmAdapter/constants";
import ModelBanner from "containers/CarModel/components/ModelBanner";
import MediaBanner from "containers/MediaEntry/components/MediaBanner";
import { fetchMediaInfo } from "containers/MediaEntry/store/thunks";
import Bookmark from "./components/Bookmark";
import { useIsMobile } from "hooks/proxyBreakpoint";
import ArticleVideos from "./components/ArticleVideos";
import FETCH_STATUS, { isError, isIdle, isLoading } from "shared/fetchStatus";

const stateSelector = createStructuredSelector({
  articles: selectArticlesList,
  currentArticle: selectCurrentArticleInfo,
});

const Article = () => {
  const dispatch = useDispatch();
  const { code: modelCode, sectionCode, articleCode, mediaCode } = useParams();
  const { articles, currentArticle } = useSelector(stateSelector);
  const { realm } = useRealmAdapter();
  const switchRealm = useSwitchRealm();
  const [status, setStatus] = useState(FETCH_STATUS.idle);

  useEffect(() => {
    // not sure about this condition
    if (!articles) {
      const params = { modelCode, sectionCode, mediaCode };
      dispatch(fetchArticlesList({ realm, params }));
    }
  }, [articles, modelCode, sectionCode, mediaCode, realm, dispatch]);

  useEffect(() => {
    const params = { articleCode, modelCode, mediaCode };

    setStatus(FETCH_STATUS.loading);

    dispatch(
      fetchCurrentArticle({
        realm,
        params,
        onSuccess: () => setStatus(FETCH_STATUS.sucess),
        onFail: () => setStatus(FETCH_STATUS.error),
      })
    );
  }, [articleCode, modelCode, mediaCode, realm, dispatch]);

  useEffect(() => {
    const exec = switchRealm({
      [REALMS.model]: () => dispatch(fetchCarModelInfo(modelCode)),
      [REALMS.media]: () => dispatch(fetchMediaInfo(mediaCode)),
    });

    exec();
  }, [modelCode, mediaCode, switchRealm, dispatch]);

  if (isError(status))
    return "Une erreur s'est produite lors de la récupération de l'article";

  if (isLoading(status)) return "Chargement...";

  if (isIdle(status)) return null;

  if (!articles && !currentArticle) return "PLACEHOLDER";

  return (
    <>
      <Banner />
      <Container className="container-max md:container">
        <Toolbar />
        <div className="mt-4 mb-10">
          <div className="md:grid md:grid-cols-8">
            <div className="md:col-span-2">
              <ArticlesNavigation />
            </div>

            <div className="md:col-span-6 md:px-8">
              <ArticleBody />
              <div className="mt-4">
                <ArticleVideos />
              </div>

              <div className="mt-4">
                <Feedback />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

const Toolbar = () => {
  const isMobile = useIsMobile();

  return (
    <div className="mt-4 flex">
      <ReturnButton to="./">Retour aux articles</ReturnButton>

      {!isMobile && <Bookmark className="text-primary ml-auto" />}

      {/* favorite heart icon will be added here */}
    </div>
  );
};

const Banner = () => {
  const modelInfo = useSelector(selectModelInfo);
  const currentArticle = useSelector(selectCurrentArticleInfo);
  const switchRealm = useSwitchRealm();

  if (!modelInfo && !currentArticle) return null;

  const BannerComponent = switchRealm({
    [REALMS.model]: ({ children, ...props }) => (
      <ModelBanner {...props}>{children}</ModelBanner>
    ),
    [REALMS.media]: ({ children, ...props }) => (
      <MediaBanner {...props}>{children}</MediaBanner>
    ),
  });

  return (
    <BannerComponent className="hidden md:flex h-56 bg-center bg-cover items-center ">
      <div
        style={{ background: "rgba(255, 255, 255, 0.8)" }}
        className="pl-16 px-8 py-8 inline-block text-left max-w-md">
        <h1 className="text-3xl font-bold uppercase">
          {currentArticle?.title}
        </h1>
      </div>
    </BannerComponent>
  );
};

export default Article;
