import {
  SET_CAR_MODEL_FILTER,
  SET_CAR_MODEL_SEARCH_RESULT,
  SET_MODEL_TYPES,
} from "./actions";

export const setCarModelFilter = (filter) => ({
  type: SET_CAR_MODEL_FILTER,
  payload: filter,
});

export const setModelTypes = (modelTypes) => ({
  type: SET_MODEL_TYPES,
  payload: modelTypes,
});

export const setCarModelSearchResult = (data) => ({
  type: SET_CAR_MODEL_SEARCH_RESULT,
  payload: data,
});
