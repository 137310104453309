import Icon from "components/Icon";

const ConfortIcon = ({ ...props }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M56.526 63.902h-42.09c-2.56 0-3.584-1.024-4.267-4.394L.142 5.833C-.583 1.843 1.572.009 4.132.009h4.565a9.28 9.28 0 019.024 5.824l9.728 19.2a20.63 20.63 0 01.81 19.435l-2.773 6.656h-4.394l3.477-8.534a16.81 16.81 0 00-.512-14.656L14.222 8.372c-1.94-3.478-3.264-4.267-6.4-4.267H4.09l10.453 55.701h39.424l5.42-10.965c.206-.333.316-.717.32-1.11 0-.725-.62-1.023-1.857-1.023H31.332l1.536-4.096h25.301c2.965 0 5.739 2.133 5.739 4.266a8.299 8.299 0 01-.512 3.179l-6.87 13.845z" />
    </svg>
  );
};

const Confort = ({ ...props }) => {
  return (
    <Icon {...props}>
      <ConfortIcon />
    </Icon>
  );
};

export default Confort;
