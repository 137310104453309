import {
  TOGGLE_MOBILE_LANGUAGE_SWITCH,
  TOGGLE_MOBILE_NAVIGATION_MENU,
  TOGGLE_SEARCH,
  SET_MANUFACTURER_INFO,
} from "./actions";

export const initialState = {
  manufacturerInfo: null,
  mobileMenuVisible: false,
  languageSwitchVisible: false,
  searchVisible: false,
};

export default function appReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_MANUFACTURER_INFO: {
      return { ...state, manufacturerInfo: payload };
    }
    case TOGGLE_MOBILE_NAVIGATION_MENU:
      return { ...state, mobileMenuVisible: !state.mobileMenuVisible };

    case TOGGLE_MOBILE_LANGUAGE_SWITCH:
      return { ...state, languageSwitchVisible: !state.languageSwitchVisible };

    case TOGGLE_SEARCH:
      return { ...state, searchVisible: payload };

    default:
      return state;
  }
}
