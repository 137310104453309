import {
  SET_MODEL_INDICATOR_LIGHTS,
  SET_MODEL_INFO,
  SET_MODEL_SECTIONS,
} from "./actions";

export const setModelInfo = (data) => ({
  type: SET_MODEL_INFO,
  payload: data,
});

export const setModelSections = (data) => ({
  type: SET_MODEL_SECTIONS,
  payload: data,
});

export const setModelIndicatorLights = (data) => ({
  type: SET_MODEL_INDICATOR_LIGHTS,
  payload: data,
});
