import React from "react";
import { Link } from "react-router-dom";
import { noop } from "statics";
import _capitalize from "lodash.capitalize";
import ThumbsUp from "icons/ThumbsUp";
import ThumbsDown from "icons/ThumbsDown";

const ArticleBlock = ({
  id,
  title,
  feedback = { positive: null, negative: null },
  to,
  onClick = noop,
  extraContent = null,
}) => {
  return (
    <Link
      to={to}
      onClick={(e) => onClick(id, e)}
      className="flex flex-wrap bg-white px-4 py-4 filter shadow-custom-lg">
      <div className="w-8/12 sm:w-9/12 md:w-full text-left  mb-0 md:mb-14">
        <h1 className="text-sm md:text-lg lg:text-2xl font-bold truncate md:whitespace-normal md:overflow-visible">
          {_capitalize(title)}
        </h1>
      </div>
      {feedback && (
        <div className="grid grid-cols-2 gap-4 ml-auto md:ml-0">
          <Feedback icon={<ThumbsUp />} label={feedback.positive} />
          <Feedback icon={<ThumbsDown />} label={feedback.negative} />
        </div>
      )}

      {extraContent}
    </Link>
  );
};

const Feedback = ({ icon, label }) => (
  <div className="flex items-center">
    <div className="text-grayscale-300 mr-2">{icon}</div>
    <div className="text-grayscale-300">{label}</div>
  </div>
);

export default ArticleBlock;
