import { createSelector } from "reselect";

const baseSelector = (state) => state.videoGallery;

export const selectVideosList = createSelector(
  baseSelector,
  (state) => state.videos.data
);

export const selectVideosStatus = createSelector(
  baseSelector,
  (state) => state.videos.status
);

export const selectCategories = createSelector(
  baseSelector,
  (state) => state.categories
);

export const selectActiveCategory = createSelector(
  baseSelector,
  (state) => state.activeCategory
);

export const selectActiveVideo = createSelector(
  baseSelector,
  (state) => state.activeVideo
);
