import React, { useState } from "react";
import Globe from "icons/Globe";
import Search from "icons/Search";
import Bars from "icons/Bars";
import ChevronDown from "icons/ChevronDown";
import { NavigationIconLink } from "components/NavigationLink";
import LanguageSwitch from "../LanguageSwitch";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actionCreators";
import {
  selectLanguageSwitchVisible,
  selectManufacturerInfo,
  selectSearchVisible,
} from "containers/App/store/selectors";
import DynamicMenu from "../DynamicMenu";
import CarModelSearchBar from "containers/HomePage/components/CarModelSearchBar";
import Times from "icons/Times";
import { selectLocale } from "containers/LanguageProvider/store/selectors";
import { useRef } from "react";
import useOnClickOutside from "hooks/clickOutside";

/*  TODO: Implementing css based responsive display. Will be removed in favor of complete dom removal [TBD] */
const Header = () => {
  const manufacturer = useSelector(selectManufacturerInfo);

  return (
    <div className="h-16 md:h-36">
      <div className="bg-primary h-16 md:h-36 fixed md:static z-10 w-full top-0 0 left-0">
        <div className="relative flex items-center py-4 md:py-8 px-4 md:px-7 h-full">
          <div className="h-full">
            {manufacturer?.icon && (
              <img className="h-full" src={manufacturer?.icon} alt="logo" />
            )}
          </div>

          {/* web navigation */}
          <div className="hidden md:flex flex-row items-center w-full ml-2">
            <div className="flex">
              <DynamicMenu type={DynamicMenu.TYPES.WEB} />
            </div>

            <div className="flex ml-auto">
              <WebLanguageSwitcher />
              <SearchButton />
            </div>
          </div>

          <MobileLanguageSwitcher />
          <BurgerMenuButton />
        </div>
      </div>
    </div>
  );
};

const BurgerMenuButton = () => {
  const dispatch = useDispatch();

  const toggleMobileNavigationMenu = () =>
    dispatch(actions.toggleMobileNavigationMenu());

  return (
    <>
      <div className="flex md:hidden flex-row">
        <button type="button" onClick={toggleMobileNavigationMenu}>
          <Bars className="text-white" />
        </button>
      </div>
    </>
  );
};

const MobileLanguageSwitcher = () => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);
  const languageSwitchVisible = useSelector(selectLanguageSwitchVisible);

  const toggleLanguageSwitch = () => dispatch(actions.toggleLanguageSwitch());

  return (
    <>
      <div className="flex md:hidden text-white mx-auto">
        <button
          type="button"
          onClick={toggleLanguageSwitch}
          className="flex items-center">
          <span className="uppercase text-sm font-bold">
            {/* replace with: {language} */}
            E-Guide <span className="uppercase">({locale})</span>
          </span>

          <ChevronDown className="text-secondary ml-2" />
        </button>
      </div>

      {languageSwitchVisible && (
        <div className="bg-primary absolute top-16 left-0 w-full h-16 border-t border-grayscale-600  flex md:hidden items-center">
          <LanguageSwitch
            className="w-full justify-around"
            onSelect={toggleLanguageSwitch}
          />
        </div>
      )}
    </>
  );
};

const WebLanguageSwitcher = () => {
  const containerRef = useRef(null);
  const locale = useSelector(selectLocale);
  const [visible, setVisible] = useState(false);

  const handleLanguageSwitchClick = () => {
    setVisible(!visible);
  };

  useOnClickOutside(containerRef, () => {
    if (visible) handleLanguageSwitchClick();
  });

  return (
    <div ref={containerRef} className="group relative">
      <NavigationIconLink
        onClick={handleLanguageSwitchClick}
        href="#"
        icon={<Globe />}>
        langue <span className="uppercase">({locale})</span>
      </NavigationIconLink>

      {visible && (
        <div className="absolute bg-white top-14 transform left-1/2 -translate-x-1/2 flex items-center z-10 px-4 py-2 rounded shadow">
          <LanguageSwitch
            itemClassName="text-sm flex items-center border-b border-lightgray last:border-none"
            onSelect={handleLanguageSwitchClick}
            getActiveClassName={(isActive) =>
              isActive ? "text-primary font-bold" : "text-darkgray"
            }
            className="w-full justify-around flex-col border-none"
          />
        </div>
      )}
    </div>
  );
};

const SearchButton = () => {
  const dispatch = useDispatch();
  const visible = useSelector(selectSearchVisible);

  const toggle = () => dispatch(actions.toggleSearch(!visible));

  return (
    <>
      <NavigationIconLink onClick={toggle} href="#" icon={<Search />}>
        recherche
      </NavigationIconLink>

      {visible && (
        <div
          style={{ backgroundColor: "rgba(242, 242, 242, 0.9)" }}
          className="fixed w-screen h-screen left-0 top-0 z-50 transition-colors">
          <div className="w-7/12 mx-auto mt-36">
            <button
              onClick={toggle}
              className="absolute right-0 top-0 p-6 outline-none">
              <Times />
            </button>
            <CarModelSearchBar />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
