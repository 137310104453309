import { SET_MEDIA_INFO, SET_MEDIA_SECTIONS } from "./actions";

export const setMediaSections = (data) => ({
  type: SET_MEDIA_SECTIONS,
  payload: data,
});

export const setMediaInfo = (data) => ({
  type: SET_MEDIA_INFO,
  payload: data,
});
