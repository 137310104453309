import React from "react";
import classNames from "classnames";
import Globe from "icons/Globe";
import { noop } from "statics";
import { LANGUAGES } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { changeLocale } from "containers/LanguageProvider/store/actionCreators";
import { selectLocale } from "containers/LanguageProvider/store/selectors";

const defaultGetActiveClassName = (active) =>
  active ? "text-white font-bold" : "text-darkgray";

const defaultItemClassName = "flex items-center hover:text-white";

const LanguageSwitch = ({
  getActiveClassName = defaultGetActiveClassName,
  itemClassName = defaultItemClassName,
  className,
  onSelect = noop,
}) => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);

  const handleSelectLanguage = (lang) => {
    dispatch(changeLocale(lang));
    onSelect(lang);
  };

  return (
    <div className={classNames("flex", className)}>
      {LANGUAGES.map((lang) => (
        <button
          key={lang.code}
          onClick={() => handleSelectLanguage(lang.code)}
          type="button"
          className={classNames(
            itemClassName,
            getActiveClassName(locale === lang.code)
          )}>
          <Globe className="h-10 mr-2" />

          <span>{lang.label}</span>
        </button>
      ))}
    </div>
  );
};

export default LanguageSwitch;
