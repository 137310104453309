import Icon from "components/Icon";

const TwitterIcon = ({ ...props }) => {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <g stroke="none" stroke-width="1" fill="none" filerule="evenodd">
        <g transform="translate(-150.000000, -1495.000000)" filerule="nonzero">
          <g id="Group-9" transform="translate(78.000000, 1495.000000)">
            <g id="twitter" transform="translate(72.000000, 0.000000)">
              <path
                d="M21.217375,21.217375 L14.9565,21.217375 C14.381125,21.217375 13.913,20.74925 13.913,20.173875 L13.913,17.0434375 L21.217375,17.0434375 C22.9435,17.0434375 24.3478125,15.639125 24.3478125,13.913 C24.3478125,12.186875 22.9435,10.7825625 21.217375,10.7825625 L13.913,10.7825625 L13.913,7.652125 C13.913,5.926 12.5086875,4.5216875 10.7825625,4.5216875 C9.0564375,4.5216875 7.652125,5.926 7.652125,7.652125 L7.652125,20.173875 C7.652125,24.2015 10.928875,27.47825 14.9565,27.47825 L21.217375,27.47825 C22.9435,27.47825 24.3478125,26.0739375 24.3478125,24.3478125 C24.3478125,22.6216875 22.9435,21.217375 21.217375,21.217375 Z M21.217375,25.3913125 L14.9565,25.3913125 C12.079625,25.3913125 9.739125,23.0508125 9.739125,20.1739375 L9.739125,7.6521875 C9.739125,7.0768125 10.20725,6.6086875 10.782625,6.6086875 C11.358,6.6086875 11.826125,7.0768125 11.826125,7.6521875 L11.826125,11.826125 C11.826125,12.402375 12.2933125,12.869625 12.869625,12.869625 L21.2174375,12.869625 C21.7928125,12.869625 22.2609375,13.33775 22.2609375,13.913125 C22.2609375,14.4885 21.7928125,14.956625 21.2174375,14.956625 L12.8695625,14.956625 C12.2933125,14.956625 11.8260625,15.4238125 11.8260625,16.000125 L11.8260625,20.1740625 C11.8260625,21.9001875 13.230375,23.3045 14.9565,23.3045 L21.217375,23.3045 C21.79275,23.3045 22.2609375,23.772625 22.2609375,24.348 C22.2609375,24.9231875 21.79275,25.3913125 21.217375,25.3913125 Z"
                id="Shape"></path>
              <path
                d="M26.4348125,0 L5.56525,0 C2.4965625,0 0,2.4965625 0,5.56525 L0,26.4348125 C0,29.5034375 2.4965625,32 5.56525,32 L26.4348125,32 C29.5034375,32 32,29.5034375 32,26.4348125 L32,5.56525 C32,2.4965625 29.5034375,0 26.4348125,0 Z M29.9130625,26.4348125 C29.9130625,28.35275 28.3526875,29.9130625 26.4348125,29.9130625 L5.56525,29.9130625 C3.6473125,29.9130625 2.087,28.3526875 2.087,26.4348125 L2.087,5.56525 C2.087,3.6473125 3.647375,2.087 5.56525,2.087 L26.4348125,2.087 C28.35275,2.087 29.9130625,3.647375 29.9130625,5.56525 L29.9130625,26.4348125 Z"
                id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const Twitter = ({ ...props }) => {
  return (
    <Icon {...props}>
      <TwitterIcon />
    </Icon>
  );
};

export default Twitter;
