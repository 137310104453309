import { createSelector } from "reselect";

const baseSelector = (state) => state.medias;

export const selectMedias = createSelector(baseSelector, (state) => state.data);

export const selectMediasStatus = createSelector(
  baseSelector,
  (state) => state.status
);
