import { patchModelStatus, getCMSModelsList } from "api";
import { noop } from "statics";
import { setCMSModels } from "./actionCreators";

export const fetchCMSModels = ({ criteria } = {}) => {
  return (dispatch) => {
    getCMSModelsList({ criteria })
      .then((res) => {
        dispatch(setCMSModels(res.data));
      })
      .catch(() => {
        /* TODO: handle errors */
      });
  };
};

export const updateModelStatus = ({ id, onSuccess = noop, onFail = noop }) => {
  return () => {
    patchModelStatus(id).then(onSuccess).catch(onFail);
  };
};
