import { REALMS } from "containers/RealmAdapter/constants";
import { switchRealm } from "containers/RealmAdapter/realmApiSelector";
import * as path from "path";
import { Api } from "./instance";

const MOCK_MANUFACTURER = "RNT"; // renault by default [RNT, DCA]

const makePublicBase = (...args) => path.join("/public", ...args);

// eslint-disable-next-line no-unused-vars
const makePrivateBase = (...args) => path.join("/cms", ...args);

export const getManufacturerInfo = () =>
  Api.get(makePublicBase(MOCK_MANUFACTURER, "/manufacturer"));

export const getCarModels = () =>
  Api.get(makePublicBase(MOCK_MANUFACTURER, "/types/model"));

export const searchCarModels = (keyword) =>
  Api.get(makePublicBase(MOCK_MANUFACTURER, "/models"), {
    params: { modelName: keyword },
  });

export const getCarModelByCode = (modelCode) =>
  Api.get(makePublicBase(MOCK_MANUFACTURER, `/models/${modelCode}`));

export const getSectionsByCode = (modelCode) =>
  Api.get(makePublicBase(`/model/${modelCode}/sections`));

export const getCarModelIndicatorLights = (modelCode) =>
  Api.get(makePublicBase(`/model/${modelCode}/lights`));

export const getCurrentSection = ({ realm, params }) =>
  switchRealm(realm, {
    [REALMS.model]: () =>
      Api.get(
        makePublicBase(
          `/model/${params.modelCode}/sections/${params.sectionCode}`
        )
      ),
    [REALMS.media]: () =>
      Api.get(
        makePublicBase(
          `/media/${params.mediaCode}/sections/${params.sectionCode}`
        )
      ),
  }).call();

/* root: model / media */
export const getVideosList = ({ realm, params }) =>
  switchRealm(realm, {
    [REALMS.model]: () =>
      Api.get(makePublicBase(`/model/${params.modelCode}/topics`)),
    [REALMS.media]: () =>
      Api.get(makePublicBase(`/media/${params.mediaCode}/topics`)),
    default: () => Api.get(Api.get(makePublicBase(`/global/topics`))),
  }).call();

export const getVideosListByModel = ({ realm, params }) =>
  switchRealm(realm, {
    [REALMS.model]: () =>
      Api.get(makePublicBase(`/model/${params.modelCode}/topics`)),
    [REALMS.media]: () =>
      Api.get(makePublicBase(`/media/${params.modelCode}/topics`)),
  }).call();

export const getArticlesList = ({ realm, params }) =>
  switchRealm(realm, {
    [REALMS.model]: () =>
      Api.get(
        makePublicBase(
          `/sections/${params.sectionCode}/models/${params.modelCode}/articles`
        )
      ),
    [REALMS.media]: () =>
      Api.get(
        makePublicBase(
          `/sections/${params.sectionCode}/media/${params.mediaCode}/articles`
        )
      ),
  }).call();

export const getArticleByCode = ({ realm, params }) =>
  switchRealm(realm, {
    [REALMS.model]: () =>
      Api.get(
        makePublicBase(
          `/articles/${params.articleCode}/models/${params.modelCode}`
        )
      ),
    [REALMS.media]: () =>
      Api.get(
        makePublicBase(
          `/articles/${params.articleCode}/medias/${params.mediaCode}`
        )
      ),
  }).call();

export const voteOnArticle = ({ articleId, helpful }) =>
  Api.put(makePublicBase(`/articles/${articleId}/vote`), { helpful });

export const getMediasByCode = (modelCode) =>
  Api.get(makePublicBase(`/model/${modelCode}/medias`));

export const getModelLights = (mediaCode) =>
  Api.get(makePublicBase(`/model/${mediaCode}/lights`));

export const getMediaSections = (mediaCode) =>
  Api.get(makePublicBase(`/media/${mediaCode}/sections`));

export const getMediaInfo = (mediaCode) =>
  Api.get(makePublicBase(MOCK_MANUFACTURER, `/medias/${mediaCode}`));

export const getAlphabeticalIndexes = (modelCode) =>
  Api.get(makePublicBase(`/articles/${modelCode}/indexes`));

export const getCMSModelsList = ({ criteria }) =>
  Api.get(makePrivateBase(MOCK_MANUFACTURER, "/models"), { params: criteria });

export const patchModelStatus = (id) =>
  Api.patch(makePrivateBase(`/models/${id}/status`));
