import classNames from "classnames";
import React from "react";

const BASE_CLASSNAME = "bg-primary text-white text-center px-4 py-5 font-bold";

const Stripe = ({ baseClassName = BASE_CLASSNAME, className, children }) => {
  return <div className={classNames(baseClassName, className)}>{children}</div>;
};

export default Stripe;
