import { getManufacturerInfo } from "api";
import { setManufacturerInfo } from "./actionCreators";

export const fetchManufacturerInfo = () => {
  return (dispatch) => {
    /* TODO: handle errors */
    getManufacturerInfo().then((res) => {
      dispatch(setManufacturerInfo(res.data));
    });
  };
};
