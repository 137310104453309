import {
  getCarModelByCode,
  getCarModelIndicatorLights,
  getSectionsByCode,
} from "api";
import {
  setModelIndicatorLights,
  setModelInfo,
  setModelSections,
} from "./actionCreators";

export const fetchCarModelInfo = (modelCode) => {
  return (dispatch) => {
    getCarModelByCode(modelCode)
      .then((res) => {
        dispatch(setModelInfo(res.data));
      })
      .catch(() => {
        // TODO: DO ME
      });
  };
};

export const fetchCarModelSections = (modelCode) => {
  return (dispatch) => {
    getSectionsByCode(modelCode)
      .then((res) => {
        dispatch(setModelSections(res.data));
      })
      .catch(() => {
        // TODO: DO ME
      });
  };
};
export const fetchCarModelIndicatorLights = (modelCode) => {
  return (dispatch) => {
    getCarModelIndicatorLights(modelCode)
      .then((res) => {
        dispatch(setModelIndicatorLights(res.data));
      })
      .catch(() => {
        // TODO: DO ME
      });
  };
};
