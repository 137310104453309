import { getArticleByCode, voteOnArticle } from "api";
import { Api, instanceCDN } from "api/instance";
import * as path from "path";
import { noop } from "statics";
import {
  getBookmarkById,
  removeFromBookmarks,
  setBookmark,
} from "utils/bookmark";
import {
  setBookmarkArticle,
  setCurrentArticle,
  setCurrentArticleHtmlContent,
  setUserVoted,
} from "./actionCreators";

export const fetchCurrentArticle = ({
  realm,
  params,
  onFail = noop,
  onSuccess = noop,
}) => {
  return (dispatch) => {
    getArticleByCode({ realm, params })
      .then((res) => {
        const userHaveVoted = localStorage.getItem(res.data.articleId);
        dispatch(setCurrentArticle(res.data));
        dispatch(setUserVoted(!!userHaveVoted));
        dispatch(setBookmarkArticle(!!getBookmarkById(res.data.articleId)));

        return res;
      })
      .then(onSuccess)
      .catch(onFail);
  };
};

export const fetchCurrentArticleHtmlContent = ({ url }) => {
  return (dispatch) => {
    /* TODO: Will use 'url' only later */
    // const DEMO_ONLY_URL = path.join("public/static", url);

    instanceCDN.get(url)
      .then((res) => {
        dispatch(setCurrentArticleHtmlContent(res.data));
      })
      .catch(() => {
        // TODO: DO ME
      });
  };
};

export const postArticleVote = ({ articleId, helpful }) => {
  return (dispatch) => {
    voteOnArticle({ articleId, helpful })
      .then((res) => {
        localStorage.setItem(articleId, true);
        dispatch(setUserVoted(true));
      })
      .catch(() => {
        // TODO: DO ME
      });
  };
};

export const toggleBookmarkArticle = ({ articleId, data, bookmarked }) => {
  return (dispatch) => {
    if (bookmarked) setBookmark(articleId, data);
    else removeFromBookmarks(articleId);

    dispatch(setBookmarkArticle(bookmarked));
  };
};
