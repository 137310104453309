import FETCH_STATUS from "shared/fetchStatus";
import { SET_LIGHTS_DATA } from "./actions";

const initialState = {
  status: FETCH_STATUS.idle,
  data: null,
};

export default function lightsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_LIGHTS_DATA:
      return { ...state, data: payload };

    default:
      return state;
  }
}
