import { getBookmarks } from "utils/bookmark";
import { setBookmarks } from "./actionCreators";

export const fetchBookmarks = () => {
  return (dispatch) => {
    const bookmarksMap = getBookmarks();

    if (bookmarksMap) {
      dispatch(setBookmarks(Object.values(bookmarksMap))); // not sure
    }
  };
};
