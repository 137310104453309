import Icon from "components/Icon";

const PlusIcon = ({ ...props }) => {
  return (
    <svg width="17" height="16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.52 8.35H.124V7.164H7.52V.101h1.185v7.11h7.408v1.186H8.717v7.111H7.532z"
        fill-rule="nonzero"
      />
    </svg>
  );
};

const Plus = ({ ...props }) => {
  return (
    <Icon {...props}>
      <PlusIcon />
    </Icon>
  );
};

export default Plus;
